import { createReducer, on } from '@ngrx/store'
import { UserProfileResponse } from '../../Interfaces/UserProfile'
import {
  createDebtorUserProfile,
  createDebtorUserProfileFailure,
  createDebtorUserProfileSuccess,
  createSupplierUserProfile,
  createSupplierUserProfileFailure,
  createSupplierUserProfileSuccess,
  existsUserProfile,
  existsUserProfileFailure,
  existsUserProfileSuccess,
  getUserProfile,
  getUserProfileFailure,
  getUserProfileSuccess,
  updateUserProfile,
  updateUserProfileFailure,
  updateUserProfileSuccess,
  verifiedUser,
  verifiedUserFailure,
  verifiedUserSuccess,
} from './userprofile.actions'

export const userProfileFeatureKey = 'userprofile'

export interface UserProfileState {
  verified: boolean | null
  userprofile: UserProfileResponse | null
  exists: boolean | null
  error: string | null
  loaded: boolean
}

export const initialState: UserProfileState = {
  verified: null,
  userprofile: null,
  exists: null,
  error: null,
  loaded: false,
}

export const userProfilereducers = createReducer(
  initialState,

  on(verifiedUser, state => ({
    ...state,
    loaded: false,
  })),
  on(verifiedUserSuccess, (state, { verified }) => ({
    ...state,
    error: null,
    verified: verified,
    loaded: true,
  })),
  on(verifiedUserFailure, (state, { verified }) => ({
    ...state,
    verified,
    loaded: true,
  })),

  on(existsUserProfile, state => ({
    ...state,
    loaded: false,
  })),
  on(existsUserProfileSuccess, (state, { exists }) => ({
    ...state,
    error: null,
    exists: exists,
    loaded: true,
  })),
  on(existsUserProfileFailure, (state, { exists }) => ({
    ...state,
    exists,
    loaded: true,
  })),

  on(getUserProfile, state => ({
    ...state,
    loaded: false,
  })),
  on(getUserProfileSuccess, (state, { userProfileResponse }) => ({
    ...state,
    error: null,
    userprofile: userProfileResponse,
    exists: true,
    loaded: true,
  })),
  on(getUserProfileFailure, (state, { error }) => ({
    ...state,
    error,
    userprofile: null,
    loaded: true,
  })),

  on(createSupplierUserProfile, state => ({
    ...state,
    error: null,
    userprofile: null,
    loaded: false,
  })),
  on(createSupplierUserProfileSuccess, (state, { userProfileResponse }) => ({
    ...state,
    error: null,
    userprofile: userProfileResponse,
    exists: true,
    loaded: true,
  })),
  on(createSupplierUserProfileFailure, (state, { error }) => ({
    ...state,
    error,
    userprofile: null,
    exists: true,
    loaded: true,
  })),

  on(createDebtorUserProfile, state => ({
    ...state,
    error: null,
    userprofile: null,
    loaded: false,
  })),
  on(createDebtorUserProfileSuccess, (state, { userProfileResponse }) => ({
    ...state,
    error: null,
    userprofile: userProfileResponse,
    exists: true,
    loaded: true,
  })),
  on(createDebtorUserProfileFailure, (state, { error }) => ({
    ...state,
    error,
    userprofile: null,
    loaded: true,
  })),

  on(updateUserProfile, state => ({
    ...state,
    error: null,
    userprofile: null,
    loaded: false,
  })),
  on(updateUserProfileSuccess, (state, { userProfileResponse }) => ({
    ...state,
    error: null,
    userprofile: userProfileResponse,
    loaded: true,
  })),
  on(updateUserProfileFailure, (state, { error }) => ({
    ...state,
    error,
    userprofile: null,
    loaded: true,
  })),
)
