import { HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Params, Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { EMPTY, from, switchMap, take } from 'rxjs'
import { UserProfileState } from '../../shared/store/userprofile/userprofile.reducer'
import {
  selectExistsUserProfile,
  selectUserVerified,
} from '../../shared/store/userprofile/userprofile.selectors'
import { TrustyAuthService } from './trusty-auth-service'

@Injectable({
  providedIn: 'root',
})
export class OnboardingService {
  constructor(
    private router: Router,
    private store: Store<UserProfileState>,
    private authService: TrustyAuthService,
  ) {}

  startOnboarding(role: 'supplier' | 'debtor', params: Params = {}) {
    // First check if user is already logged in
    this.authService.isLoggedIn$
      .pipe(
        take(1),
        switchMap(isLoggedIn => {
          if (!isLoggedIn) {
            // Store intended destination and redirect to login
            localStorage.setItem('onboarding_role', role)
            localStorage.setItem('onboarding_params', JSON.stringify(params))

            // Return an observable instead of void
            const queryParamsString = new HttpParams({
              fromObject: params,
            }).toString()
            this.authService.login(
              `authentication-confirmation?${queryParamsString}&type=${role}`,
            )
            return EMPTY
          }

          // User is logged in, check email verification
          return this.store.select(selectUserVerified).pipe(
            take(1),
            switchMap(isVerified => {
              if (!isVerified) {
                return from(
                  this.router.navigate(['/authentication-confirmation'], {
                    queryParams: { ...params, type: role },
                  }),
                )
              }

              // Check if profile exists
              return this.store.select(selectExistsUserProfile).pipe(
                take(1),
                switchMap(hasProfile => {
                  if (!hasProfile) {
                    return from(
                      this.router.navigate([`/${role}-onboarding`], {
                        queryParams: params,
                      }),
                    )
                  }
                  if (role === 'supplier') {
                    return from(
                      this.router.navigate(['/trusty-creation'], {
                        queryParams: params,
                      }),
                    )
                  } else {
                    if (params['trustyId'] && params['trustyId'] !== '') {
                      return from(
                        this.router.navigate(['/trusty-adjust'], {
                          queryParams: params,
                        }),
                      )
                    } else {
                      return from(
                        this.router.navigate(['/trusty-ask-form'], {
                          queryParams: params,
                        }),
                      )
                    }
                  }
                }),
              )
            }),
          )
        }),
      )
      .subscribe()
  }

  continueAfterEmailVerification() {
    const role = localStorage.getItem('onboarding_role')
    const paramsString = localStorage.getItem('onboarding_params')
    const params = paramsString ? JSON.parse(paramsString) : {}

    if (role) {
      localStorage.removeItem('onboarding_role')
      localStorage.removeItem('onboarding_params')
      this.router.navigate([`/${role}-onboarding`], {
        queryParams: params,
      })
    } else {
      console.log('no role')
      this.router.navigate(['/'])
    }
  }
}
