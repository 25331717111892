export enum Role {
  unknown,
  particulier,
  eenmanszaak,
  bedrijf,
}

export function getRole(value: string): Role {
  switch (value) {
    case 'particulier':
      return Role.particulier
    case 'eenmanszaak':
      return Role.eenmanszaak
    case 'bedrijf':
      return Role.bedrijf
    default:
      return Role.unknown
  }
}

export function getRoleStringValueFromNumber(value: number): string {
  switch (value) {
    case 1:
      return 'particulier'
    case 2:
      return 'eenmanszaak'
    case 3:
      return 'bedrijf'
    default:
      return 'unknown'
  }
}
