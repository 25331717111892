import { Component, OnInit } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { ActivatedRoute, Params, Router } from '@angular/router'
import {
  getExplanationSupplierOnboardSteps,
  Step,
} from '../../../../../shared/Interfaces/internal/onboardflow-steps'
import { OnboardingService } from '../../../../services/onboarding-service'
import { SupplierOnboardHeaderComponent } from '../supplier-onboard-header/supplier-onboard-header.component'

@Component({
  selector: 'app-supplier-onboarding-information',
  standalone: true,
  imports: [MatIconModule, SupplierOnboardHeaderComponent],
  templateUrl: './supplier-onboarding-information.component.html',
  styleUrl: './supplier-onboarding-information.component.css',
})
export class SupplierOnboardingInformationComponent implements OnInit {
  steps: Step[] = getExplanationSupplierOnboardSteps()
  params: Params = {}

  constructor(
    private onboardingService: OnboardingService,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.params = params
    })
  }

  startOnboarding(): void {
    this.onboardingService.startOnboarding('supplier', this.params)
  }

  navigateToContact(): void {
    this.router.navigate(['/contact'])
  }
}
