<app-dashboard-header></app-dashboard-header>
<div class="bg-transparent pl-4 pr-4 pb-4 rounded-2xl">
  <div class="bg-white shadow rounded-2xl border-t-4 p-4 mb-4">
    <h2 class="text-2xl font-bold text-[#333333] mb-4 text-center">
      Betalingen
    </h2>
    <div class="flex space-x-2 mb-6">
      <input
        type="text"
        name="debtor"
        [(ngModel)]="filters.debtor"
        placeholder="Klant"
        class="flex-1 p-2 border rounded-md"
      />
      <input
        type="text"
        name="amount"
        [(ngModel)]="filters.amount"
        placeholder="Bedrag"
        class="flex-1 p-2 border rounded-md"
      />
      <input
        type="date"
        name="date"
        [(ngModel)]="filters.date"
        class="flex-1 p-2 border rounded-md"
      />
      <button
        (click)="toggleAdvancedFilters()"
        class="bg-indigo-400 hover:bg-indigo-500 text-white font-bold py-2 px-4 rounded transition duration-300 flex items-center"
      >
        <mat-icon class="mr-2">filter_list</mat-icon>
        Filter
      </button>
    </div>

    <app-advancedfilters
      *ngIf="showAdvancedFilters"
      [filters]="filters"
      (filterChange)="handleFilterChange($event)"
      (applyFilters)="applyFilters()"
    >
    </app-advancedfilters>

    <div class="overflow-x-auto">
      <!-- Scrollable container with fixed height -->
      <div class="max-h-80 overflow-y-auto relative">
        <table class="min-w-full divide-y divide-gray-200">
          <thead class="bg-gray-50 sticky top-0 z-10">
            <tr>
              <th
                *ngFor="let header of tableHeaders"
                (click)="handleSort(header.key)"
                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider bg-gray-50"
              >
                <div class="flex items-center">
                  {{ header.label }}
                  <mat-icon *ngIf="sortConfig.key === header.key">
                    {{
                      sortConfig.direction === 'ascending'
                        ? 'arrow_upward'
                        : 'arrow_downward'
                    }}
                  </mat-icon>
                </div>
              </th>
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200">
            <tr
              *ngFor="
                let trustyWithPayment of filteredAndSortedTrustiesAndPayments
              "
              [ngClass]="{
                'bg-red-100': trustyWithPayment.state === 'Modified',
                'bg-green-100': trustyWithPayment.state === 'Pending',
              }"
            >
              <td class="px-6 py-4 whitespace-nowrap">
                {{ trustyWithPayment.trusty.debtor.name }}
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                <input
                  type="text"
                  min="0"
                  pattern="[0-9]*"
                  inputmode="numeric"
                  [disabled]="!canEditAmountAndDate(trustyWithPayment.status)"
                  [ngModel]="trustyWithPayment.nextPayment.totalAmount"
                  (ngModelChange)="
                    handlePaymentChange(
                      trustyWithPayment.trusty.id,
                      'amount',
                      $event
                    )
                  "
                  class="w-24 px-2 py-1 border rounded"
                />
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                <input
                  type="date"
                  [disabled]="!canEditAmountAndDate(trustyWithPayment.state)"
                  [ngModel]="trustyWithPayment.nextPayment.dueDate"
                  (ngModelChange)="
                    handlePaymentChange(
                      trustyWithPayment.trusty.id,
                      'date',
                      $event
                    )
                  "
                  class="px-2 py-1 border rounded"
                />
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                {{ trustyWithPayment.state }}
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                <button
                  (click)="handleProcessPayment(trustyWithPayment.trusty.id)"
                  [ngClass]="{
                    invisible: !canEditAmountAndDate(trustyWithPayment.state),
                  }"
                  class="bg-indigo-400 text-white hover:bg-indigo-500 px-3 py-1 rounded flex items-center"
                  [title]="
                    trustyWithPayment.state === 'Pending'
                      ? 'Save changes'
                      : 'Confirm changes'
                  "
                >
                  <mat-icon>{{
                    trustyWithPayment.state === 'Pending' ? 'save' : 'check'
                  }}</mat-icon>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="bg-white mb-4 shadow-md rounded-2xl p-6 border-t-4">
    <h2 class="text-2xl font-bold mb-4 text-center">Extra betalingen</h2>
    <form (ngSubmit)="handleExtraPaymentSubmit()" class="mb-4">
      <div class="grid grid-cols-4 gap-4">
        <label for="debtor" class="font-semibold">Klant</label>
        <label for="amount" class="font-semibold">Bedrag</label>
        <label for="date" class="font-semibold">Datum</label>
        <div></div>
        <select
          id="debtor"
          name="debtor"
          [(ngModel)]="newExtraPayment.id"
          (ngModelChange)="onDebtorSelect($event)"
          class="border p-2 rounded w-full"
          required
        >
          <option value="" disabled selected>Selecteer een klant</option>
          <option
            *ngFor="
              let trustyWithPayment of filteredAndSortedTrustiesAndPayments
            "
            [value]="trustyWithPayment.trusty.id"
          >
            {{ trustyWithPayment.trusty.debtor.name }}
          </option>
        </select>
        <input
          type="text"
          min="0"
          pattern="[0-9]*"
          inputmode="numeric"
          id="amount"
          name="amount"
          [(ngModel)]="newExtraPayment.amount"
          class="border p-2 rounded w-full"
          required
        />
        <input
          id="date"
          type="date"
          name="date"
          [(ngModel)]="newExtraPayment.date"
          class="border p-2 rounded w-full"
          required
        />
        <button
          type="submit"
          class="bg-indigo-400 text-white px-4 py-2 rounded disabled:opacity-50 disabled:cursor-not-allowed"
          [disabled]="
            !newExtraPayment.id ||
            !newExtraPayment.date ||
            !newExtraPayment.amount ||
            newExtraPayment.amount <= 0
          "
        >
          Voeg extra betaling toe
        </button>
      </div>
    </form>
  </div>
</div>
