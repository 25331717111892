import { CommonModule } from '@angular/common'
import { Component, Input } from '@angular/core'
import {
  AbstractControl,
  FormControl,
  ReactiveFormsModule,
} from '@angular/forms'

export interface NumberFieldConfig {
  min: number
  max?: number
  step: number
}

@Component({
  selector: 'app-form-field',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './form-field.component.html',
  styleUrl: './form-field.component.css',
})
export class FormFieldComponent {
  @Input() control!: AbstractControl | null
  @Input() label: string = ''
  @Input() fieldId: string = ''
  @Input() readOnly = false
  @Input() type: 'text' | 'email' | 'tel' | 'select' | 'number' = 'text'
  @Input() options?: { value: string; label: string }[]
  @Input() numberConfig?: NumberFieldConfig
  @Input() customErrorMessages: { [key: string]: string } = {}

  get formControl(): FormControl {
    return this.control as FormControl
  }

  get isInvalid(): boolean {
    return !!this.control && this.control.invalid && this.control.touched
  }

  get errorMessage(): string {
    if (!this.isInvalid) return ''

    const errors = this.formControl.errors
    if (!errors) return ''

    // Return custom error message if provided
    for (const key of Object.keys(errors)) {
      if (this.customErrorMessages[key]) {
        return this.customErrorMessages[key]
      }
    }

    // Default error messages
    if (errors['min']) return `Minimum waarde is ${errors['min'].min}`
    if (errors['max']) return `Maximum waarde is ${errors['max'].max}`
    if (errors['required']) return 'Dit veld is verplicht'

    return 'Ongeldige waarde'
  }
}
