import { Component, EventEmitter, Input, Output } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { FilterConfig } from '../../../../../shared/Interfaces/FilterConfig'

@Component({
  selector: 'app-advancedfilters',
  standalone: true,
  imports: [FormsModule],
  templateUrl: './advancedfilters.component.html',
  styleUrl: './advancedfilters.component.css',
})
export class AdvancedfiltersComponent {
  @Input() filters!: FilterConfig
  @Output() filterChange: EventEmitter<any> = new EventEmitter<any>()
  @Output() applyFilters: EventEmitter<void> = new EventEmitter<void>()

  triggerApplyFilters() {
    this.applyFilters.emit()
  }
}
