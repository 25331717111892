import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { lastValueFrom, Observable } from 'rxjs'
import { environment } from '../../../../environments/environment'
import {
  ExtraPaymentRequest,
  TrustyActualPaymentUpdateRequest,
  TrustyCreateRequest,
  TrustyDebtorInviteSupplierRequest,
  TrustyDebtorPaymentLink,
  TrustyResponse,
  TrustyUpdateRequest,
} from '../../shared/Interfaces/Trusty'
import { LoadingService } from './loading-service'

@Injectable({
  providedIn: 'root',
})
export class TrustyService {
  constructor(
    private http: HttpClient,
    private loadingService: LoadingService,
  ) {}

  getTrusties(): Observable<TrustyResponse[]> {
    this.loadingService.hideLoader()

    return this.http
      .get<TrustyResponse[]>(`${environment.apiUrl}/api/trusty/list`)
      .pipe(this.loadingService.attachLoader())
  }

  getDebtorTrusties(): Observable<TrustyResponse[]> {
    this.loadingService.hideLoader()

    return this.http
      .get<TrustyResponse[]>(`${environment.apiUrl}/api/trusty/debtor/list`)
      .pipe(this.loadingService.attachLoader())
  }

  createTrusty(
    trustyCreateRequest: TrustyCreateRequest,
  ): Observable<TrustyResponse> {
    this.loadingService.hideLoader()

    return this.http
      .post<TrustyResponse>(
        `${environment.apiUrl}/api/trusty`,
        trustyCreateRequest,
      )
      .pipe(this.loadingService.attachLoader())
  }

  updateTrusty(
    trustyUpdateRequest: TrustyUpdateRequest,
  ): Observable<TrustyResponse> {
    this.loadingService.hideLoader()

    return this.http
      .put<TrustyResponse>(
        `${environment.apiUrl}/api/trusty`,
        trustyUpdateRequest,
      )
      .pipe(this.loadingService.attachLoader())
  }

  updateActualPayment(
    trustyId: string,
    trustyActualPaymentUpdateRequest: TrustyActualPaymentUpdateRequest,
  ): Observable<TrustyResponse> {
    this.loadingService.hideLoader()

    return this.http
      .put<TrustyResponse>(
        `${environment.apiUrl}/api/trusty/${trustyId}/payment/update`,
        trustyActualPaymentUpdateRequest,
      )
      .pipe(this.loadingService.attachLoader())
  }

  deleteTrusty(trustyId: string): Observable<object> {
    this.loadingService.hideLoader()

    return this.http
      .delete(`${environment.apiUrl}/api/trusty/${trustyId}`)
      .pipe(this.loadingService.attachLoader())
  }

  assignTrustyDebtor(trustyId: string): Observable<object> {
    this.loadingService.hideLoader()

    return this.http
      .post(`${environment.apiUrl}/api/trusty/debtor/${trustyId}/assign`, {})
      .pipe(this.loadingService.attachLoader())
  }

  trustyDebtorInviteSupplier(
    trustyDebtorInviteSupplierRequest: TrustyDebtorInviteSupplierRequest,
  ): Observable<object> {
    this.loadingService.hideLoader()

    return this.http
      .post(
        `${environment.apiUrl}/api/trusty/debtor/create`,
        trustyDebtorInviteSupplierRequest,
      )
      .pipe(this.loadingService.attachLoader())
  }

  async getDebtorTrustyPaymentLink(
    trustyId: string,
  ): Promise<TrustyDebtorPaymentLink> {
    this.loadingService.hideLoader()

    const request$ = this.http
      .get<TrustyDebtorPaymentLink>(
        `${environment.apiUrl}/api/trusty/debtor/${trustyId}/payment-link`,
        {},
      )
      .pipe(this.loadingService.attachLoader())

    return await lastValueFrom<TrustyDebtorPaymentLink>(request$)
  }

  trustyAddExtraPayment(
    extraPaymentRequest: ExtraPaymentRequest,
  ): Observable<TrustyResponse> {
    this.loadingService.hideLoader()

    return this.http
      .post<TrustyResponse>(
        `${environment.apiUrl}/api/trusty/${extraPaymentRequest.id}/payment/add`,
        extraPaymentRequest,
      )
      .pipe(this.loadingService.attachLoader())
  }
}
