import { NgClass, NgForOf, NgIf } from '@angular/common'
import { Component, OnDestroy, OnInit } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { MatIconModule } from '@angular/material/icon'
import { Router } from '@angular/router'
import { Action, Store } from '@ngrx/store'
import { filter, Subject, takeUntil } from 'rxjs'
import { handleGeneralSort, sortData } from '../../../../../../util/sortData'
import { ConfirmationDialogComponent } from '../../../../../shared/components/confirmation-dialog/confirmation-dialog.component'
import { DashboardHeaderComponent } from '../../../../../shared/components/dashboard-header/dashboard-header.component'
import {
  FilterConfig,
  getDefaultFilterConfig,
} from '../../../../../shared/Interfaces/FilterConfig'
import {
  getDefaultSortConfig,
  SortConfig,
} from '../../../../../shared/Interfaces/SortConfig'
import { TrustyResponse } from '../../../../../shared/Interfaces/Trusty'
import {
  deleteTrusty,
  getTrusties,
} from '../../../../../shared/store/trusty/trusty.actions'
import { TrustyState } from '../../../../../shared/store/trusty/trusty.reducer'
import {
  selectTrusties,
  selectTrustyLoaded,
} from '../../../../../shared/store/trusty/trusty.selectors'
import { toUTCDate } from '../../../../services/data.service'
import { TrustyPreviewService } from '../../../../services/trusty-preview-service'

interface TrustyWithState {
  trusty: TrustyResponse
  state: string
}

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    FormsModule,
    NgClass,
    NgIf,
    NgForOf,
    MatIconModule,
    DashboardHeaderComponent,
  ],
  templateUrl: './management.component.html',
  styleUrl: './management.component.css',
})
export class ManagementComponent implements OnInit, OnDestroy {
  constructor(
    private router: Router,
    private store: Store<TrustyState>,
    public dialog: MatDialog,
    private trustyPreviewService: TrustyPreviewService,
  ) {}

  initialLoad: boolean = true
  trusties: TrustyWithState[] = []
  private destroy$ = new Subject<void>()

  sortConfig: SortConfig = getDefaultSortConfig()
  filters: FilterConfig = getDefaultFilterConfig()

  tableHeaders = [
    { key: 'debtor', label: 'Klant' },
    { key: 'amount', label: 'Bedrag' },
    { key: 'rest', label: 'Resterende tijd' },
    { key: 'interest', label: 'Interest' },
    { key: 'status', label: 'Status' },
    { key: 'action', label: 'Acties*' },
    { key: 'extras', label: "Extra's" },
  ]

  showInfo = false

  ngOnInit(): void {
    this.store
      .select(selectTrustyLoaded)
      .pipe(takeUntil(this.destroy$))
      .subscribe(loaded => {
        if (!loaded) {
          this.store.dispatch(getTrusties())
        }
      })

    this.store
      .select(selectTrusties)
      .pipe(
        takeUntil(this.destroy$),
        filter((trusties: TrustyResponse[]) => trusties !== null),
      )
      .subscribe((trusties: TrustyResponse[]) => {
        this.selectTrustiesWithState(trusties)
      })
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  get filteredAndSortedTrusties() {
    const filteredTrusties = this.trusties.filter(
      trustyWithState =>
        trustyWithState.trusty.debtor.name
          .toLowerCase()
          .includes(this.filters.debtor.toLowerCase()) &&
        trustyWithState.trusty.totalAmount
          .toString()
          .includes(this.filters.amount) &&
        toUTCDate(trustyWithState.trusty.startDate)
          .toDateString()
          .includes(this.filters.date) &&
        (this.filters.amountMin === '' ||
          trustyWithState.trusty.totalAmount >=
            Number(this.filters.amountMin)) &&
        (this.filters.amountMax === '' ||
          trustyWithState.trusty.totalAmount <=
            Number(this.filters.amountMax)) &&
        (this.filters.dateMin === '' ||
          toUTCDate(trustyWithState.trusty.startDate).toDateString() >=
            this.filters.dateMin) &&
        (this.filters.dateMax === '' ||
          toUTCDate(trustyWithState.trusty.startDate).toDateString() <=
            this.filters.dateMax),
    )

    return sortData(filteredTrusties, this.sortConfig)
  }

  handleSort(key: string) {
    handleGeneralSort(key, this.sortConfig)
  }

  stopTrusty(trusty: TrustyResponse): void {
    this.openAndHandleDialog(
      `Trusty voor ${trusty.debtor.name} (bedrag: € ${trusty.totalAmount}) stopzetten?`,
      deleteTrusty({ trustyId: trusty.id.toString() }),
    )
  }

  viewTrusty(trusty: TrustyResponse): void {
    this.setTrustyPreview(trusty)
    this.router.navigate(['trusty-preview'])
  }

  changeTrusty(trusty: TrustyResponse): void {
    this.setTrustyPreview(trusty)
    this.router.navigate(['trusty-change'], { queryParams: { id: trusty.id } })
  }

  doBemiddeling(trusty: TrustyResponse): void {
    this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title:
          'Trusty voor ' + trusty.debtor.name + ' naar bemiddeling sturen?',
        HTMLContent: `Deze functionaliteit is beschikbaar in Sales2Cash. <br>
          Contacteer Jonas voor meer informatie. <br>
          jonas.devlieger@alternatiefbv.be`,
        showCancelButton: true,
      },
    })
  }

  openAndHandleDialog(data: string, action: Action) {
    const confirmDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Trusty verwijderen?',
        text: data,
        showCancelButton: true,
      },
    })

    confirmDialogRef.afterClosed().subscribe((confirmResult: boolean) => {
      if (confirmResult === true) {
        this.store.dispatch(action)
      }
    })
  }

  private selectTrustiesWithState(trusties: TrustyResponse[]): void {
    const dateNow = toUTCDate(new Date())

    this.trusties = trusties
      .filter(x => x.theoreticalPayments.length > 0)
      .map<TrustyWithState>(trusty => ({
        trusty: trusty,
        state:
          trusty.agreementDate !== undefined && trusty.agreementDate !== null
            ? 'Actief'
            : toUTCDate(trusty.createdDate).getTime() +
                  7 * 24 * 60 * 60 * 1000 >
                dateNow.getTime()
              ? 'In afwachting'
              : 'Kritiek',
      }))
  }

  private setTrustyPreview(trusty: TrustyResponse) {
    this.trustyPreviewService.trustyPreview = {
      supplierName: trusty.supplierName,
      debtor: {
        role: trusty.debtor.role,
        name: trusty.debtor.name,
        email: trusty.debtor.email,
        phone: trusty.debtor.phone,
      },
      totalAmount: trusty.totalAmount,
      interestRate: trusty.interestRate,
      amountOfMonths: trusty.amountOfMonths,
      agreementDate: trusty.agreementDate,
      minAmount: trusty.minAmount,
      startDate: toUTCDate(trusty.startDate!),
      invoices: trusty.invoices,
      actualPayments: trusty.actualPayments,
      theoreticalPayments: trusty.theoreticalPayments,
    }
  }
}
