import { NgIf } from '@angular/common'
import { Component, OnDestroy, OnInit } from '@angular/core'
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms'
import { Router } from '@angular/router'
import { Subject, takeUntil } from 'rxjs'
import { ClientService } from '../../../main/services/client-service'
import { FormFieldComponent } from '../form-field/form-field.component'

@Component({
  selector: 'app-contact-form',
  standalone: true,
  imports: [NgIf, ReactiveFormsModule, FormFieldComponent],
  templateUrl: './contact-form.component.html',
  styleUrl: './contact-form.component.css',
})
export class ContactFormComponent implements OnInit, OnDestroy {
  contactForm: FormGroup
  private destroy$ = new Subject<void>()

  constructor(
    private fb: FormBuilder,
    private clientService: ClientService,
    private router: Router,
  ) {
    this.contactForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', Validators.required],
      message: ['', Validators.required],
    })
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  onSubmit() {
    if (this.contactForm.valid) {
      this.clientService
        .sendContactForm({
          name: this.contactForm.value['name'],
          email: this.contactForm.value['email'],
          phone: this.contactForm.value['phone'],
          message: this.contactForm.value['message'],
        })
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: () => {
            this.router.navigate([''])
          },
          error: err => {
            console.error('Error sending contact form:', err)
          },
        })
    }
  }
}
