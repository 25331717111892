import { Component, OnInit } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { ActivatedRoute, Params } from '@angular/router'
import {
  Step,
  getDebtorOnboardSteps,
} from '../../../../../shared/Interfaces/internal/onboardflow-steps'
import { OnboardingService } from '../../../../services/onboarding-service'
import { DebtorOnboardHeaderComponent } from '../debtor-onboard-header/debtor-onboard-header.component'

@Component({
  selector: 'app-debtor-onboarding-information',
  standalone: true,
  imports: [MatIconModule, DebtorOnboardHeaderComponent],
  templateUrl: './debtor-onboarding-information.component.html',
  styleUrl: './debtor-onboarding-information.component.css',
})
export class DebtorOnboardInformationComponent implements OnInit {
  steps: Step[] = getDebtorOnboardSteps()
  params: Params = {}

  constructor(
    private onboardingService: OnboardingService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.params = params
    })
  }

  startOnboarding(): void {
    this.onboardingService.startOnboarding('debtor', this.params)
  }
}
