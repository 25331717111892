export interface Step {
  title: string
  description: string
  importance: string
  icon: any
  timeLimit: string | null
}

export function getDebtorOnboardSteps(): Step[] {
  return [
    {
      title: 'Veilige Authenticatie',
      description:
        'We gebruiken twee-factor authenticatie (2FA) via e-mail om uw identiteit te verifiëren.',
      importance:
        'Deze extra beveiligingsstap beschermt uw persoonlijke financiële gegevens.',
      icon: 'shield',
      timeLimit: null,
    },
    {
      title: 'Profiel Vervolledigen',
      description: 'Controleer en vul uw profielgegevens aan.',
      importance: 'Accurate gegevens zorgen voor een vlotte communicatie.',
      icon: 'manage_accounts',
      timeLimit: null,
    },
    {
      title: 'Afbetalingsplan Aanpassen en bevestigen',
      description:
        'Verhoog uw maandelijks bedrag via een handige slider en zie het effect op de duurtijd.',
      importance:
        'Door vandaag iets meer te betalen, bent u morgen sneller schuldenvrij. U ziet direct hoeveel maanden u wint.',
      icon: 'bar_chart',
      timeLimit: null,
    },
    {
      title: 'Activatie',
      description:
        'Na bevestiging ontvangt u een factuur voor de servicekost. Uw afbetalingsplan wordt automatisch geactiveerd zodra we uw betaling hebben ontvangen.',
      importance:
        'Snelle activatie voor directe start van uw afbetaling zodra uw betaling bij ons binnen is.',
      icon: 'rocket',
      timeLimit: '7 dagen',
    },
  ]
}

export function getExplanationSupplierOnboardSteps(): Step[] {
  return [
    {
      title: 'Gratis voor leveranciers',
      description: 'Debiteur betaalt een bescheiden administratieve bijdrage.',
      importance: '',
      icon: 'shield',
      timeLimit: null,
    },
    {
      title: 'Verhoogde inningsgraad',
      description: 'Betere cashflow door flexibele betalingsregelingen.',
      importance: '',
      icon: 'auto_graph',
      timeLimit: null,
    },
    {
      title: 'Behoud klantenrelaties',
      description: 'Professionele en klantvriendelijke aanpak.',
      importance: '',
      icon: 'supervisor_account',
      timeLimit: null,
    },
  ]
}

export function getSupplierOnboardSteps(): Step[] {
  return [
    {
      title: 'Veilige Authenticatie',
      description: '',
      importance: '',
      icon: '',
      timeLimit: null,
    },
    {
      title: 'Profiel vervolledigen',
      description: '',
      importance: '',
      icon: '',
      timeLimit: null,
    },
    {
      title: 'Activatie',
      description: '',
      importance: '',
      icon: '',
      timeLimit: null,
    },
  ]
}
