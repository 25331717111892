<div class="mb-4 p-4 border rounded-2xl bg-gray-50">
  <div class="grid grid-cols-2 gap-4">
    <div>
      <label class="block text-sm font-medium text-gray-700"
        >Bedrag bereik</label
      >
      <div class="flex space-x-2">
        <input
          type="number"
          name="amountMin"
          [(ngModel)]="filters.amountMin"
          placeholder="Minimum"
          class="flex-1 p-2 border rounded-md"
        />
        <input
          type="number"
          name="amountMax"
          [(ngModel)]="filters.amountMax"
          placeholder="Maximum"
          class="flex-1 p-2 border rounded-md"
        />
      </div>
    </div>
    <div>
      <label class="block text-sm font-medium text-gray-700"
        >Datum bereik</label
      >
      <div class="flex space-x-2">
        <input
          type="date"
          name="dateMin"
          [(ngModel)]="filters.dateMin"
          class="flex-1 p-2 border rounded-md"
        />
        <input
          type="date"
          name="dateMax"
          [(ngModel)]="filters.dateMax"
          class="flex-1 p-2 border rounded-md"
        />
      </div>
    </div>
  </div>
  <button
    (click)="triggerApplyFilters()"
    class="mt-4 bg-indigo-400 hover:bg-indigo-500 text-white font-bold py-2 px-4 rounded transition duration-300"
  >
    Pas filter toe
  </button>
</div>
