import { NgClass, NgIf } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { MatIconModule } from '@angular/material/icon'
import { ActivatedRoute, Router } from '@angular/router'
import { ConfirmationDialogComponent } from '../../../../shared/components/confirmation-dialog/confirmation-dialog.component'
import { getRole } from '../../../../shared/constants/role'
import { TYPEngagement } from '../../../../shared/constants/TYPTexts'
import { TrustyService } from '../../../services/trusty-service'
import { DebtorOnboardHeaderComponent } from '../../onboarding/debtor-onboarding/debtor-onboard-header/debtor-onboard-header.component'

@Component({
  selector: 'app-debtor-welcome',
  standalone: true,
  imports: [
    NgIf,
    MatIconModule,
    NgClass,
    FormsModule,
    DebtorOnboardHeaderComponent,
    ConfirmationDialogComponent,
  ],
  templateUrl: './debtor-welcome.component.html',
  styleUrl: './debtor-welcome.component.css',
})
export class DebtorWelcomeComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private trustyService: TrustyService,
    public dialog: MatDialog,
  ) {}

  public selectedRole: number | undefined
  private trustyId: string | undefined
  agreedToTerms: boolean = false

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.selectedRole = getRole(params['debtorRole'])
      this.trustyId = params['trustyId']
    })
  }

  async onContinue() {
    if (this.agreedToTerms) {
      const paymentLinkResponse =
        await this.trustyService.getDebtorTrustyPaymentLink(this.trustyId!)

      window.open(paymentLinkResponse.paymentUrl, '_blank')

      this.router.navigate(['debtor-dashboard'])
    }
  }

  openTYPEngagement() {
    const confirmDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Trust Your Plan Engagement',
        HTMLContent: TYPEngagement,
        showCancelButton: false,
      },
    })

    confirmDialogRef.afterClosed().subscribe((result: boolean) => {
      if (result === true) {
        this.agreedToTerms = true
      }
    })
  }
}
