import { Component, OnDestroy, OnInit } from '@angular/core'
import { FaqComponent } from './faq/faq.component'
import { HowItWorksComponent } from './how-it-works/how-it-works.component'
import { SplitUspComponent } from './split-usp/split-usp.component'

@Component({
  selector: 'app-landing-page',
  standalone: true,
  imports: [FaqComponent, HowItWorksComponent, SplitUspComponent],
  templateUrl: './landing.component.html',
  styleUrl: './landing.component.css',
})
export class LandingComponent implements OnInit, OnDestroy {
  private texts = [
    'Een ethisch afbetalingsplan.',
    'Een win-win oplossing.',
    'Een aanpak die werkt.',
  ]
  currentText = this.texts[0]
  private currentIndex = 0
  private intervalId: any
  fadeState: 'in' | 'out' = 'in'
  isPlaying = true

  ngOnInit() {
    this.startTextRotation()
  }

  ngOnDestroy() {
    this.stopRotation()
  }

  toggleRotation() {
    if (this.isPlaying) {
      this.stopRotation()
    } else {
      this.startTextRotation()
    }
    this.isPlaying = !this.isPlaying
  }

  private stopRotation() {
    if (this.intervalId) {
      clearInterval(this.intervalId)
      this.intervalId = null
    }
  }

  private startTextRotation() {
    // Ensure any existing interval is cleared
    this.stopRotation()

    this.intervalId = setInterval(() => {
      this.fadeState = 'out'

      setTimeout(() => {
        this.currentIndex = (this.currentIndex + 1) % this.texts.length
        this.currentText = this.texts[this.currentIndex]
        this.fadeState = 'in'
      }, 500)
    }, 5000)
  }
}
