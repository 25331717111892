import { DatePipe } from '@angular/common'
import {
  HttpBackend,
  HttpClient,
  provideHttpClient,
  withInterceptors,
} from '@angular/common/http'
import { APP_INITIALIZER, ApplicationConfig } from '@angular/core'
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async'
import { provideRouter } from '@angular/router'
import {
  AuthClientConfig,
  authHttpInterceptorFn,
  provideAuth0,
} from '@auth0/auth0-angular'
import { provideEffects } from '@ngrx/effects'
import { provideState, provideStore } from '@ngrx/store'
import { provideStoreDevtools } from '@ngrx/store-devtools'
import { map } from 'rxjs'
import { environment } from '../environments/environment'
import { routes } from './app.routes'
import { ClientConfig } from './modules/shared/Interfaces/ClientConfig'
import { TrustyEffects } from './modules/shared/store/trusty/trusty.effects'
import {
  trustyFeatureKey,
  trustyReducers,
} from './modules/shared/store/trusty/trusty.reducer'
import { UserProfileEffects } from './modules/shared/store/userprofile/userprofile.effects'
import {
  userProfileFeatureKey,
  userProfilereducers as userProfileReducers,
} from './modules/shared/store/userprofile/userprofile.reducer'

export const appConfig: ApplicationConfig = {
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: configInitializer,
      deps: [HttpBackend, AuthClientConfig],
      multi: true,
    },
    DatePipe,
    provideAuth0(),
    provideRouter(routes),
    provideAnimationsAsync(),
    provideHttpClient(withInterceptors([authHttpInterceptorFn])),
    provideStore(),
    provideState({ name: userProfileFeatureKey, reducer: userProfileReducers }),
    provideState({ name: trustyFeatureKey, reducer: trustyReducers }),
    provideEffects([UserProfileEffects, TrustyEffects]),
    provideStoreDevtools(),
  ],
}

function configInitializer(handler: HttpBackend, config: AuthClientConfig) {
  return () =>
    new HttpClient(handler)
      .get<ClientConfig>(`${environment.apiUrl}/api/config/client`)
      .pipe(
        map(loadedConfig =>
          config.set({
            clientId: loadedConfig.clientId,
            domain: loadedConfig.domain,
            authorizationParams: {
              redirect_uri: window.location.origin,
              audience: loadedConfig.audience,
              scope: 'read:current_user',
            },
            httpInterceptor: {
              allowedList: [
                {
                  uri: `${environment.apiUrl}/api/*`,
                  tokenOptions: {
                    authorizationParams: {
                      audience: loadedConfig.audience,
                      scope: 'read:current_user',
                    },
                  },
                },
              ],
            },
          }),
        ),
      )
}
