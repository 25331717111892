<h2 mat-dialog-title>{{ data.title }}</h2>

<mat-dialog-content>
  <div *ngIf="data.HTMLContent" [innerHTML]="data.HTMLContent"></div>
  <p *ngIf="!data.HTMLContent">{{ data.text }}</p>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button
    type="button"
    mat-button
    color="accent"
    (click)="onNoClick()"
    *ngIf="data.showCancelButton"
  >
    Annuleer
  </button>
  <button
    type="submit"
    mat-raised-button
    color="primary"
    (click)="onYesClick()"
  >
    Bevestig
  </button>
</mat-dialog-actions>
