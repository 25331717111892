import { CommonModule } from '@angular/common'
import { Component, OnDestroy, OnInit } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { MatDialog } from '@angular/material/dialog'
import { ActivatedRoute, Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { Subject, takeUntil } from 'rxjs'
import { DebtorOnboardHeaderComponent } from '../../../main/components/onboarding/debtor-onboarding/debtor-onboard-header/debtor-onboard-header.component'
import { SupplierOnboardHeaderComponent } from '../../../main/components/onboarding/supplier-onboarding/supplier-onboard-header/supplier-onboard-header.component'
import { OnboardingService } from '../../../main/services/onboarding-service'
import { verifiedUser } from '../../store/userprofile/userprofile.actions'
import { UserProfileState } from '../../store/userprofile/userprofile.reducer'
import { selectUserVerified } from '../../store/userprofile/userprofile.selectors'
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component'

@Component({
  selector: 'app-authentication-confirmation',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    SupplierOnboardHeaderComponent,
    DebtorOnboardHeaderComponent,
    ConfirmationDialogComponent,
  ],
  templateUrl: './authentication-confirmation.component.html',
  styleUrl: './authentication-confirmation.component.css',
})
export class AuthenticationConfirmationComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>()
  isSupplier: boolean = false
  hasRole: boolean = false

  constructor(
    private store: Store<UserProfileState>,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private onboardingService: OnboardingService,
  ) {}

  ngOnInit(): void {
    const role = localStorage.getItem('onboarding_role')
    this.hasRole = role !== null && role !== 'NONE'

    this.route.queryParams.subscribe(params => {
      if (params['type'] === 'supplier') {
        this.isSupplier = true
        this.hasRole = true
      }

      if (params['type'] === 'debtor') {
        this.isSupplier = false
        this.hasRole = true
      }
    })

    this.store
      .select(selectUserVerified)
      .pipe(takeUntil(this.destroy$))
      .subscribe(hasVerifiedMail => {
        if (hasVerifiedMail) {
          this.onboardingService.continueAfterEmailVerification()
        }
      })
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  continueOnboarding(): void {
    this.store.dispatch(verifiedUser())

    this.store
      .select(selectUserVerified)
      .pipe(takeUntil(this.destroy$))
      .subscribe(hasVerifiedMail => {
        if (!hasVerifiedMail) {
          const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            data: {
              title: 'Nog iets vergeten?',
              HTMLContent: `Uw e-mail is nog niet geverifieerd. <br>
              Klik op de link in de e-mail om deze te verifiëren. <br>
              Check zeker ook de spam folder.`,
              showCancelButton: false,
            },
          })

          dialogRef.afterClosed().subscribe(() => {
            this.store.dispatch(verifiedUser())
          })
        } else {
          this.dialog.open(ConfirmationDialogComponent, {
            data: {
              title: 'Geverifieerd!',
              HTMLContent: `E-mailadres succesvol geverifieerd.`,
              showCancelButton: false,
            },
          })
          if (!this.hasRole) {
            this.router.navigate(['/'])
          } else {
            this.onboardingService.continueAfterEmailVerification()
          }
        }
      })
  }
}
