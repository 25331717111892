<div
  class="grid gap-4 mb-4"
  [ngClass]="{
    'grid-cols-4': !userProfile?.isSupplier || !userProfile?.isDebtor,
    'grid-cols-5': userProfile?.isSupplier && userProfile?.isDebtor,
  }"
>
  <button
    *ngIf="userProfile?.isSupplier"
    (click)="createNewTrusty()"
    [ngClass]="{
      'not-active':
        router.url.indexOf('/trusty-creation') === -1 &&
        router.url !== '/supplier-dashboard' &&
        router.url !== '/debtor-dashboard',
    }"
    class="shadow bg-indigo-200 hover:bg-indigo-300 transition-colors p-2 rounded-2xl flex flex-col items-center justify-center"
  >
    <i class="material-icons"> add </i>
    <h2 class="text-xl font-bold">Aanmaken</h2>
  </button>
  <button
    *ngIf="userProfile?.isDebtor"
    (click)="askNewTrusty()"
    [ngClass]="{
      'not-active':
        router.url !== '/debtor-dashboard' &&
        router.url !== '/supplier-dashboard',
    }"
    class="shadow bg-indigo-200 hover:bg-indigo-300 transition-colors p-2 rounded-2xl flex flex-col items-center justify-center"
  >
    <i class="material-icons"> add </i>
    <h2 class="text-xl font-bold">Aanvragen</h2>
  </button>
  <button
    *ngIf="userProfile?.isSupplier"
    (click)="goToManagement()"
    [ngClass]="{
      'not-active':
        router.url !== '/management' &&
        router.url !== '/supplier-dashboard' &&
        router.url !== '/debtor-dashboard',
    }"
    class="shadow bg-emerald-200 hover:bg-emerald-300 transition-colors p-2 rounded-2xl flex flex-col items-center justify-center"
  >
    <i class="material-icons">stacked_bar_chart</i>
    <h2 class="text-xl font-bold">Beheer</h2>
  </button>
  <button
    *ngIf="userProfile?.isSupplier"
    (click)="goToReport()"
    [ngClass]="{
      'not-active':
        router.url !== '/report' &&
        router.url !== '/supplier-dashboard' &&
        router.url !== '/debtor-dashboard',
    }"
    class="shadow bg-amber-200 hover:bg-amber-300 transition-colors p-2 rounded-2xl flex flex-col items-center justify-center"
  >
    <i class="material-icons">file_present</i>
    <h2 class="text-xl font-bold">Rapport</h2>
  </button>
  <button
    (click)="goToSettings()"
    [ngClass]="{
      'not-active':
        router.url !== '/profile' &&
        router.url !== '/debtor-dashboard' &&
        router.url !== '/supplier-dashboard',
    }"
    class="shadow bg-fuchsia-300 hover:bg-fuchsia-400 transition-colors p-2 rounded-2xl flex flex-col items-center justify-center"
  >
    <i class="material-icons">settings</i>
    <h2 class="text-xl font-bold">Instellingen</h2>
  </button>
</div>
