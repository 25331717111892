import { CommonModule } from '@angular/common'
import { Component, EventEmitter, Input, Output } from '@angular/core'

@Component({
  selector: 'app-role-selection',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './role-selection.component.html',
  styleUrl: './role-selection.component.css',
})
export class RoleSelectionComponent {
  @Input()
  selectedRole: string = 'bedrijf'
  @Output()
  roleSelected = new EventEmitter<string>()

  selectRole(role: string): void {
    this.selectedRole = role
    this.roleSelected.emit(role)
  }
}
