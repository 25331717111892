import { CommonModule } from '@angular/common'
import { Component, Input } from '@angular/core'
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormGroup,
} from '@angular/forms'
import { RoleSelectionComponent } from '../../../../shared/components/role-selection/role-selection.component'

@Component({
  selector: 'app-trusty-contact-information',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RoleSelectionComponent,
  ],
  templateUrl: './trusty-contact-information.component.html',
  styleUrl: './trusty-contact-information.component.css',
})
export class TrustyContactInformationComponent {
  @Input()
  formGroup!: UntypedFormGroup

  @Input()
  public selectedRole: string = 'bedrijf'

  selectRole(role: string): void {
    this.selectedRole = role
  }
}
