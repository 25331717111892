import { Overlay, OverlayRef } from '@angular/cdk/overlay'
import { ComponentPortal } from '@angular/cdk/portal'
import { Injectable } from '@angular/core'
import { Observable, tap } from 'rxjs'
import { LoadingComponent } from '../../shared/components/loading/loading.component'

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  overlayRef: OverlayRef
  constructor(private overlay: Overlay) {
    this.overlayRef = this.overlay.create({
      positionStrategy: this.overlay
        .position()
        .global()
        .centerHorizontally()
        .centerVertically(),
      hasBackdrop: true,
    })
  }

  showLoader() {
    this.overlayRef.attach(new ComponentPortal(LoadingComponent))
  }

  hideLoader() {
    this.overlayRef.detach()
  }

  attachLoader<T>(): (source: Observable<T>) => Observable<T> {
    return tap<T>({
      subscribe: () => this.showLoader(),
      next: () => this.hideLoader(),
      error: () => {
        // TODO: navigate to error page
        this.hideLoader()
      },
    })
  }
}
