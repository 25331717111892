<app-dashboard-header></app-dashboard-header>
<div class="container mx-auto pl-4 pr-4 pb-4">
  <div class="flex justify-center" style="display: block">
    <canvas
      baseChart
      [data]="barChartData"
      [options]="barChartOptions"
      [type]="barChartType"
    ></canvas>
  </div>
</div>
