import { CommonModule, Location } from '@angular/common'
import { Component, OnDestroy, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'
import { Subject, takeUntil } from 'rxjs'
import { PaymentDetail } from '../../../../shared/Interfaces/internal/trusty-detail'
import { TrustyPreview } from '../../../../shared/Interfaces/internal/trusty-preview'
import { UserProfileState } from '../../../../shared/store/userprofile/userprofile.reducer'
import { selectUserProfile } from '../../../../shared/store/userprofile/userprofile.selectors'
import { toUTCDate } from '../../../services/data.service'
import { TrustyDetailsService } from '../../../services/trusty-detail-service'
import { TrustyPreviewService } from '../../../services/trusty-preview-service'
import { PaymentTableComponent } from '../payment-table/payment-table.component'

@Component({
  selector: 'app-trusty-preview',
  standalone: true,
  imports: [CommonModule, PaymentTableComponent],
  templateUrl: './trusty-preview.component.html',
  styleUrls: ['./trusty-preview.component.css'],
})
export class TrustyPreviewComponent implements OnInit, OnDestroy {
  constructor(
    private trustyDetailsService: TrustyDetailsService,
    private trustyService: TrustyPreviewService,
    private store: Store<UserProfileState>,
    private location: Location,
  ) {}

  trustyPreview?: TrustyPreview
  executedPayments: PaymentDetail[] = []
  remainingPayments: PaymentDetail[] = []
  formattedDate: string = ''
  isDebtor: boolean = false
  private destroy$ = new Subject<void>()

  ngOnInit(): void {
    this.store
      .select(selectUserProfile)
      .pipe(takeUntil(this.destroy$))
      .subscribe(userProfile => {
        this.isDebtor = userProfile.isDebtor
      })

    this.trustyService.currentTrustyPreview$
      .pipe(takeUntil(this.destroy$))
      .subscribe(trustyPreview => {
        if (trustyPreview !== undefined) {
          this.trustyPreview = trustyPreview
          this.formattedDate = trustyPreview.agreementDate
            ? this.formatDate(toUTCDate(trustyPreview.agreementDate))
            : 'Nog niet geaccepteerd'

          this.calculatePaymentPlan()
        }
      })
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  formatCurrency(amount: number): string {
    return amount.toLocaleString('nl-NL', {
      style: 'currency',
      currency: 'EUR',
    })
  }

  formatDate(date: Date): string {
    return toUTCDate(date).toLocaleDateString('nl-NL', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    })
  }

  navigateToTrusty(): void {
    this.location.back()
  }

  calculatePaymentPlan(): void {
    if (this.trustyPreview && this.trustyPreview.actualPayments?.length > 0) {
      this.getPaymentDataForExistingTrusty()
    } else {
      const trustyDetail = this.trustyDetailsService.calculatePaymentPlan(
        this.trustyPreview!.totalAmount,
        this.trustyPreview!.minAmount,
        this.trustyPreview!.interestRate,
        this.trustyPreview!.amountOfMonths,
        this.trustyPreview!.agreementDate
          ? toUTCDate(this.trustyPreview!.agreementDate)
          : toUTCDate(new Date()),
        toUTCDate(this.trustyPreview!.startDate),
      )

      this.executedPayments = trustyDetail.executedPayments
      this.remainingPayments = trustyDetail.remainingPayments
    }
  }

  getRemainingMonths(): number {
    return (
      this.trustyPreview?.theoreticalPayments?.filter(
        payment => !payment.isPaid,
      ).length ?? 0
    )
  }

  totalAmountPaid(): number {
    return this.executedPayments.reduce((acc, x) => acc + x.payment, 0)
  }

  totalAmountRemaining(): number {
    return this.remainingPayments.reduce((acc, x) => acc + x.payment, 0)
  }

  private getPaymentDataForExistingTrusty() {
    let totalAmount = this.trustyPreview!.totalAmount

    let actualPayments = [...this.trustyPreview!.actualPayments].sort(
      (a, b) =>
        new Date(a.paymentDate).getTime() - new Date(b.paymentDate).getTime(),
    )

    for (let i = 0; i < actualPayments.length; i++) {
      const payment = actualPayments[i]
      this.executedPayments.push({
        reference: payment.paymentNumber,
        date: toUTCDate(payment.paymentDate),
        payment: payment.amountPaid,
        capital: payment.principalPaid,
        interest: payment.interestPaid,
        balance: totalAmount - payment.principalPaid,
      })
      totalAmount -= payment.principalPaid
    }

    let remainingPayments = [...this.trustyPreview!.theoreticalPayments]
      .filter(x => !x.isPaid)
      .sort((a, b) => {
        const numA = parseInt(a.paymentNumber.replace('REF', ''), 10)
        const numB = parseInt(b.paymentNumber.replace('REF', ''), 10)
        return numA - numB
      })

    for (let i = 0; i < remainingPayments.length; i++) {
      const payment = remainingPayments[i]
      const isLastPayment = i === remainingPayments.length - 1

      if (isLastPayment) {
        this.remainingPayments.push({
          balance: 0,
          capital: totalAmount,
          payment: totalAmount + payment.interestAmount,
          date: toUTCDate(payment.dueDate),
          interest: payment.interestAmount,
          reference: payment.paymentNumber,
        })
      } else {
        totalAmount -= payment.principalAmount
        this.remainingPayments.push({
          balance: Number(totalAmount.toFixed(2)),
          capital: payment.principalAmount,
          payment: payment.totalAmount,
          date: toUTCDate(payment.dueDate),
          interest: payment.interestAmount,
          reference: payment.paymentNumber,
        })
      }
    }
  }
}
