<ng-container *ngIf="trustyPreview$ | async as trustyPreview">
  <div class="flex flex-row justify-between" style="height: 80vh">
    <!-- Left side (2/3 of the width) -->
    <div
      class="w-2/3 pl-6 pr-6 pb-6 pt-2 bg-transparent rounded-2xl overflow-y-auto"
      style="box-sizing: border-box"
    >
      <div class="flex flex-row gap-4 mb-6">
        <!-- Supplier Information -->
        <div
          class="flex-1 bg-white p-4 rounded-2xl border-t-4 border-fuchsia-200 shadow"
        >
          <h3 class="text-lg font-semibold mb-2 text-[#333333]">
            Leveranciersinformatie
          </h3>
          <div class="space-y-2">
            <div>
              <label class="text-sm text-[#333333]">Leveranciersnaam</label>
              <p class="text-base font-semibold text-indigo-500">
                {{ trustyPreview.debtor.name }}
              </p>
            </div>
            <div>
              <label class="text-sm text-[#333333]">Totale schuld</label>
              <p class="text-base font-semibold text-indigo-500">
                {{ formatCurrency(trustyPreview.totalAmount) }}
              </p>
            </div>
            <div>
              <label class="text-sm text-[#333333]">Overeenkomstdatum</label>
              <p class="text-base font-semibold text-indigo-500">
                {{ getFormattedAgreementDate(trustyPreview) }}
              </p>
            </div>
          </div>
        </div>

        <!-- Payment Terms -->
        <div
          class="flex-1 bg-white p-4 rounded-2xl border-t-4 border-fuchsia-200 shadow"
        >
          <h3 class="text-lg font-semibold mb-2 text-[#333333]">
            Betalingsvoorwaarden
          </h3>
          <div class="space-y-2">
            <div>
              <label class="text-sm text-[#333333]"
                >Maandelijkse betaling</label
              >
              <p class="text-base font-semibold text-indigo-500">
                {{ formatCurrency(trustyPreview.minAmount) }}
              </p>
            </div>
            <div>
              <label class="text-sm text-[#333333]">Interest</label>
              <p class="text-base font-semibold text-indigo-500">
                {{ trustyPreview.interestRate }}%
              </p>
            </div>
            <div>
              <label class="text-sm text-[#333333]">Aantal maanden</label>
              <p class="text-base font-semibold text-indigo-500">
                {{ trustyPreview.amountOfMonths }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- Payment Tables -->
      <app-payment-table
        [payments]="executedPayments"
        title="Uitgevoerde betalingen"
        [isExecuted]="true"
      ></app-payment-table>
      <app-payment-table
        [payments]="remainingPayments"
        title="Resterende betalingen"
        [isExecuted]="false"
      ></app-payment-table>
    </div>

    <!-- Right side (1/3 of the width) -->
    <div class="w-1/3 pl-6 pr-6 pt-2 flex flex-col justify-between h-full">
      <p class="text-xl font-bold text-center">
        Proficiat!<br />U hebt een nieuw afbetalingsplan in
        {{ testCompletionTime }} seconden opgesteld!
      </p>
      
      <p class="text-xl font-semibold">
        Wilt u uw cashflow direct verbeteren en klantrelaties behouden?
      </p>

      <div class="flex flex-col gap-2">
        <button
          style="height: 41.6px"
          (click)="navigateToSignUp('toestaan')"
          class="bg-fuchsia-400 hover:bg-fuchsia-500 text-white font-bold py-2 px-4 rounded-lg w-full"
        >
          <div class="flex items-center justify-center">
            <i class="material-icons">check</i>
            <span> Afbetalingsplan toestaan</span>
          </div>
        </button>

        <button
          style="height: 41.6px"
          (click)="navigateToSignUp('aanvragen')"
          class="bg-purple-400 hover:bg-purple-500 text-white font-bold py-2 px-4 rounded-lg w-full"
        >
          <div class="flex items-center justify-center">
            <i class="material-icons">edit_note</i>
            <span>Afbetalingsplan aanvragen</span>
          </div>
        </button>

        <button
          (click)="navigateToStart()"
          class="primary-color-button rounded-lg w-full"
        >
          <div class="flex items-center justify-center">
            <i class="material-icons">home</i>
            <span>Terug naar de startpagina</span>
          </div>
        </button>
      </div>
    </div>
  </div>
</ng-container>
