import { inject } from '@angular/core'
import { Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { catchError, filter, map, of, switchMap } from 'rxjs'
import { UserProfileResponse } from '../Interfaces/UserProfile'
import { UserProfileState } from '../store/userprofile/userprofile.reducer'
import {
  selectExistsUserProfile,
  selectUserProfile,
  selectUserProfileLoaded,
  selectUserVerified,
} from '../store/userprofile/userprofile.selectors'

export const verifiedUserEmailGuard = () => {
  const store = inject(Store<UserProfileState>)
  const router = inject(Router)

  return store.select(selectUserVerified).pipe(
    filter((verified: boolean | null) => verified !== null),
    map((verified: boolean | null) => {
      if (!verified) {
        router.navigate(['/authentication-confirmation'])
        return false
      }

      return true
    }),
    catchError(error => {
      console.error('verifiedUserEmailGuard: Error in role check:', error)
      router.navigate(['/unauthorized'])
      return of(false)
    }),
  )
}

export const existsUserProfileGuard = () => {
  const store = inject(Store<UserProfileState>)
  const router = inject(Router)

  return store.select(selectExistsUserProfile).pipe(
    filter((exists: boolean | null) => exists !== null),
    map((exists: boolean | null) => {
      if (!exists) {
        router.navigate(['/unauthorized'])
        return false
      }

      return true
    }),
    catchError(error => {
      console.error('existsUserProfileGuard: Error in role check:', error)
      router.navigate(['/unauthorized'])
      return of(false)
    }),
  )
}

export const supplierGuard = () => {
  const store = inject(Store<UserProfileState>)
  const router = inject(Router)

  return store.select(selectUserProfileLoaded).pipe(
    filter(loaded => loaded === true),
    switchMap(() => store.select(selectUserProfile)),
    filter(userProfile => userProfile !== null),
    map((userProfile: UserProfileResponse | null) => {
      if (!userProfile || !userProfile.isSupplier) {
        console.log('supplierGuard: userProfile:', userProfile)
        router.navigate(['/unauthorized'])
        return false
      }
      return true
    }),
    catchError(error => {
      console.error('supplierGuard: Error in role check:', error)
      router.navigate(['/unauthorized'])
      return of(false)
    }),
  )
}

export const debtorGuard = () => {
  const store = inject(Store<UserProfileState>)
  const router = inject(Router)

  return store.select(selectUserProfileLoaded).pipe(
    filter(loaded => loaded === true),
    switchMap(() => store.select(selectUserProfile)),
    map((userProfile: UserProfileResponse | null) => {
      if (!userProfile || !userProfile.isDebtor) {
        router.navigate(['/unauthorized'])
        return false
      }
      return true
    }),
    catchError(error => {
      console.error('debtorGuard: Error in role check:', error)
      router.navigate(['/unauthorized'])
      return of(false)
    }),
  )
}
