<div class="trusty-details pl-6 pr-6 pb-6 bg-transparent rounded-2xl">
  <div class="relative flex items-center justify-center mb-4">
    <button
      type="button"
      (click)="navigateToTrusty()"
      class="absolute left-0 primary-color-button transition-colors shadow-lg"
    >
      Terug
    </button>
    <h2 class="text-3xl font-bold text-[#333333]">Preview</h2>
  </div>

  <!-- Top frames side by side -->
  <div class="flex flex-row gap-6 mb-4">
    <!-- Supplier Information -->
    <div
      class="flex-1 bg-white p-6 rounded-2xl shadow-md border-t-4 border-fuchsia-200"
    >
      <h3 class="text-xl font-semibold mb-4 text-[#333333]">
        Leveranciersinformatie
      </h3>

      <div class="space-y-4">
        <div *ngIf="!isDebtor">
          <label class="block text-sm font-medium text-[#333333]"
            >Debiteur</label
          >
          <p class="mt-1 text-lg font-semibold text-indigo-500">
            {{ trustyPreview?.debtor?.name }}
          </p>
        </div>

        <div *ngIf="isDebtor">
          <label class="block text-sm font-medium text-[#333333]"
            >Leveranciersnaam</label
          >
          <p class="mt-1 text-lg font-semibold text-indigo-500">
            {{ trustyPreview?.supplierName }}
          </p>
        </div>

        <div>
          <label class="block text-sm font-medium text-[#333333]"
            >Totale schuld</label
          >
          <p class="mt-1 text-lg font-semibold text-indigo-500">
            {{
              trustyPreview?.totalAmount
                ? formatCurrency(trustyPreview!.totalAmount)
                : ''
            }}
          </p>
        </div>

        <div>
          <label class="block text-sm font-medium text-[#333333]"
            >Overeenkomstdatum</label
          >
          <p class="mt-1 text-lg font-semibold text-indigo-500">
            {{ formattedDate }}
          </p>
        </div>
      </div>
    </div>

    <!-- Payment Terms -->
    <div
      class="flex-1 bg-white p-6 rounded-2xl border-t-4 shadow-md border-fuchsia-200"
    >
      <h3 class="text-xl font-semibold mb-4 text-[#333333]">
        Betalingsvoorwaarden
      </h3>

      <div class="space-y-4">
        <div>
          <label class="block text-sm font-medium text-[#333333]"
            >Maandelijkse betaling</label
          >
          <p class="mt-1 text-lg font-semibold text-indigo-500">
            {{
              trustyPreview?.minAmount
                ? formatCurrency(trustyPreview!.minAmount)
                : ''
            }}
          </p>
        </div>

        <div>
          <label class="block text-sm font-medium text-[#333333]"
            >Interest</label
          >
          <p class="mt-1 text-lg font-semibold text-indigo-500">
            {{ trustyPreview?.interestRate }}%
          </p>
        </div>

        <div>
          <label class="block text-sm font-medium text-[#333333]"
            >Aantal maanden</label
          >
          <p class="mt-1 text-lg font-semibold text-indigo-500">
            {{ trustyPreview?.amountOfMonths }}
          </p>
        </div>
      </div>
    </div>

    <!-- Payment overview -->
    <div
      class="flex-1 bg-white p-6 rounded-2xl border-t-4 shadow-md border-fuchsia-200"
    >
      <h3 class="text-xl font-semibold mb-4 text-[#333333]">
        Betalingsoverzicht
      </h3>

      <div class="space-y-4">
        <div>
          <label class="block text-sm font-medium text-[#333333]"
            >Reeds betaald</label
          >
          <p class="mt-1 text-lg font-semibold text-indigo-500">
            {{ formatCurrency(totalAmountPaid()) }}
          </p>
        </div>

        <div>
          <label class="block text-sm font-medium text-[#333333]"
            >Nog te betalen</label
          >
          <p class="mt-1 text-lg font-semibold text-indigo-500">
            {{ formatCurrency(totalAmountRemaining()) }}
          </p>
        </div>
        <div>
          <label class="block text-sm font-medium text-[#333333]"
            >Resterende maanden</label
          >
          <p class="mt-1 text-lg font-semibold text-indigo-500">
            {{ getRemainingMonths() }}
          </p>
        </div>
      </div>
    </div>
  </div>

  <!-- Payment Tables -->
  <app-payment-table
    [payments]="executedPayments"
    title="Uitgevoerde betalingen"
    [isExecuted]="true"
  ></app-payment-table>

  <app-payment-table
    [payments]="remainingPayments"
    title="Resterende betalingen"
    [isExecuted]="false"
  ></app-payment-table>
</div>
