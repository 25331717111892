import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from '../../../../environments/environment'
import {
  DebtorOnboardRequest,
  SupplierOnboardRequest,
  UserExistsResponse,
  UserVerifiedResponse,
} from '../../shared/Interfaces/Onboarding'
import {
  UserProfileResponse,
  UserProfileUpdateRequest,
} from '../../shared/Interfaces/UserProfile'
import { LoadingService } from './loading-service'

@Injectable({
  providedIn: 'root',
})
export class TrustyUserService {
  constructor(
    private http: HttpClient,
    private loadingService: LoadingService,
  ) {}

  existsUser(): Observable<UserExistsResponse> {
    return this.http.get<UserExistsResponse>(
      `${environment.apiUrl}/api/identity/exists`,
    )
  }

  verifiedUser(): Observable<UserVerifiedResponse> {
    return this.http.get<UserVerifiedResponse>(
      `${environment.apiUrl}/api/identity/verified`,
    )
  }

  onboardSupplier(
    supplierOnboardRequest: SupplierOnboardRequest,
  ): Observable<UserProfileResponse> {
    this.loadingService.hideLoader()

    return this.http
      .post<UserProfileResponse>(
        `${environment.apiUrl}/api/onboarding/supplier`,
        supplierOnboardRequest,
      )
      .pipe(this.loadingService.attachLoader())
  }

  onboardDebtor(
    debtorOnboardRequest: DebtorOnboardRequest,
  ): Observable<UserProfileResponse> {
    this.loadingService.hideLoader()

    return this.http
      .post<UserProfileResponse>(
        `${environment.apiUrl}/api/onboarding/debtor`,
        debtorOnboardRequest,
      )
      .pipe(this.loadingService.attachLoader())
  }

  getUserProfile(): Observable<UserProfileResponse> {
    this.loadingService.hideLoader()

    return this.http
      .get<UserProfileResponse>(`${environment.apiUrl}/api/userprofile`)
      .pipe(this.loadingService.attachLoader())
  }

  updateUserProfile(
    userProfileUpdateRequest: UserProfileUpdateRequest,
  ): Observable<UserProfileResponse> {
    this.loadingService.hideLoader()

    return this.http
      .put<UserProfileResponse>(
        `${environment.apiUrl}/api/userprofile`,
        userProfileUpdateRequest,
      )
      .pipe(this.loadingService.attachLoader())
  }
}
