<footer class="footer text-white py-8">
  <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
    <div class="grid md:grid-cols-3 gap-8">
      <div>
        <h4 class="text-2xl font-semibold mb-4 text-center">Neem contact op</h4>
        <a
          routerLink="/contact"
          class="text-xl block text-center hover:text-blue-400 transition-colors"
        >
          Contactformulier
        </a>
      </div>
      <a href="http://alternatief.ai" target="_blank">
        <div class="flex flex-col items-center text-2xl">
          <img src="https://imgur.com/YeX0mTu.png" class="w-[50%]" />
        </div>
      </a>
      <div>
        <h4 class="text-2xl font-semibold mb-4 text-center">Volg ons</h4>
        <div class="flex space-x-4 justify-center">
          <a
            href="https://be.linkedin.com/company/alternatiefbv"
            class="text-xl hover:text-blue-400 transition-colors"
            target="_blank"
            >LinkedIn</a
          >
          <a
            href="https://www.facebook.com/bvbaAlternatief/"
            class="text-xl hover:text-blue-400 transition-colors"
            target="_blank"
            ><i class="material-icons">facebook</i></a
          >
          <a
            href="https://www.instagram.com/alternatief_ai?igsh=MWV6amltdjJrMDM0Mw=="
            class="text-xl hover:text-blue-400 transition-colors"
            target="_blank"
            >Instagram</a
          >
        </div>
      </div>
    </div>
  </div>
</footer>
