import { NgClass, NgForOf, NgIf } from '@angular/common'
import { Component, OnDestroy, OnInit } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { MatIconModule } from '@angular/material/icon'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { Store } from '@ngrx/store'
import { debounceTime, Subject, takeUntil } from 'rxjs'
import { handleGeneralSort, sortData } from '../../../../../util/sortData'
import { DashboardHeaderComponent } from '../../../../shared/components/dashboard-header/dashboard-header.component'
import {
  FilterConfig,
  getDefaultFilterConfig,
} from '../../../../shared/Interfaces/FilterConfig'
import {
  ExtraPayment,
  TrustyWithNextTheoreticalPayment,
} from '../../../../shared/Interfaces/internal/supplier-dashboard'
import {
  getDefaultSortConfig,
  SortConfig,
} from '../../../../shared/Interfaces/SortConfig'
import {
  TheoreticalPayment,
  TrustyResponse,
} from '../../../../shared/Interfaces/Trusty'
import {
  getTrusties,
  trustyExtraPayment,
  updateTrustyPayment,
} from '../../../../shared/store/trusty/trusty.actions'
import { TrustyState } from '../../../../shared/store/trusty/trusty.reducer'
import {
  selectTrusties,
  selectTrustyLoaded,
} from '../../../../shared/store/trusty/trusty.selectors'
import { toUTCDate } from '../../../services/data.service'
import { TrustyDetailsService } from '../../../services/trusty-detail-service'
import { AdvancedfiltersComponent } from './advancedfilters/advancedfilters.component'

type TrustyUpdateField = 'amount' | 'date'

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    FormsModule,
    NgClass,
    NgIf,
    NgForOf,
    MatIconModule,
    MatSnackBarModule,
    AdvancedfiltersComponent,
    DashboardHeaderComponent,
  ],
  templateUrl: './supplier-dashboard.component.html',
  styleUrl: './supplier-dashboard.component.css',
})
export class SupplierDashboardComponent implements OnInit, OnDestroy {
  private paymentChangeSubject = new Subject<{
    id: string
    field: TrustyUpdateField
    value: number | string
  }>()

  sortConfig: SortConfig = getDefaultSortConfig()
  filters: FilterConfig = getDefaultFilterConfig()
  showAdvancedFilters = false
  private destroy$ = new Subject<void>()

  tableHeaders = [
    { key: 'debtor', label: 'Klant' },
    { key: 'amount', label: 'Bedrag' },
    { key: 'date', label: 'Datum' },
    { key: 'status', label: 'Status' },
    { key: 'action', label: 'Actie' },
  ]
  newExtraPayment: ExtraPayment = { id: '', debtor: '', amount: 0, date: '' }
  trustiesWithPayment: TrustyWithNextTheoreticalPayment[] = []

  constructor(
    private store: Store<TrustyState>,
    private trustyDetailService: TrustyDetailsService,
  ) {
    this.paymentChangeSubject
      .pipe(debounceTime(750))
      .subscribe(({ id, field, value }) => {
        this.updatePayment(id, field, value)
      })
  }

  ngOnInit(): void {
    this.store
      .select(selectTrustyLoaded)
      .pipe(takeUntil(this.destroy$))
      .subscribe(loaded => {
        if (!loaded) {
          this.store.dispatch(getTrusties())
        }
      })

    this.store
      .select(selectTrusties)
      .pipe(takeUntil(this.destroy$))
      .subscribe((trusties: TrustyResponse[]) => {
        this.selectTrustiesWithPayment(trusties)
      })
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  get filteredAndSortedTrustiesAndPayments() {
    const filteredTrustiesPayments = this.trustiesWithPayment.filter(
      trustyWithLastPayment =>
        trustyWithLastPayment.trusty.remainingAmount > 0 &&
        trustyWithLastPayment.trusty.debtor.name
          .toLowerCase()
          .includes(this.filters.debtor.toLowerCase()) &&
        trustyWithLastPayment.nextPayment.totalAmount
          .toString()
          .includes(this.filters.amount) &&
        trustyWithLastPayment.nextPayment.dueDate.includes(this.filters.date) &&
        (this.filters.amountMin === '' ||
          trustyWithLastPayment.nextPayment.totalAmount >=
            Number(this.filters.amountMin)) &&
        (this.filters.amountMax === '' ||
          trustyWithLastPayment.nextPayment.totalAmount <=
            Number(this.filters.amountMax)) &&
        (this.filters.dateMin === '' ||
          trustyWithLastPayment.nextPayment.dueDate >= this.filters.dateMin) &&
        (this.filters.dateMax === '' ||
          trustyWithLastPayment.nextPayment.dueDate <= this.filters.dateMax),
    )
    return sortData(filteredTrustiesPayments, this.sortConfig)
  }

  toggleAdvancedFilters() {
    this.showAdvancedFilters = !this.showAdvancedFilters
  }

  handleSort(key: string) {
    handleGeneralSort(key, this.sortConfig)
  }

  handleFilterChange(filters: any) {
    this.filters = filters
  }

  applyFilters() {
    this.showAdvancedFilters = false
  }

  handlePaymentChange(
    id: string,
    field: TrustyUpdateField,
    value: number | string,
  ) {
    this.paymentChangeSubject.next({ id, field, value })
  }

  canEditAmountAndDate(state: string): boolean {
    return state !== 'Confirmed'
  }

  handleProcessPayment(id: string) {
    const trustyWithPayment = this.trustiesWithPayment.find(
      p => p.trusty.id === id,
    )

    if (!trustyWithPayment || trustyWithPayment.state === 'Confirmed') return

    if (trustyWithPayment.state === 'Upcoming') {
      this.trustiesWithPayment = this.trustiesWithPayment.map(twp =>
        twp.trusty.id === id ? { ...twp, state: 'Modified' } : twp,
      )
      return
    }

    if (trustyWithPayment.state === 'Modified') {
      this.trustiesWithPayment = this.trustiesWithPayment.map(twp =>
        twp.trusty.id === id ? { ...twp, state: 'Pending' } : twp,
      )
      return
    }

    if (trustyWithPayment.state === 'Pending') {
      this.store.dispatch(
        updateTrustyPayment({
          trustyId: trustyWithPayment.trusty.id,
          trustyUpdatePaymentRequest: {
            paymentNumber: trustyWithPayment.nextPayment.paymentNumber,
            date: toUTCDate(
              trustyWithPayment.nextPayment.dueDate,
            ).toISOString(),
            totalAmount: trustyWithPayment.nextPayment.totalAmount,
            interestAmount: trustyWithPayment.nextPayment.interestAmount,
            principalAmount: trustyWithPayment.nextPayment.principalAmount,
          },
        }),
      )
    }
  }

  private updatePayment(
    id: string,
    field: TrustyUpdateField,
    value: number | string,
  ) {
    this.trustiesWithPayment = this.trustiesWithPayment.map(
      trustyWithPayment => {
        if (
          trustyWithPayment.trusty.id !== id ||
          !this.canEditAmountAndDate(trustyWithPayment.state)
        ) {
          return trustyWithPayment
        }

        const updatedTrustyWithPayment: TrustyWithNextTheoreticalPayment =
          JSON.parse(JSON.stringify(trustyWithPayment))

        if (
          field === 'amount' ||
          (field === 'date' && typeof value === 'string')
        ) {
          // Update date if applicable
          updatedTrustyWithPayment.nextPayment.dueDate =
            field === 'date'
              ? (value as string)
              : updatedTrustyWithPayment.nextPayment.dueDate

          // Update amount if applicable
          const newAmount =
            field === 'amount'
              ? Number(value)
              : updatedTrustyWithPayment.nextPayment.totalAmount

          updatedTrustyWithPayment.nextPayment =
            this.trustyDetailService.calculatePaymentBasedOnNewValue(
              updatedTrustyWithPayment.trusty,
              updatedTrustyWithPayment.nextPayment,
              newAmount,
            )
        }

        if (
          !this.validateUpdatedAndOriginalTrusty(
            updatedTrustyWithPayment,
            trustyWithPayment,
          )
        ) {
          updatedTrustyWithPayment.state = 'Modified'
        }

        return updatedTrustyWithPayment
      },
    )
  }

  handleExtraPaymentSubmit() {
    if (
      !this.newExtraPayment.id ||
      !this.newExtraPayment.amount ||
      !this.newExtraPayment.date
    ) {
      return
    }

    this.store.dispatch(trustyExtraPayment(this.newExtraPayment))

    // Reset the form after submission
    this.newExtraPayment = {
      id: '',
      debtor: '',
      amount: 0,
      date: '',
    }
    this.store.select(selectTrustyLoaded).subscribe(loaded => {
      if (loaded) {
        this.store.dispatch(getTrusties())
      }
    })
  }

  onDebtorSelect(trustyId: string) {
    const selectedTrusty = this.filteredAndSortedTrustiesAndPayments.find(
      t => t.trusty.id === trustyId,
    )
    if (selectedTrusty) {
      this.newExtraPayment.debtor = selectedTrusty.trusty.debtor.name
    }
  }

  private selectTrustiesWithPayment(trusties: TrustyResponse[]) {
    this.trustiesWithPayment = trusties
      .filter(x => x.remainingAmount > 0)
      .map<TrustyWithNextTheoreticalPayment>(trusty => ({
        trusty: trusty,
        nextPayment: this.getTrustyNextPayment(trusty),
        state: 'Upcoming',
      }))
  }

  private getTrustyNextPayment(trusty: TrustyResponse): TheoreticalPayment {
    return trusty.theoreticalPayments
      .filter(
        payment =>
          !payment.isPaid &&
          toUTCDate(payment.dueDate).getTime() >=
            toUTCDate(new Date()).getTime(),
      )
      .sort(
        (payment1, payment2) =>
          toUTCDate(payment1.dueDate).getTime() -
          toUTCDate(payment2.dueDate).getTime(),
      )[0]
  }

  private validateUpdatedAndOriginalTrusty(
    updatedTrustyWithPayment: TrustyWithNextTheoreticalPayment,
    trustyWithPayment: TrustyWithNextTheoreticalPayment,
  ) {
    return (
      updatedTrustyWithPayment.nextPayment.totalAmount ===
        trustyWithPayment.nextPayment.totalAmount &&
      updatedTrustyWithPayment.nextPayment.dueDate ===
        trustyWithPayment.nextPayment.dueDate
    )
  }
}
