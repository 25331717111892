import { Injectable } from '@angular/core'
import {
  AppState,
  AuthService,
  LogoutOptions,
  User,
} from '@auth0/auth0-angular'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class TrustyAuthService {
  public user$: Observable<User | null | undefined> = this.auth.user$
  public isLoggedIn$: Observable<boolean> = this.auth.isAuthenticated$

  constructor(private auth: AuthService) {}

  login(redirectPage?: string): void {
    let appState: AppState | undefined = redirectPage
      ? { target: redirectPage }
      : undefined

    this.auth.loginWithRedirect({
      authorizationParams: {
        screen_hint: 'signup',
      },
      appState: appState,
    })
  }

  logout(returnLocation?: string): void {
    let logoutOptions: LogoutOptions = {
      logoutParams: {
        returnTo: returnLocation,
      },
    }

    this.auth.logout(logoutOptions)
  }
}
