import { CommonModule } from '@angular/common'
import { Component, OnDestroy } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { Router } from '@angular/router'
import { TrustyResponse } from '../../../../shared/Interfaces/Trusty'

@Component({
  selector: 'app-trusty-creation-success',
  standalone: true,
  imports: [CommonModule, MatIconModule],
  templateUrl: './trusty-creation-success.component.html',
  styleUrl: './trusty-creation-success.component.css',
})
export class TrustyCreationSuccessComponent implements OnDestroy {
  trusty: TrustyResponse | null = null

  constructor(private router: Router) {
    this.trusty = this.router.getCurrentNavigation()?.extras
      ?.state as TrustyResponse
  }

  ngOnDestroy(): void {
    this.trusty = null
  }

  navigateTo(destination: 'dashboard' | 'create' | 'request') {
    switch (destination) {
      case 'dashboard':
        this.router.navigate(['supplier-dashboard'])
        break
      case 'create':
        this.router.navigate(['trusty-creation'])
        break
      case 'request':
        this.router.navigate(['trusty-ask-form'])
        break
    }
  }
}
