<div class="pr-4 pb-4 pl-4 bg-transparent rounded-2xl">
  <app-debtor-onboard-header [currentStep]="4"></app-debtor-onboard-header>

  <div class="bg-white p-4 rounded-2xl border-t-4 mb-4">
    <h2 class="text-xl font-bold mb-2 text-indigo-500">
      Belangrijkste voordelen van Trust Your Plan:
    </h2>
    <ul class="space-y-2">
      <li class="flex items-center">
        <span class="mr-2 text-[#333333]">🛡️</span>
        <span
          ><strong class="text-[#333333]">Boetevrije oplossing</strong></span
        >
      </li>
      <li class="flex items-center">
        <span class="mr-2 text-[#333333]">🕒</span>
        <span
          ><strong class="text-[#333333]"
            >Flexibele betalingsmogelijkheden</strong
          ></span
        >
      </li>
      <li class="flex items-center">
        <span class="mr-2 text-[#333333]">📈</span>
        <span
          ><strong class="text-[#333333]"
            >Mogelijkheid om de betalingsperiode te verkorten</strong
          ></span
        >
      </li>
      <li class="flex items-center">
        <span class="mr-2 text-[#333333]">👍</span>
        <span
          ><strong class="text-[#333333]"
            >Behouden van een goede relatie</strong
          ></span
        >
      </li>
    </ul>
  </div>

  <div class="mb-4 bg-white p-4 rounded-2xl border-t-4">
    <h2
      *ngIf="selectedRole === undefined"
      class="text-xl mb-2 font-bold text-indigo-500"
    >
      Dossierkosten: € 40 (incl. 21% BTW)
    </h2>
    <h2
      *ngIf="selectedRole === 1"
      class="text-xl mb-2 font-bold text-indigo-500"
    >
      Dossierkosten: € 40 (incl. 21% BTW)
    </h2>
    <h2
      *ngIf="selectedRole === 2"
      class="text-xl mb-2 font-bold text-indigo-500"
    >
      Dossierkosten: € 60 (excl. 21% BTW)
    </h2>
    <h2
      *ngIf="selectedRole === 3"
      class="text-xl mb-2 font-bold text-indigo-500"
    >
      Dossierkosten: € 80 (excl. 21% BTW)
    </h2>
    <p class="text-[#333333] mb-2 font-semibold">
      Door deze eenmalige vergoeding te betalen, krijg je toegang tot de
      volgende functies:
    </p>
    <ul class="space-y-2">
      <li class="flex items-center">
        <span class="mr-2 text-[#333333]">📝</span>
        <span class="text-[#333333]"
          ><strong>Je gepersonaliseerde afbetalingsplan</strong></span
        >
      </li>
      <li class="flex items-center">
        <span class="mr-2 text-[#333333]">🌐</span>
        <span class="text-[#333333]"
          ><strong>24/7 online toegang tot de details van je plan</strong></span
        >
      </li>
      <li class="flex items-center">
        <span class="mr-2 text-[#333333]">📅</span>
        <span class="text-[#333333]"
          ><strong
            >Automatische maandelijkse betalingsherinneringen</strong
          ></span
        >
      </li>
      <li class="flex items-center">
        <span class="mr-2 text-[#333333]">💰</span>
        <span class="text-[#333333]"
          ><strong
            >Mogelijkheid om extra of hogere betalingen te doen, wat de
            terugbetalingsperiode kan verkorten</strong
          ></span
        >
      </li>
    </ul>
  </div>

  <div class="mb-4">
    <label class="flex items-center space-x-2">
      <input
        type="checkbox"
        [(ngModel)]="agreedToTerms"
        class="form-checkbox h-5 w-5 text-blue-600"
      />
      <span class="text-sm font-semibold" (click)="openTYPEngagement()">
        Akkoord met de <i>Algemene Voorwaarden</i> en de
        <i>Trust Your Plan Engagement</i> van Alternatief BV.
      </span>
    </label>
  </div>

  <button
    (click)="onContinue()"
    [disabled]="!agreedToTerms"
    class="w-full primary-color-button flex items-center justify-center"
    [ngClass]="{ 'opacity-50 cursor-not-allowed': !agreedToTerms }"
  >
    Bevestigen
  </button>
</div>
