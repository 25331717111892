<app-dashboard-header></app-dashboard-header>
<div class="bg-transparent pb-4 rounded-2xl">
  <div class="bg-white shadow rounded-2xl border-t-4 p-4 mb-4">
    <div class="flex space-x-2 mb-6">
      <input
        type="text"
        name="debtor"
        [(ngModel)]="filters.debtor"
        placeholder="Klant"
        class="flex-1 p-2 border rounded-md"
      />
      <input
        type="text"
        name="amount"
        [(ngModel)]="filters.amount"
        placeholder="Bedrag"
        class="flex-1 p-2 border rounded-md"
      />
      <input
        type="date"
        name="date"
        [(ngModel)]="filters.date"
        class="flex-1 p-2 border rounded-md"
      />
    </div>

    <div class="overflow-x-auto">
      <!-- Scrollable container with fixed height -->
      <div class="max-h-80 overflow-y-auto relative">
        <table class="min-w-full divide-y divide-gray-200">
          <thead class="bg-gray-50 sticky top-0 z-10">
            <tr>
              <th
                *ngFor="let header of tableHeaders"
                (click)="handleSort(header.key)"
                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider bg-gray-50"
              >
                <div class="flex items-center justify-between">
                  {{ header.label }}
                  <mat-icon *ngIf="sortConfig.key === header.key" class="ml-2">
                    {{
                      sortConfig.direction === 'ascending'
                        ? 'arrow_upward'
                        : 'arrow_downward'
                    }}
                  </mat-icon>
                </div>
              </th>
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200">
            <tr
              *ngFor="let trustyWithState of filteredAndSortedTrusties"
              [ngClass]="{
                'bg-red-100': trustyWithState.state === 'Kritiek',
                'bg-green-100': trustyWithState.state === 'Actief',
                'bg-amber-100': trustyWithState.state === 'In afwachting',
              }"
            >
              <td class="px-6 py-4 whitespace-nowrap">
                {{ trustyWithState.trusty.debtor.name }}
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                {{ trustyWithState.trusty.totalAmount }} €
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                {{ trustyWithState.trusty.amountOfMonths }} maanden
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                {{ trustyWithState.trusty.interestRate }} %
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                {{ trustyWithState.state }}
              </td>
              <td class="pr-6 py-4 whitespace-nowrap">
                <div class="flex justify-end space-x-2">
                  <button
                    (click)="viewTrusty(trustyWithState.trusty)"
                    class="bg-emerald-400 text-white hover:bg-emerald-500 px-3 py-1 rounded flex items-center"
                  >
                    Bekijk
                  </button>
                  <button
                    (click)="changeTrusty(trustyWithState.trusty)"
                    class="bg-indigo-400 text-white hover:bg-indigo-500 px-3 py-1 rounded flex items-center"
                  >
                    Aanpassen
                  </button>
                  <button
                    (click)="stopTrusty(trustyWithState.trusty)"
                    class="bg-rose-400 text-white hover:bg-rose-500 px-3 py-1 rounded flex items-center"
                  >
                    Stop
                  </button>
                </div>
              </td>
              <td>
                <button
                  (click)="doBemiddeling(trustyWithState.trusty)"
                  class="bg-amber-400 text-white hover:bg-amber-500 px-3 py-1 rounded flex items-center"
                >
                  Bemiddeling
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <!-- Belangrijke informatie section -->
  <div class="flex justify-center">
    <button
      (click)="showInfo = !showInfo"
      class="mb-2 bg-indigo-400 text-white hover:bg-indigo-500 px-3 py-1 rounded flex items-center"
    >
      *Informatie over ACTIE
    </button>
  </div>

  <div
    *ngIf="showInfo"
    class="p-4 bg-gray-100 border-gray-500 text-[#333333] rounded-2xl"
  >
    <ul class="list-disc space-y-4 text-lg" style="list-style-type: none">
      <li>
        <button
          class="bg-emerald-400 text-white px-3 py-1 rounded flex items-center shadow-md"
        >
          Bekijk</button
        >Geeft je een gedetailleerd overzicht van de Trusty met de uitgevoerde
        betalingen en resterende betalingen.
      </li>
      <li>
        <button
          class="center bg-indigo-400 text-white px-3 py-1 rounded flex items-center shadow-md"
        >
          Aanpassen</button
        >Door het wijzigen van het saldo, het aantal resterende maanden of de
        rente creëer je een nieuwe Trusty. De bestaande (versie 1) stopt en
        wordt vervangen door de nieuwe (versie 2).
      </li>
      <li>
        <button
          class="bg-rose-400 text-white px-3 py-1 rounded flex items-center shadow-md"
        >
          Stop
        </button>
        In dit geval stop je de Trusty en neem je het beheer van het resterende
        bedrag weer over.
      </li>
      <li>
        EXTRA'S
        <button
          class="bg-amber-400 text-white px-3 py-1 rounded flex items-center shadow-md"
        >
          Bemiddeling</button
        >Deze optie genereert een bestand van alle gebeurtenissen en stuurt het
        naar Alternatief voor minnelijke incasso. Je ontvangt ook een bestand om
        akkoord te gaan met een vast tarief, gecompenseerd door boetes uit je
        factuurvoorwaarden.
      </li>
    </ul>
  </div>
</div>
