<section class="bg-white rounded-3xl shadow-lg p-8 detail mb-8">
  <div class="flex flex-col justify-between h-full">
    <div>
      <h3 class="text-2xl font-bold mb-4">
        <div class="flex items-center">
          <svg
            width="30px"
            height="30px"
            viewBox="-2 0 20 20"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            fill="#ffffff"
            class="mr-2"
          >
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              <title>lightning [#1262]</title>
              <desc>Created with Sketch.</desc>
              <defs></defs>
              <g
                id="Page-1"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <g
                  id="Dribbble-Light-Preview"
                  transform="translate(-62.000000, -2559.000000)"
                  fill="#fbbf24"
                >
                  <g id="icons" transform="translate(56.000000, 160.000000)">
                    <polygon
                      id="lightning-[#1262]"
                      points="14 2419 14 2411 6 2411 14 2399 14 2407 22 2407"
                    ></polygon>
                  </g>
                </g>
              </g>
            </g>
          </svg>
          Bliksemsnelle oplossing
        </div>
      </h3>
      <p class="text-gray-600 mb-4">
        Snel handelen is vaak cruciaal bij het oplossen van betalingsproblemen.
        Trust Your Plan biedt u binnen 60 seconden een oplossing op maat:
      </p>
      <ul class="list-disc list-inside text-gray-600">
        <li>
          <b>Digitaal: </b> Alle stappen voor een afbetalingsplan (Trusty) kan
          je online regelen.
        </li>
        <li>
          <b>Automatisch: </b>Geen tijdverlies meer met opmaak en opvolging van
          afbetalingsplannen
        </li>
        <li>
          <b>Flexibel: </b>Trust Your Plan herberekent uw plan op basis van de
          betalingen
        </li>
        <li><b>Snel: </b>Razendsnelle oplossingen voor directe resultaten</li>
      </ul>
      <div class="flex justify-between items-center">
        <p>
          Met Trust Your Plan kunt u razendsnel handelen en tegelijkertijd uw
          relaties met klanten verbeteren.
        </p>
        <button
          class="bg-amber-300 hover:bg-amber-400 text-white font-bold py-6 px-10 text-xl rounded-lg"
          (click)="navigateToSpeedtest()"
        >
          Doe de test!
        </button>
      </div>
    </div>
  </div>
</section>

<div class="flex">
  <div class="w-full pb-6 pl-6 pt-6 bg-white rounded-2xl shadow-lg mb-4">
    <h2 class="text-3xl font-bold mb-6 text-center">
      Hoe Trust Your Plan werkt
    </h2>
    <div class="grid grid-cols-2 gap-6">
      @for (
        step of steps;
        track step.title;
        let i = $index;
        let isLast = $last
      ) {
        <div class="flex items-start">
          <div class="flex flex-col items-center mr-4">
            <div [class]="'rounded-full p-3 ' + step.color + ' text-white'">
              <i [innerHTML]="step.icon"></i>
            </div>
          </div>
          <div>
            <h3 class="text-xl font-bold mb-1">
              {{ i + 1 }}. {{ step.title }}
            </h3>
            <p class="text-gray-600">{{ step.description }}</p>
          </div>
        </div>
      }
    </div>
  </div>
  <!-- <div class="w-1/2 p-6">
    <section class="mb-16">
      <h3 class="text-2xl text-center font-bold mb-6">
        Wat onze gebruikers zeggen
      </h3>
      <div class="grid md:grid-cols-1 gap-8">
        <div
          *ngFor="let testimonial of testimonials"
          class="bg-white p-6 rounded-3xl shadow"
        >
          <p class="italic mb-4">"{{ testimonial.quote }}"</p>
          <p class="font-semibold text-right">- {{ testimonial.author }}</p>
        </div>
      </div>
    </section>
  </div> -->
</div>
