<div
  class="max-w-7xl mx-auto pr-6 pl-6 pb-6 bg-transparent rounded-2xl"
  style="margin-bottom: 2rem"
>
  <app-debtor-onboard-header [currentStep]="3"></app-debtor-onboard-header>

  <form [formGroup]="debtorForm" (ngSubmit)="onSubmit()" class="space-y-6">
    <app-onboarding-contact
      headerText="Duid uw type profiel aan:"
      [showExtraInformationField]="false"
      [selectedRole]="selectedRole"
      [userProfileData]="userProfile$ | async"
    ></app-onboarding-contact>
    <button
      [disabled]="
        !debtorForm.valid ||
        (!onboardingContactComponent?.isValidForm() ?? false)
      "
      type="submit"
      class="w-full primary-color-button mt-4 disabled:opacity-50 disabled:cursor-not-allowed"
    >
      Registreren
    </button>
  </form>
</div>
