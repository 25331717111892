import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from '../../../../environments/environment'
import { ContactFormRequest } from '../../shared/Interfaces/Client'
import { LoadingService } from './loading-service'

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  constructor(
    private http: HttpClient,
    private loadingService: LoadingService,
  ) {}

  sendContactForm(contactForm: ContactFormRequest): Observable<object> {
    this.loadingService.hideLoader()

    return this.http
      .post(`${environment.apiUrl}/api/client/contact`, contactForm)
      .pipe(this.loadingService.attachLoader())
  }
}
