<app-dashboard-header></app-dashboard-header>
<div class="bg-transparent pb-4 rounded-2xl">
  <app-payment-information></app-payment-information>

  <!-- Preview and Submit Buttons -->
  <div id="buttons" class="mt-8 flex justify-between">
    <button
      type="button"
      (click)="togglePreview()"
      class="primary-color-button"
    >
      Preview Trusty
    </button>
    <button
      (click)="updateTrusty()"
      type="submit"
      class="px-4 py-2 bg-fuchsia-400 text-white rounded-md hover:bg-fuchsia-500 transition-colors shadow-lg"
    >
      Pas Trusty aan
    </button>
  </div>
</div>
