import { Injectable } from '@angular/core'
import { TrustyDetail } from '../../shared/Interfaces/internal/trusty-detail'
import {
  TheoreticalPayment,
  TrustyResponse,
} from '../../shared/Interfaces/Trusty'
import { toUTCDate } from './data.service'

@Injectable({
  providedIn: 'root',
})
export class TrustyDetailsService {
  calculatePaymentPlan(
    totalDebt: number,
    monthlyPayment: number,
    interestRate: number,
    numberOfMonths: number,
    agreementDate: Date,
    firstPaymentDate: Date,
  ): TrustyDetail {
    let balance = totalDebt
    let currentDate = toUTCDate(agreementDate)

    const startPayment = {
      reference: 'Start',
      date: agreementDate,
      payment: 0,
      capital: 0,
      interest: 0,
      balance: balance,
    }
    const allPayments = []

    for (let i = 1; i <= numberOfMonths; i++) {
      const paymentReference = `REF${String(i).padStart(3, '0')}`

      const nextDate = this.addMonths(firstPaymentDate, i - 1)
      const daysBetween = this.calculateDaysBetween(
        toUTCDate(nextDate),
        toUTCDate(currentDate),
      )
      currentDate = nextDate

      const interestAmount =
        ((balance * (interestRate / 100)) / 365) * daysBetween

      let capitalAmount = monthlyPayment - interestAmount
      let payment = monthlyPayment
      if (balance + interestAmount <= monthlyPayment) {
        payment = balance + interestAmount
        capitalAmount = balance
        balance = 0
      } else {
        balance -= capitalAmount
      }
      allPayments.push({
        reference: paymentReference,
        date: currentDate,
        payment: payment,
        capital: capitalAmount,
        interest: interestAmount,
        balance: balance,
      })
      if (balance === 0) break
    }

    return {
      executedPayments: [startPayment],
      remainingPayments: allPayments.map(payment => ({
        ...payment,
      })),
    }
  }

  calculatePaymentBasedOnNewValue(
    trusty: TrustyResponse,
    nextPayment: TheoreticalPayment,
    value: string | number,
  ): TheoreticalPayment {
    const totalAmount = value as number

    const currentBalance = this.calculateCurrentBalance(trusty)
    const daysBetween = this.calculateDaysBetween(
      toUTCDate(trusty.lastPaymentDate || trusty.startDate),
      toUTCDate(nextPayment.dueDate),
    )

    const minimumInterest = this.calculateInterestAmount(
      currentBalance,
      trusty.interestRate,
      daysBetween,
    )

    return this.handlePaymentNormal(nextPayment, totalAmount, minimumInterest)
  }

  private calculateInterestAmount(
    balance: number,
    interestRate: number,
    days: number,
  ): number {
    return parseFloat(
      (((balance * (interestRate / 100)) / 365) * days).toFixed(2),
    )
  }

  private calculateDaysBetween(startDate: Date, endDate: Date): number {
    const diffTime = Math.abs(endDate.getTime() - startDate.getTime())
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24))
  }

  private calculateCurrentBalance(trusty: TrustyResponse): number {
    const paidPayments = trusty.theoreticalPayments.filter(p => p.isPaid)
    const totalPaidPrincipal = paidPayments.reduce(
      (sum, p) => sum + p.principalAmount,
      0,
    )
    return trusty.totalAmount - totalPaidPrincipal
  }

  private handlePaymentNormal(
    nextPayment: TheoreticalPayment,
    totalAmount: number,
    interestAmount: number,
  ) {
    nextPayment.totalAmount = totalAmount
    nextPayment.interestAmount = interestAmount
    nextPayment.principalAmount = totalAmount - interestAmount
    return nextPayment
  }

  private addMonths(date: Date, months: number): Date {
    const newDate = toUTCDate(date)
    newDate.setMonth(newDate.getMonth() + months)
    return newDate
  }
}
