import { AsyncPipe, NgClass, NgIf } from '@angular/common'
import { Component, OnDestroy, OnInit } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { filter, Subject, takeUntil } from 'rxjs'
import { UserProfileResponse } from '../../Interfaces/UserProfile'
import { UserProfileState } from '../../store/userprofile/userprofile.reducer'
import { selectUserProfile } from '../../store/userprofile/userprofile.selectors'

@Component({
  selector: 'app-dashboard-header',
  standalone: true,
  imports: [MatIconModule, NgClass, NgIf, AsyncPipe],
  templateUrl: './dashboard-header.component.html',
  styleUrl: './dashboard-header.component.css',
})
export class DashboardHeaderComponent implements OnInit, OnDestroy {
  notActive: boolean = true
  private destroy$ = new Subject<void>()
  userProfile: UserProfileResponse | null = null

  constructor(
    public router: Router,
    private store: Store<UserProfileState>,
  ) {}

  ngOnInit(): void {
    this.store
      .select(selectUserProfile)
      .pipe(
        takeUntil(this.destroy$),
        filter((userProfile: UserProfileResponse) => userProfile !== null),
      )
      .subscribe((userProfile: UserProfileResponse) => {
        this.userProfile = userProfile
      })
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  createNewTrusty() {
    this.router.navigate(['trusty-creation'])
  }

  askNewTrusty() {
    this.router.navigate(['trusty-ask-form'])
  }

  goToReport() {
    this.router.navigate(['report'])
  }

  goToSettings() {
    this.router.navigate(['profile'])
  }

  goToManagement() {
    this.router.navigate(['management'])
  }
}
