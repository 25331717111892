<app-dashboard-header></app-dashboard-header>
<div class="bg-transparent pb-4 rounded-2xl">
  <form
    *ngIf="dataLoaded"
    [formGroup]="userProfileForm"
    (ngSubmit)="onSubmit()"
  >
    <!-- Bedrijfsinformatie -->
    <app-onboarding-contact
      [showExtraInformationField]="false"
      [selectedRole]="selectedRole"
      [userProfileData]="userProfile$ | async"
      [shouldShowRoleSelection]="false"
    ></app-onboarding-contact>

    <!-- Three frames next to each other -->
    <div class="grid grid-cols-5 gap-6">
      <!-- Trusty Limieten -->
      <div
        *ngIf="(userProfile$ | async)?.isSupplier"
        class="col-span-2 bg-white p-6 rounded-2xl shadow-md border-t-4"
      >
        <div class="flex items-center">
          <h2 class="text-2xl font-semibold mb-4 text-[#333333]">
            Trusty limieten
          </h2>
          <app-info-icon
            class="ml-4"
            [infoText]="
              'Duid hier aan hoeveel maanden krediet u maximaal wilt toestaan aan uw debiteuren en wat het minimum bedrag is dat ze in ieder geval altijd zouden moeten betalen. Leg hier ook een interestvoet tussen 0% (u hanteert geen interest bij uw afbetalingsplan) en 12,5% (u gebruikt de maximum toegelaten wettelijke interestvoet) vast.'
            "
          ></app-info-icon>
        </div>
        <div class="mb-4">
          <app-form-field
            label="Maximale termijnen (maanden)"
            fieldId="maxTerms"
            type="number"
            [control]="userProfileForm.get('maxTerms')"
            [numberConfig]="{ min: 2, max: 60, step: 1 }"
            [customErrorMessages]="{
              min: 'Maximale termijnen moeten tussen 2 en 60 zijn',
              max: 'Maximale termijnen moeten tussen 2 en 60 zijn',
            }"
          ></app-form-field>
        </div>
        <div class="mb-4">
          <app-form-field
            label="Minimaal bedrag (€)"
            fieldId="minAmount"
            type="number"
            [control]="userProfileForm.get('minAmount')"
            [numberConfig]="{ min: 1, step: 5 }"
            [customErrorMessages]="{
              min: 'Minimaal bedrag moet 1 of hoger zijn',
            }"
          ></app-form-field>
        </div>
        <div class="mb-4">
          <app-form-field
            label="Rentepercentage (%)"
            fieldId="interestRate"
            type="number"
            [control]="userProfileForm.get('interestRate')"
            [numberConfig]="{ min: 0, max: 12.5, step: 0.5 }"
            [customErrorMessages]="{
              min: 'Rentepercentage moet tussen 0 en 12.5 zijn',
              max: 'Rentepercentage moet tussen 0 en 12.5 zijn',
            }"
          ></app-form-field>
        </div>
      </div>

      <!-- Bankinformatie -->
      <div
        *ngIf="(userProfile$ | async)?.isSupplier"
        class="col-span-2 bg-white p-6 rounded-2xl shadow-md border-t-4 flex flex-col justify-between"
      >
        <h2 class="text-2xl font-semibold mb-4 text-[#333333]">
          Bankinformatie
        </h2>
        <div class="mb-4">
          <app-form-field
            label="IBAN"
            fieldId="iban"
            type="text"
            [control]="userProfileForm.get('iban')"
            [customErrorMessages]="{
              required: 'IBAN is verplicht',
            }"
          ></app-form-field>
        </div>
        <div class="mb-4">
          <app-form-field
            label="Banknaam"
            fieldId="bankName"
            type="text"
            [control]="userProfileForm.get('bankName')"
            [customErrorMessages]="{
              required: 'IBAN is verplicht',
            }"
          ></app-form-field>
        </div>
      </div>

      <!-- Submit Button -->
      <div
        [ngClass]="{
          'col-span-1': (userProfile$ | async)?.isSupplier,
          'col-span-5 bg-white p-6 rounded-2xl shadow-md border-t-4': !(
            userProfile$ | async
          )?.isSupplier,
        }"
      >
        <button
          type="submit"
          [disabled]="!userProfileForm.valid"
          class="w-full bg-indigo-400 text-white px-6 py-3 rounded-lg shadow-md hover:bg-indigo-500 focus:outline-none focus:ring-opacity-50 transition-colors duration-300 ease-in-out hover:bg-green-400 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          Opslaan
        </button>
      </div>
    </div>
  </form>
</div>
