import { createFeatureSelector, createSelector } from '@ngrx/store'
import { TrustyResponse } from '../../Interfaces/Trusty'
import { trustyFeatureKey } from './trusty.reducer'

const trustyFeature = createFeatureSelector(trustyFeatureKey)

export const selectTrusties = createSelector(
  trustyFeature,
  (trusty: any) => trusty?.supplierTrusties,
)

export const selectDebtorTrusties = createSelector(
  trustyFeature,
  (trusty: any) => trusty?.debtorTrusties,
)

export const selectTrusty = (trustyId: string) =>
  createSelector(trustyFeature, (trusty: any) =>
    trusty?.supplierTrusties.find((x: TrustyResponse) => x.id === trustyId),
  )

export const selectTrustyError = createSelector(
  trustyFeature,
  (trusty: any) => trusty?.error,
)

export const selectTrustyLoaded = createSelector(
  trustyFeature,
  (state: any) => state?.loaded,
)
