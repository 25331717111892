import { AsyncPipe, NgForOf, NgIf } from '@angular/common'
import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core'
import {
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms'
import { MatDialog, MatDialogModule } from '@angular/material/dialog'
import { MatIconModule } from '@angular/material/icon'
import { ActivatedRoute, Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { BehaviorSubject, filter, Subject, take } from 'rxjs'
import { ConfirmationDialogComponent } from '../../../../shared/components/confirmation-dialog/confirmation-dialog.component'
import { FormFieldComponent } from '../../../../shared/components/form-field/form-field.component'
import { InfoIconComponent } from '../../../../shared/components/info-icon/info-icon.component'
import {
  getRole,
  getRoleStringValueFromNumber,
  Role,
} from '../../../../shared/constants/role'
import { TYPEngagement, TYPTC } from '../../../../shared/constants/TYPTexts'
import { SupplierOnboardRequest } from '../../../../shared/Interfaces/Onboarding'
import { UserProfileResponse } from '../../../../shared/Interfaces/UserProfile'
import {
  createSupplierUserProfile,
  updateUserProfile,
} from '../../../../shared/store/userprofile/userprofile.actions'
import { UserProfileState } from '../../../../shared/store/userprofile/userprofile.reducer'
import {
  selectUserProfile,
  selectUserProfileLoaded,
} from '../../../../shared/store/userprofile/userprofile.selectors'
import { OnboardingContactComponent } from '../onboarding-contact/onboarding-contact.component'
import { SupplierOnboardHeaderComponent } from './supplier-onboard-header/supplier-onboard-header.component'

@Component({
  selector: 'app-supplier-onboarding',
  standalone: true,
  imports: [
    AsyncPipe,
    ReactiveFormsModule,
    NgIf,
    NgForOf,
    MatIconModule,
    MatDialogModule,
    InfoIconComponent,
    OnboardingContactComponent,
    SupplierOnboardHeaderComponent,
    ConfirmationDialogComponent,
    FormFieldComponent,
  ],
  templateUrl: './supplier-onboarding.component.html',
  styleUrl: './supplier-onboarding.component.css',
})
export class SupplierOnboardingComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private store: Store<UserProfileState>,
    public dialog: MatDialog,
  ) {}

  private destroy$ = new Subject<void>()

  supplierForm: UntypedFormGroup = new UntypedFormGroup({})
  selectedRole = new BehaviorSubject('particulier')
  termsRead = true
  trustyRead = true
  termsAgreed = false
  trustyAgreed = false
  userProfile$ = this.store
    .select(selectUserProfile)
    .pipe(filter((profile): profile is UserProfileResponse => !!profile))

  public isDebtorToSupplier: boolean = false

  @ViewChild(OnboardingContactComponent)
  onboardingContactComponent!: OnboardingContactComponent

  ngOnInit() {
    this.initForm()

    this.route.queryParams.subscribe(params => {
      this.isDebtorToSupplier = params['isDebtorToSupplier'] === 'true'
    })

    this.userProfile$.subscribe(profile => {
      this.route.queryParams.subscribe(params => {
        if (profile && params['redirect']) {
          this.router.navigate([params['redirect']])
        }
      })

      if (profile && profile.isSupplier) {
        this.router.navigate(['trusty-creation'])
      }
    })
  }

  ngAfterViewInit() {
    this.route.queryParams.subscribe(params => {
      if (params['email'] || params['phone'] || params['name']) {
        this.onboardingContactComponent.onboardingContactForm.patchValue({
          contactEmail: params['email'],
          contactPhone: params['phone'],
          contactName: params['name'],
        })
        this.onboardingContactComponent.selectedRole.next(
          getRoleStringValueFromNumber(parseInt(params['role'])),
        )
      }
    })
  }

  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }

  private initForm() {
    this.supplierForm = this.fb.group({
      iban: [null, Validators.required],
      bankName: [null, Validators.required],
      maxTerms: [
        12,
        [Validators.required, Validators.min(1), Validators.max(60)],
      ],
      minAmount: [50, [Validators.required, Validators.min(0)]],
      interestRate: [
        5,
        [Validators.required, Validators.min(0), Validators.max(100)],
      ],
    })
  }

  onSubmit() {
    this.markFormGroupTouched(this.supplierForm)

    if (this.isDebtorToSupplier && this.supplierForm.valid) {
      this.onboardDebtorAsSupplier()
    } else if (
      this.supplierForm.valid &&
      this.onboardingContactComponent.isValidForm()
    ) {
      this.onboardNewSupplier()
    }
  }

  private onboardDebtorAsSupplier() {
    this.store
      .select(selectUserProfile)
      .pipe(
        filter(userProfile => userProfile !== null),
        take(1),
      )
      .subscribe((userProfile: UserProfileResponse) => {
        this.store.dispatch(
          updateUserProfile({
            isSupplier: true,
            isDebtor: userProfile.isDebtor,
            contactPersons: userProfile.contactPersons,
            company: userProfile.company,
            address: userProfile.address,
            bank: {
              bankName: this.supplierForm.value['bankName'],
              iban: this.supplierForm.value['iban'],
            },
            paymentPlanParameters: {
              maxDurationInMonths: this.supplierForm.value['maxTerms'],
              minimumAmount: this.supplierForm.value['minAmount'],
              defaultInterestRate: this.supplierForm.value['interestRate'],
            },
          }),
        )
      })

    this.store
      .select(selectUserProfileLoaded)
      .pipe(
        filter(x => x === true),
        take(1),
      )
      .subscribe(loaded => {
        if (loaded) {
          this.router.navigate(['trusty-creation'])
        }
      })
  }

  private onboardNewSupplier() {
    const onboardingContactForm =
      this.onboardingContactComponent.onboardingContactForm.value

    const selectedRole = getRole(
      this.onboardingContactComponent.selectedRole.value,
    )

    const supplierOnboardRequest: SupplierOnboardRequest = {
      role: this.onboardingContactComponent.selectedRole.value,
      contactPersons: [
        {
          isMain: true,
          name: onboardingContactForm['contactName'],
          email: onboardingContactForm['contactEmail'],
          phone: onboardingContactForm['contactPhone'],
        },
      ],
      company:
        selectedRole === Role.particulier
          ? null
          : {
              name: onboardingContactForm['companyName'],
              address: onboardingContactForm['address'],
              postalCode: onboardingContactForm['postalCode'],
              city: onboardingContactForm['city'],
              country: onboardingContactForm['country'],
              btwNummer: onboardingContactForm['vatNumber'],
            },
      bank: {
        bankName: this.supplierForm.value['bankName'],
        iban: this.supplierForm.value['iban'],
      },
      paymentPlanParameters: {
        maxDurationInMonths: this.supplierForm.value['maxTerms'],
        minimumAmount: this.supplierForm.value['minAmount'],
        defaultInterestRate: this.supplierForm.value['interestRate'],
      },
      address:
        selectedRole === Role.bedrijf
          ? null
          : {
              address: onboardingContactForm['contactAddress'],
              city: onboardingContactForm['contactCity'],
              postalCode: onboardingContactForm['contactPostalCode'],
              country: onboardingContactForm['contactCountry'],
            },
    }
    if (
      onboardingContactForm['extraContactName'] !== undefined &&
      onboardingContactForm['extraContactEmail'] !== undefined &&
      onboardingContactForm['extraContactPhone'] !== undefined
    ) {
      supplierOnboardRequest.contactPersons.push({
        isMain: false,
        name: onboardingContactForm['extraContactName'],
        email: onboardingContactForm['extraContactEmail'],
        phone: onboardingContactForm['extraContactPhone'],
      })
    }

    this.store.dispatch(createSupplierUserProfile(supplierOnboardRequest))

    this.store.select(selectUserProfileLoaded).subscribe(loaded => {
      if (loaded) {
        this.router.navigate(['trusty-creation'])
      }
    })
  }

  handleTermsAgree(event: Event) {
    const target = event.target as HTMLInputElement
    if (!this.termsRead && target.checked) {
      event.preventDefault()
    } else {
      this.termsAgreed = target.checked
    }
  }

  openTermsAndConditions() {
    const confirmDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Trust Your Plan Terms & Conditions',
        HTMLContent: TYPTC,
        showCancelButton: false,
      },
    })

    confirmDialogRef.afterClosed().subscribe((result: boolean) => {
      if (result === true) {
        this.termsAgreed = true
        this.termsRead = true
      }
    })
  }

  openTYPEngagement() {
    const confirmDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Trust Your Plan Engagement',
        HTMLContent: TYPEngagement,
        showCancelButton: false,
      },
    })

    confirmDialogRef.afterClosed().subscribe((result: boolean) => {
      if (result === true) {
        this.trustyAgreed = true
        this.trustyRead = true
      }
    })
  }

  handleTrustyAgree(event: Event) {
    const target = event.target as HTMLInputElement
    if (!this.trustyRead && target.checked) {
      event.preventDefault()
    } else {
      this.trustyAgreed = target.checked
    }
  }

  markFormGroupTouched(formGroup: UntypedFormGroup) {
    Object.values(formGroup.controls).forEach(control => {
      control.markAsTouched()
      if (control instanceof UntypedFormGroup) {
        this.markFormGroupTouched(control)
      }
    })
  }
}
