import { createAction, props } from '@ngrx/store'
import {
  TrustyActualPaymentUpdateRequest,
  TrustyCreateRequest,
  TrustyDebtorInviteSupplierRequest,
  TrustyResponse,
  TrustyUpdateRequest,
} from '../../Interfaces/Trusty'
import { ExtraPayment } from '../../Interfaces/internal/supplier-dashboard'

export const getTrusties = createAction('[Trusty] get all')
export const getTrustiesSuccess = createAction(
  '[Trusty] get all Success',
  props<{ trustiesResponse: TrustyResponse[] }>(),
)
export const getTrustiesFailure = createAction(
  '[Trusty] get all Failure',
  props<{ error: any }>(),
)

export const getDebtorTrusties = createAction('[Trusty] debtor get all')
export const getDebtorTrustiesSuccess = createAction(
  '[Trusty] debtor get all Success',
  props<{ trustiesResponse: TrustyResponse[] }>(),
)
export const getDebtorTrustiesFailure = createAction(
  '[Trusty] debtor get all Failure',
  props<{ error: any }>(),
)

export const createTrusty = createAction(
  '[Trusty] create',
  props<TrustyCreateRequest>(),
)
export const createTrustySuccess = createAction(
  '[Trusty] create Success',
  props<TrustyResponse>(),
)
export const createTrustyFailure = createAction(
  '[Trusty] create Failure',
  props<{ error: any }>(),
)

export const updateTrusty = createAction(
  '[Trusty] update',
  props<{ trustyUpdateRequest: TrustyUpdateRequest }>(),
)
export const updateTrustySuccess = createAction(
  '[Trusty] update Success',
  props<TrustyResponse>(),
)
export const updateTrustyFailure = createAction(
  '[Trusty] update Failure',
  props<{ error: any }>(),
)

export const updateTrustyPayment = createAction(
  '[Trusty] update payment',
  props<{
    trustyId: string
    trustyUpdatePaymentRequest: TrustyActualPaymentUpdateRequest
  }>(),
)
export const updateTrustyPaymentSuccess = createAction(
  '[Trusty] update payment Success',
  props<TrustyResponse>(),
)
export const updateTrustyPaymentFailure = createAction(
  '[Trusty] update payment Failure',
  props<{ error: any }>(),
)

export const deleteTrusty = createAction(
  '[Trusty] delete',
  props<{ trustyId: string }>(),
)
export const deleteTrustySuccess = createAction(
  '[Trusty] delete Success',
  props<{ trustyId: string }>(),
)
export const deleteTrustyFailure = createAction(
  '[Trusty] delete Failure',
  props<{ error: any }>(),
)

export const trustyAssignDebtor = createAction(
  '[Trusty] debtor assign',
  props<{ trustyId: string }>(),
)
export const trustyAssignDebtorSuccess = createAction(
  '[Trusty] debtor assign Success',
)
export const trustyAssignDebtorFailure = createAction(
  '[Trusty] debtor assign Failure',
  props<{ error: any }>(),
)

export const trustyDebtorInviteSupplier = createAction(
  '[Trusty] debtor invite supplier',
  props<TrustyDebtorInviteSupplierRequest>(),
)
export const trustyDebtorInviteSupplierSuccess = createAction(
  '[Trusty] debtor invite supplier Success',
)
export const trustyDebtorInviteSupplierFailure = createAction(
  '[Trusty] debtor invite supplier Failure',
  props<{ error: any }>(),
)

export const trustyExtraPayment = createAction(
  '[Trusty] extra payment',
  props<ExtraPayment>(),
)
export const trustyExtraPaymentSuccess = createAction(
  '[Trusty] extra payment Success',
  props<TrustyResponse>(),
)
export const trustyExtraPaymentFailure = createAction(
  '[Trusty] extra payment Failure',
  props<{ error: any }>(),
)
