import { CommonModule } from '@angular/common'
import { Component, OnDestroy, OnInit } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { ActivatedRoute, Router } from '@angular/router'
import { filter, Subject, takeUntil, tap } from 'rxjs'
import { PaymentDetail } from '../../../../shared/Interfaces/internal/trusty-detail'
import { TrustyPreview } from '../../../../shared/Interfaces/internal/trusty-preview'
import { toUTCDate } from '../../../services/data.service'
import { TrustyAuthService } from '../../../services/trusty-auth-service'
import { TrustyDetailsService } from '../../../services/trusty-detail-service'
import { TrustyPreviewService } from '../../../services/trusty-preview-service'
import { PaymentTableComponent } from '../../trusty/payment-table/payment-table.component'

@Component({
  selector: 'app-trusty-preview',
  standalone: true,
  imports: [CommonModule, MatIconModule, PaymentTableComponent],
  templateUrl: './trusty-test.component.html',
  styleUrls: ['./trusty-test.component.css'],
})
export class TrustyTestComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>()

  constructor(
    private route: ActivatedRoute,
    private trustyDetailsService: TrustyDetailsService,
    private trustyService: TrustyPreviewService,
    private router: Router,
    private authService: TrustyAuthService,
  ) {}

  trustyPreview$ = this.trustyService.currentTrustyPreview$.pipe(
    filter((preview): preview is TrustyPreview => preview !== undefined),
    tap(preview => this.calculatePaymentPlan(preview)),
  )

  executedPayments: PaymentDetail[] = []
  remainingPayments: PaymentDetail[] = []
  testCompletionTime: number | undefined // Time in seconds

  ngOnInit(): void {
    this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe(params => {
      this.testCompletionTime = params['duration'] || null
    })
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
    this.trustyService.clearTrustyData()
  }

  formatCurrency(amount: number): string {
    return amount.toLocaleString('nl-NL', {
      style: 'currency',
      currency: 'EUR',
    })
  }

  getFormattedAgreementDate(trustyPreview: TrustyPreview): string {
    return trustyPreview.agreementDate
      ? this.formatDate(toUTCDate(trustyPreview.agreementDate))
      : 'Nog niet aanvaard'
  }

  private formatDate(date: Date): string {
    const year = date.getFullYear()
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const day = date.getDate().toString().padStart(2, '0')
    return `${day}-${month}-${year}`
  }

  navigateToSignUp(type: string) {
    console.log(type)
    this.authService.isLoggedIn$
      .pipe(takeUntil(this.destroy$))
      .subscribe(isLoggedIn => {
        switch (type) {
          case 'toestaan':
            console.log(isLoggedIn)
            if (isLoggedIn) {
              this.router.navigate(['trusty-creation'])
            } else {
              this.router.navigate(['supplier-onboarding-information'])
            }
            break

          case 'aanvragen':
            console.log(isLoggedIn)
            if (isLoggedIn) {
              this.router.navigate(['trusty-ask-form'])
            } else {
              this.router.navigate(['debtor-onboarding-information'])
            }
            break
        }
      })
  }

  navigateToStart() {
    this.router.navigate(['/'])
  }

  calculatePaymentPlan(preview: TrustyPreview): void {
    const paymentPlan = this.trustyDetailsService.calculatePaymentPlan(
      preview.totalAmount,
      preview.minAmount,
      preview.interestRate,
      preview.amountOfMonths,
      preview.agreementDate
        ? toUTCDate(preview.agreementDate)
        : toUTCDate(new Date()),
      toUTCDate(preview.startDate),
    )

    this.executedPayments = paymentPlan.executedPayments
    this.remainingPayments = paymentPlan.remainingPayments
  }
}
