import { Routes } from '@angular/router'
import { DebtorDashboardComponent } from './modules/main/components/debtor/dashboard/debtor-dashboard.component'
import { PaymentErrorComponent } from './modules/main/components/debtor/payment-error/payment-error.component'
import { PaymentSuccessComponent } from './modules/main/components/debtor/payment-success/payment-success.component'
import { DebtorWelcomeComponent } from './modules/main/components/debtor/welcome/debtor-welcome.component'
import { LandingComponent } from './modules/main/components/landing-page/landing.component'
import { DebtorOnboardInformationComponent } from './modules/main/components/onboarding/debtor-onboarding/debtor-onboarding-information/debtor-onboarding-information.component'
import { DebtorOnboardingComponent } from './modules/main/components/onboarding/debtor-onboarding/debtor-onboarding.component'
import { SupplierOnboardingInformationComponent } from './modules/main/components/onboarding/supplier-onboarding/supplier-onboarding-information/supplier-onboarding-information.component'
import { SupplierOnboardingComponent } from './modules/main/components/onboarding/supplier-onboarding/supplier-onboarding.component'
import { ManagementComponent } from './modules/main/components/supplier/dashboard/management/management.component'
import { SupplierDashboardComponent } from './modules/main/components/supplier/dashboard/supplier-dashboard.component'
import { ReportComponent } from './modules/main/components/supplier/report/report.component'
import { SpeedtestComponent } from './modules/main/components/test/speedtest/speedtest.component'
import { TrustyTestComponent } from './modules/main/components/test/trusty-test/trusty-test.component'
import { TrustyAskFormForComponent } from './modules/main/components/trusty/trusty-ask-form-for/trusty-ask-form-for.component'
import { TrustyAskFormComponent } from './modules/main/components/trusty/trusty-ask-form/trusty-ask-form.component'
import { TrustyChangeFormComponent } from './modules/main/components/trusty/trusty-change-form/trusty-change-form.component'
import { TrustyCreationFormComponent } from './modules/main/components/trusty/trusty-creation-form/trusty-creation-form.component'
import { TrustyCreationSuccessComponent } from './modules/main/components/trusty/trusty-creation-success/trusty-creation-success.component'
import { TrustyPaymentPlanPreviewComponent } from './modules/main/components/trusty/trusty-payment-plan-preview/trusty-payment-plan-preview.component'
import { TrustyPreviewComponent } from './modules/main/components/trusty/trusty-preview/trusty-preview.component'
import { UserProfileSettingsComponent } from './modules/main/components/user-profile-settings/user-profile-settings.component'
import { AuthenticationConfirmationComponent } from './modules/shared/components/authentication-confirmation/authentication-confirmation.component'
import { ContactFormComponent } from './modules/shared/components/contact-form/contact-form.component'
import { UnauthorizedComponent } from './modules/shared/components/unauthorized/unauthorized.component'
import { authGuard } from './modules/shared/guards/auth.guard'
import {
  debtorGuard,
  existsUserProfileGuard,
  supplierGuard,
  verifiedUserEmailGuard,
} from './modules/shared/guards/userRole.guard'

export const routes: Routes = [
  // Public routes - no auth required
  { path: '', component: LandingComponent, pathMatch: 'full' },

  // Trusty test routes
  { path: 'speedtest', component: SpeedtestComponent },
  { path: 'trusty-test', component: TrustyTestComponent },

  // Authentication confirmation routes
  {
    path: 'authentication-confirmation',
    component: AuthenticationConfirmationComponent,
  },

  {
    path: 'supplier-onboarding-information',
    component: SupplierOnboardingInformationComponent,
  },
  {
    path: 'debtor-onboarding-information',
    component: DebtorOnboardInformationComponent,
  },
  { path: 'trusty-preview', component: TrustyPreviewComponent },
  { path: 'contact', component: ContactFormComponent },
  { path: 'unauthorized', component: UnauthorizedComponent },

  // Private routes - auth required
  {
    path: 'supplier-onboarding',
    component: SupplierOnboardingComponent,
    canActivate: [authGuard, verifiedUserEmailGuard],
  },
  {
    path: 'supplier-dashboard',
    component: SupplierDashboardComponent,
    canActivate: [authGuard, existsUserProfileGuard, supplierGuard],
  },
  {
    path: 'management',
    component: ManagementComponent,
    canActivate: [authGuard, existsUserProfileGuard, supplierGuard],
  },
  {
    path: 'report',
    component: ReportComponent,
    canActivate: [authGuard, existsUserProfileGuard, supplierGuard],
  },
  {
    path: 'profile',
    component: UserProfileSettingsComponent,
    canActivate: [authGuard, existsUserProfileGuard],
  },
  {
    path: 'debtor-onboarding',
    component: DebtorOnboardingComponent,
    canActivate: [authGuard, verifiedUserEmailGuard],
  },
  {
    path: 'debtor-welcome',
    component: DebtorWelcomeComponent,
    canActivate: [authGuard, existsUserProfileGuard, debtorGuard],
  },
  {
    path: 'debtor/payment/success',
    component: PaymentSuccessComponent,
    canActivate: [authGuard, existsUserProfileGuard, debtorGuard],
  },
  {
    path: 'debtor/payment/error',
    component: PaymentErrorComponent,
    canActivate: [authGuard, existsUserProfileGuard, debtorGuard],
  },
  {
    path: 'trusty-creation',
    component: TrustyCreationFormComponent,
    canActivate: [authGuard, existsUserProfileGuard, supplierGuard],
  },
  {
    path: 'trusty-creation-success',
    component: TrustyCreationSuccessComponent,
    canActivate: [authGuard, existsUserProfileGuard, supplierGuard],
  },
  {
    path: 'trusty-adjust',
    component: TrustyPaymentPlanPreviewComponent,
    canActivate: [authGuard, existsUserProfileGuard, debtorGuard],
  },
  {
    path: 'trusty-ask-form',
    component: TrustyAskFormComponent,
    canActivate: [authGuard, existsUserProfileGuard],
  },
  {
    path: 'trusty-ask-form-for',
    component: TrustyAskFormForComponent,
    canActivate: [authGuard, existsUserProfileGuard],
  },
  {
    path: 'trusty-change',
    component: TrustyChangeFormComponent,
    canActivate: [authGuard, existsUserProfileGuard],
  },
  {
    path: 'debtor-dashboard',
    component: DebtorDashboardComponent,
    canActivate: [authGuard, existsUserProfileGuard, debtorGuard],
  },
]
