import { NgForOf, NgIf } from '@angular/common'
import { Component, OnDestroy, OnInit } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { filter, Subject, takeUntil } from 'rxjs'
import { Role } from '../../../../shared/constants/role'
import { TrustyResponse } from '../../../../shared/Interfaces/Trusty'
import { getDebtorTrusties } from '../../../../shared/store/trusty/trusty.actions'
import { TrustyState } from '../../../../shared/store/trusty/trusty.reducer'
import { selectDebtorTrusties } from '../../../../shared/store/trusty/trusty.selectors'
import { toUTCDate } from '../../../services/data.service'
import { TrustyDetailsService } from '../../../services/trusty-detail-service'
import { TrustyPreviewService } from '../../../services/trusty-preview-service'
import { DebtorOnboardHeaderComponent } from '../../onboarding/debtor-onboarding/debtor-onboard-header/debtor-onboard-header.component'

@Component({
  selector: 'app-trusty-payment-plan-preview',
  standalone: true,
  imports: [NgIf, FormsModule, NgForOf, DebtorOnboardHeaderComponent],
  templateUrl: './trusty-payment-plan-preview.component.html',
  styleUrl: './trusty-payment-plan-preview.component.css',
})
export class TrustyPaymentPlanPreviewComponent implements OnInit, OnDestroy {
  trusty: TrustyResponse | undefined = undefined
  private readonly destroy$ = new Subject<void>()

  private trustyId?: string = undefined

  private readonly MAX_MONTHS = 60
  private readonly MAX_DISPLAY_MONTHS = 24

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private trustyStore: Store<TrustyState>,
    private trustyDetailsService: TrustyDetailsService,
    private trustyPreviewService: TrustyPreviewService,
  ) {}

  maxPayment!: number
  installmentAmount!: number
  months!: number
  public selectedRole: string | undefined

  ngOnInit(): void {
    this.initializeFromRouteParams()
    this.initializeTrustyData()
  }

  private initializeFromRouteParams(): void {
    this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe(params => {
      this.selectedRole = params['debtorRole'] ?? this.selectedRole
      this.trustyId = params['trustyId'] ?? this.trustyId
    })
  }

  private initializeTrustyData(): void {
    this.trustyStore.dispatch(getDebtorTrusties())
    this.trustyStore
      .select(selectDebtorTrusties)
      .pipe(
        takeUntil(this.destroy$),
        filter((trusties: TrustyResponse[]) => trusties.length > 0),
        filter(() => !!this.trustyId),
      )
      .subscribe((trusties: TrustyResponse[]) => {
        const foundTrusty = trusties.find(x => x.id === this.trustyId)
        if (!foundTrusty) return

        this.trusty = foundTrusty
        this.initializePaymentState()
      })
  }

  private initializePaymentState(): void {
    if (!this.trusty) return

    this.maxPayment = this.trusty.totalAmount / 2
    this.installmentAmount = this.trusty.minAmount
    this.selectedRole ??= Role[this.trusty.debtor.role]
    this.calculateMonths()
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  calculateMonths(): void {
    const calculatedMonths = Math.ceil(
      this.trusty!.totalAmount / this.installmentAmount,
    )
    this.months = Math.min(calculatedMonths, this.MAX_MONTHS)
  }

  handleInstallmentChange(event: Event): void {
    const value = parseFloat((event.target as HTMLInputElement).value)
    if (value >= this.trusty!.minAmount && value <= this.maxPayment) {
      this.installmentAmount = value
      this.calculateMonths()
    }
  }

  formatDate(dateString: string): string {
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    }
    return toUTCDate(dateString).toLocaleDateString('nl-NL', options)
  }

  renderMonthCircles(): number[] {
    const displayMonths = Math.min(this.months, this.MAX_DISPLAY_MONTHS)
    return Array.from({ length: displayMonths }, (_, index) => index + 1)
  }

  confirmPaymentPlan(): void {
    this.router.navigate(['debtor-welcome'], {
      queryParams: {
        debtorRole: this.selectedRole,
        trustyId: this.trusty?.id,
      },
    })
  }

  previewTrusty(): void {
    const paymentPlan = this.trustyDetailsService.calculatePaymentPlan(
      this.trusty!.totalAmount,
      this.installmentAmount,
      this.trusty!.interestRate,
      this.months,
      this.trusty!.agreementDate
        ? toUTCDate(this.trusty!.agreementDate)
        : toUTCDate(new Date()),
      toUTCDate(this.trusty!.startDate),
    )

    this.trustyPreviewService.trustyPreview = {
      supplierName: this.trusty!.supplierName,
      debtor: {
        role: this.trusty!.debtor.role,
        name: this.trusty!.debtor.name,
        email: this.trusty!.debtor.email,
        phone: this.trusty!.debtor.phone,
      },
      totalAmount: this.trusty!.totalAmount,
      interestRate: this.trusty!.interestRate,
      amountOfMonths: this.months,
      minAmount: this.installmentAmount,
      startDate: toUTCDate(this.trusty!.startDate),
      invoices: this.trusty!.invoices,
      actualPayments: [],
      theoreticalPayments: paymentPlan.remainingPayments.map(x => ({
        paymentNumber: x.reference.toString(),
        totalAmount: x.payment,
        principalAmount: x.capital,
        interestAmount: x.interest,
        dueDate: x.date.toDateString(),
        isPaid: false,
      })),
      agreementDate: undefined,
    }

    this.router.navigate(['trusty-preview'])
  }
}
