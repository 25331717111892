export const TYPEngagement = `
<div class="typ-engagement">
  <h2>Leveranciersengagement Trust Your Plan</h2>

  <h3>Inleiding</h3>
  <p>Als leverancier verbinden wij ons tot een ethische en constructieve aanpak van betalingsachterstanden via het Trust Your Plan platform. Dit engagement weerspiegelt onze focus op eerlijke handelspraktijken en duurzame klantrelaties.</p>

  <h3>Engagement</h3>
  <p>Hierbij engageren wij ons persoonlijk dat:</p>

  <ol>
    <li>
      Voor elke klant die via Trust Your Plan een afbetalingsplan aanmaakt en naleeft, wij expliciet afzien van:
      <ul>
        <li>Het aanrekenen van schadebedingen</li>
        <li>Het toepassen van conventionele verhogingen</li>
        <li>Het doorrekenen van administratieve kosten gerelateerd aan de betalingsachterstand</li>
      </ul>
    </li>
    <li>
      Wij erkennen dat dit engagement van toepassing is op:
      <ul>
        <li>Alle facturen die worden opgenomen in een Trust Your Plan afbetalingsplan</li>
        <li>De volledige looptijd van het afbetalingsplan</li>
        <li>Alle toekomstige Trust Your Plan afbetalingsplannen</li>
      </ul>
    </li>
    <li>
      Bij het naleven van het afbetalingsplan door de klant:
      <ul>
        <li>Worden enkel de oorspronkelijke factuurbedragen gevorderd</li>
        <li>Kan enkel wettelijke interest worden aangerekend</li>
        <li>Worden geen bijkomende kosten of vergoedingen in rekening gebracht</li>
      </ul>
    </li>
  </ol>

  <h3>Voorwaarden</h3>
  <p>Dit engagement is van kracht onder de volgende voorwaarden:</p>
  <ol>
    <li>U, als klant, respecteert de overeengekomen betalingstermijnen binnen het Trust Your Plan afbetalingsplan</li>
    <li>Bij niet-naleving van het afbetalingsplan vervalt dit engagement</li>
    <li>Het engagement geldt uitsluitend voor afbetalingsplannen aangemaakt via het Trust Your Plan platform</li>
  </ol>

  <h3>Voordelen</h3>
  <p>Als tegenprestatie voor dit engagement krijgen wij:</p>
  <ol>
    <li>Onbeperkt gratis toegang tot het Trust Your Plan platform</li>
    <li>Ongelimiteerde mogelijkheid tot het aanmaken van afbetalingsplannen</li>
    <li>Toegang tot alle premium features van het platform</li>
  </ol>

  <h3>Duur en Geldigheid</h3>
  <ul>
    <li>Dit engagement gaat in vanaf de datum van aanvaarding</li>
    <li>Het blijft geldig voor onbepaalde duur zolang er een afbetalingsplan actief lopende is</li>
    <li>Bestaande afbetalingsplannen blijven onder de voorwaarden van dit engagement vallen</li>
  </ul>

  <h3>Bevestiging</h3>
  <p>Door dit engagement te aanvaarden, bevestig ik dat:</p>
  <ol>
    <li>Ik bevoegd ben om deze verbintenis aan te gaan namens mijn organisatie</li>
    <li>Ik de voorwaarden volledig heb gelezen en begrepen</li>
    <li>Ik akkoord ga met de elektronische vastlegging van deze overeenkomst</li>
    <li>Ik begrijp dat dit een juridisch bindend document is</li>
  </ol>
</div>`

export const TYPTC = `Trust Your Plan Terms&Conditions`
