<div class="mb-8 mt-4 p-4 bg-gray-50 rounded-2xl border border-t-4">
  <h2 class="text-2xl font-semibold mb-6 text-[#333333]">Schuldinformatie</h2>

  <div class="mb-8 p-4 bg-gray-100 rounded-2xl border border-gray-200">
    <div class="flex justify-between items-center mb-4">
      <label class="block text-sm font-medium text-[#333333]" for="totalAmount">
        Totaalbedrag
      </label>
    </div>

    <div class="text-sm">
      <i class="material-icons text-sm">info</i>
      Vul hier het totaalbedrag in:
    </div>
    <div class="flex space-x-4">
      <input
        class="w-1/2 p-2 bg-white border border-[#333333] rounded-md text-gray-700 focus:outline-none focus:ring-2 focus:ring-[#333333] focus:border-transparent"
        id="totalAmount"
        type="number"
        [(ngModel)]="totalAmount"
        [readonly]="readOnlyTotal"
        (blur)="onBlur()"
      />
    </div>
    <div *ngIf="totalAmount <= 0" class="text-red-500 text-sm mt-1">
      Totaal moet meer dan 0 zijn
    </div>
    <div class="space-y-4 text-xl"><u>OF</u></div>
    <div class="mt-4 space-y-2">
      <div class="text-sm">
        <i class="material-icons text-sm">info</i>
        Koppel hier factuur per factuur (totaal wordt automatisch berekend):
      </div>
      <div
        *ngFor="let invoice of invoices; let i = index"
        class="flex space-x-4"
      >
        <input
          class="flex-1 p-2 bg-white border border-[#333333] rounded-md text-[#333333] focus:outline-none focus:ring-2 focus:ring-[#333333] focus:border-transparent"
          type="text"
          [id]="'number-' + i"
          [(ngModel)]="invoice.invoiceNumber"
          (ngModelChange)="handleInvoiceChange(i, 'invoiceNumber', $event)"
          placeholder="Factuurnummer"
        />

        <input
          class="flex-1 p-2 bg-white border border-[#333333] rounded-md text-[#333333] focus:outline-none focus:ring-2 focus:ring-[#333333] focus:border-transparent"
          type="number"
          [id]="'amount-' + i"
          [(ngModel)]="invoice.amount"
          (ngModelChange)="handleInvoiceChange(i, 'amount', $event)"
          placeholder="Bedrag"
        />

        <button (click)="removeInvoice(i)" type="button">
          <i
            class="material-icons px-2 py-1 bg-red-500 text-white rounded hover:bg-red-600 transition-colors"
            >delete</i
          >
        </button>

        <button (click)="addInvoice()" type="button">
          <i
            class="material-icons px-2 py-1 bg-green-500 text-white rounded hover:bg-green-600 transition-colors"
            >add</i
          >
        </button>
      </div>
    </div>
  </div>

  <h3 class="text-lg font-medium mb-4 text-[#333333]">
    Veranderbare parameters
    <div class="text-sm">
      <i class="material-icons text-sm">info</i>
      De toegestane minimum aflossing en maximum termijn zijn vastgelegd in uw
      profiel. Bij aanpassing van één parameter wordt de andere automatisch
      herberekend.
    </div>
  </h3>

  <div class="grid grid-cols-2 gap-6">
    <div class="p-4">
      <label
        class="block text-sm font-medium text-[#333333] mb-2"
        for="minAmount"
      >
        Minimum maandelijkse aflossing
      </label>

      <input
        class="w-full p-2 bg-white border border-[#333333] rounded-md text-gray-700 focus:outline-none focus:ring-2 focus:ring-[#333333] focus:border-transparent"
        id="minAmount"
        type="number"
        [(ngModel)]="minAmount"
        (focus)="onFocus('minAmount')"
        (blur)="onBlur()"
        step="5"
        min="1"
      />
      <div *ngIf="minAmount < 1" class="text-red-500 text-sm mt-1">
        Minimum maandelijkse aflossing moet meer dan 1 zijn
      </div>
    </div>

    <div class="p-4">
      <label
        class="block text-sm font-medium text-[#333333] mb-2"
        for="numberOfMonths"
      >
        Maximum aantal maanden
      </label>

      <input
        class="w-full p-2 bg-white border border-[#333333] rounded-md text-[#333333] focus:outline-none focus:ring-2 focus:ring-[#333333] focus:border-transparent"
        id="numberOfMonths"
        type="number"
        [(ngModel)]="numberOfMonths"
        (ngModelChange)="handleNumberOfMonthsChange(Math.abs($event))"
        (focus)="onFocus('numberOfMonths')"
        (blur)="onBlur()"
        min="2"
        max="60"
      />

      <div
        *ngIf="numberOfMonths < 2 || numberOfMonths > 60"
        class="text-red-500 text-sm mt-1"
      >
        Aantal maanden moet tussen 2 en 60 zijn
      </div>
    </div>

    <div class="p-4">
      <label
        class="block text-sm font-medium text-[#333333] mb-2"
        for="interestRate"
      >
        Interest (%)
      </label>

      <input
        class="w-full p-2 bg-white border border-[#333333] rounded-md text-[#333333] focus:outline-none focus:ring-2 focus:ring-[#333333] focus:border-transparent"
        id="interestRate"
        type="number"
        [(ngModel)]="interestRate"
        (ngModelChange)="handleInterestRateChange($event)"
        (focus)="onFocus('interestRate')"
        (blur)="onBlur()"
        step="0.5"
        min="0"
        max="12.5"
      />
      <div
        *ngIf="interestRate < 0 || interestRate > 12.5"
        class="text-red-500 text-sm mt-1"
      >
        Interest moet tussen 0 en 12.5 zijn
      </div>
    </div>

    <div class="p-4">
      <label
        class="block text-sm font-medium text-[#333333] mb-2"
        for="startDate"
      >
        Datum eerste betaling
      </label>

      <input
        class="w-full p-2 bg-white border border-[#333333] rounded-md text-[#333333] focus:outline-none focus:ring-2 focus:ring-[#333333] focus:border-transparent"
        id="startDate"
        type="date"
        [(ngModel)]="formattedStartDate"
        (ngModelChange)="onDateChange($event)"
      />
    </div>
  </div>
</div>
