<div class="bg-transparent pl-4 pr-4 pb-4 rounded-2xl">
  <div class="relative flex items-center justify-center mb-6 pb-4">
    <button
      type="button"
      (click)="navigateBack()"
      class="absolute left-0 primary-color-button transition-colors shadow-lg"
    >
      Terug
    </button>
    <h2 class="text-3xl font-bold text-[#333333] gradient-text">
      Aanvraagformulier
    </h2>
  </div>

  <!-- Buttons for choosing type -->
  <app-onboarding-contact
    headerText="Ik vraag een Trusty aan van een:"
    [selectedRole]="selectedRole"
  ></app-onboarding-contact>

  <!-- Preview and Submit Buttons -->
  <div id="buttons" class="flex justify-center">
    <button
      (click)="submitForm()"
      type="submit"
      [disabled]="!selectedRole"
      [class.disabled-button]="!selectedRole"
      class="px-4 py-2 bg-fuchsia-400 text-white rounded-md hover:bg-fuchsia-500 transition-colors shadow-lg"
    >
      Verstuur aanvraag
    </button>
  </div>
</div>
