import { createAction, props } from '@ngrx/store'
import {
  DebtorOnboardRequest,
  SupplierOnboardRequest,
} from '../../Interfaces/Onboarding'
import {
  UserProfileResponse,
  UserProfileUpdateRequest,
} from '../../Interfaces/UserProfile'

export const verifiedUser = createAction('[User] verified')
export const verifiedUserSuccess = createAction(
  '[User] verified Success',
  props<{ verified: boolean }>(),
)
export const verifiedUserFailure = createAction(
  '[User] verified Failure',
  props<{ verified: boolean }>(),
)

export const existsUserProfile = createAction('[UserProfile] exists')
export const existsUserProfileSuccess = createAction(
  '[UserProfile] exists Success',
  props<{ exists: boolean }>(),
)
export const existsUserProfileFailure = createAction(
  '[UserProfile] exists Failure',
  props<{ exists: boolean }>(),
)
export const getUserProfile = createAction('[UserProfile] get')
export const getUserProfileSuccess = createAction(
  '[UserProfile] get Success',
  props<{ userProfileResponse: UserProfileResponse }>(),
)
export const getUserProfileFailure = createAction(
  '[UserProfile] get Failure',
  props<{ error: any }>(),
)

export const createSupplierUserProfile = createAction(
  '[UserProfile] supplier create',
  props<SupplierOnboardRequest>(),
)
export const createSupplierUserProfileSuccess = createAction(
  '[UserProfile] supplier create Success',
  props<{ userProfileResponse: UserProfileResponse }>(),
)
export const createSupplierUserProfileFailure = createAction(
  '[UserProfile] supplier create Failure',
  props<{ error: any }>(),
)

export const createDebtorUserProfile = createAction(
  '[UserProfile] debtor create',
  props<DebtorOnboardRequest>(),
)
export const createDebtorUserProfileSuccess = createAction(
  '[UserProfile] debtor create Success',
  props<{ userProfileResponse: UserProfileResponse }>(),
)
export const createDebtorUserProfileFailure = createAction(
  '[UserProfile] debtor create Failure',
  props<{ error: any }>(),
)

export const updateUserProfile = createAction(
  '[UserProfile] update',
  props<UserProfileUpdateRequest>(),
)
export const updateUserProfileSuccess = createAction(
  '[UserProfile] update Success',
  props<{ userProfileResponse: UserProfileResponse }>(),
)
export const updateUserProfileFailure = createAction(
  '[UserProfile] update Failure',
  props<{ error: any }>(),
)
