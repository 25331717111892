import { NgForOf } from '@angular/common'
import { Component } from '@angular/core'

interface FAQ {
  question: string
  answer: string
  isOpen: boolean // Add this property
}

@Component({
  selector: 'app-faq',
  standalone: true,
  imports: [NgForOf],
  templateUrl: './faq.component.html',
  styleUrl: './faq.component.css',
})
export class FaqComponent {
  constructor() {}

  faqs: FAQ[] = [
    {
      question: 'Hoe profiteert mijn bedrijf van Trust Your Plan?',
      answer: `Trust Your Plan helpt u positieve klantrelaties te behouden terwijl uw cashflow verbetert. 
      Het biedt flexibele betalingsoplossingen die zowel voor u als voor uw klanten werken. 
      Bovendien is het voor u, als leverancier, gratis te gebruiken.`,
      isOpen: false,
    },
    {
      question: 'Is Trust Your Plan moeilijk om op te zetten?',
      answer: `Helemaal niet! Trust Your Plan is ontworpen voor een snelle en eenvoudige setup. 
      U kunt binnen enkele minuten na registratie beginnen met het maken van betalingsregelingen. 
      Eens uw setup voltooid, maakt u binnen de 60 seconden een nieuw plan.`,
      isOpen: false,
    },
    {
      question: 'Hoeveel kost Trust Your Plan?',
      answer: `Voor een leverancier is het platform gratis te gebruiken. 
      De klant die een Trusty verkrijgt betaalt een vergoeding afhankelijk of hij een privaat persoon is, een zelfstandige of een bedrijf. 
      Door het engagement van de leverancier, is er geen schadebeding van toepassing, wat resulteert in een win-win situatie voor de klant.`,
      isOpen: false,
    },
    {
      question: 'Kan ik ook met automatische inningen werken?',
      answer: `Ja, betalingen kunnen via een SEPA Direct Debit (SDD) of domiciliëring worden geïnd. 
      Dit zorgt voor tijdige betalingen, vermindert administratieve lasten en helpt klanten hun betalingsverplichtingen na te komen zonder extra kosten of juridische stappen. 
      Wenst u deze optie contacteer dan jonas.devlieger@alternatiefbv.be`,
      isOpen: false,
    },
    {
      question: 'Hoe profiteer ik als debiteur van Trust Your Plan?',
      answer: `Trust Your Plan helpt u uw betalingsverplichtingen op een flexibele en beheersbare manier na te komen. 
      Het biedt oplossingen die uw cashflow verbeteren en tegelijkertijd uw relatie met leveranciers behouden. 
      Bovendien voorkomt het extra kosten en juridische stappen door tijdige betalingen te faciliteren.`,
      isOpen: false,
    },
    {
      question:
        'Kan ik als leverancier de kostenbijdrage van mijn klanten zelf betalen?',
      answer: `Zeker! Als leverancier kunt u ervoor kiezen om de Trust Your Plan dienstverlening aan te bieden als een extra service voor uw klanten en zelf in te staan voor de betaling hiervan. 
      Dit versterkt uw klantrelaties en toont uw betrokkenheid bij hun succes. 
      Neem hiervoor contact op via het contactformulier dat u onderaan de website vindt.`,
      isOpen: false,
    },
    {
      question: 'Waarom zou ik vandaag nog starten met Trust Your Plan? ',
      answer: `Uit de praktijk blijkt dat het uitstellen van een aangetekende ingebrekestelling om een relatie te behouden, vaak leidt tot langere wachttijden op betalingen en uiteindelijk toch juridische stappen. 
      Het resultaat? Langere wachttijden op uw geld en meestal verlies van de relatie. Met Trust Your Plan ontvangt u sneller uw geld en behoudt u uw relaties. 
      <b>Probeer het uit en merk het resultaat!</b>`,
      isOpen: false,
    },
    // {
    //   question:
    //     'Hoe onderscheidt Trust Your Plan zich van traditionele incasso? ',
    //   answer: `Trust Your Plan biedt een positieve, relatiegerichte aanpak die uw klantrelaties versterkt in plaats van ze op het spel te zetten.
    //   U behoudt de volledige controle over het proces en bouwt tegelijkertijd aan duurzame zakelijke relaties. `,
    //   isOpen: false,
    // },
    {
      question: 'Wat gebeurt als een Trusty niet (meer) betaald wordt?',
      answer: `In dat geval zal de klant nog tweemaal aangemaand worden om toch de betalingen te hervatten. 
      Gebeurt dit niet, dan heeft u als leverancier de mogelijkheid om het plan stop te zetten, door te sturen naar ons ter invordering (op een ethisch, oplossingsgerichte manier) of het beheer van hetgeen nog openstaat zelf terug over te nemen. `,
      isOpen: false,
    },
    {
      question:
        'Wat is de garantie dat mijn data vertrouwelijk worden behandeld?',
      answer: `<u><a href="http://alternatief.ai/">Alternatief BV</a></u> die het platform heeft ontwikkeld en beheert is GDPR compliant. 
      Bovendien werken wij alleen met gecertificeerde dienstverleners zowel wat betreft de registratieprocedure als wat het beheer van de data betreft.`,
      isOpen: false,
    },
    {
      question:
        'Welke groeimogelijkheden biedt Trust Your Plan voor mijn bedrijf?',
      answer: `Trust Your Plan biedt een laagdrempelige start voor slim debiteurenbeheer. 
      Groeit uw bedrijf en heeft u behoefte aan meer automatisering? Dan kunt u naadloos overstappen naar <u><a href="http://alternatief.ai/">Sales2Cash</a></u>, dat uitgebreide features biedt zoals: 
      <ul>
        <li>Automatische bankkoppelingen voor directe betalingsverwerking</li>
        <li>Geavanceerde matching van facturen en betalingen</li>
        <li>Slimme herinneringsflows</li>
        <li>Uitgebreide rapportagemogelijkheden</li>
        <li>Integraties met uw boekhoudpakket</li>
      </ul>
      <br>
      Begin vandaag met Trust Your Plan en groei wanneer u er klaar voor bent door naar het complete <u><a href="http://alternatief.ai/">Sales2Cash</a></u> platform. `,
      isOpen: false,
    },
  ]
}
