<section class="text-center">
  <h1 class="text-4xl font-bold mb-2 text-fuchs leading-tight mt-2">
    <span
      (click)="toggleRotation()"
      style="color: rgb(51, 51, 51); font-size: 85%"
      [class.fade-out]="fadeState === 'out'"
      [class.fade-in]="fadeState === 'in'"
    >
      {{ currentText }}
    </span>
  </h1>
</section>
<app-split-usp></app-split-usp>
<app-how-it-works></app-how-it-works>
<app-faq></app-faq>
