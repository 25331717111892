<app-dashboard-header></app-dashboard-header>

<app-supplier-onboard-header
  *ngIf="isOnboarding | async"
  [currentStep]="4"
></app-supplier-onboard-header>

<form
  [formGroup]="formGroup"
  (ngSubmit)="onSubmit()"
  (keydown.enter)="$event.preventDefault()"
>
  <div class="bg-transparent pb-4 rounded-2xl">
    <app-trusty-contact-information
      [formGroup]="formGroup"
      [selectedRole]="(selectedRole | async) ?? 'bedrijf'"
    ></app-trusty-contact-information>
    <app-payment-information></app-payment-information>
    <div id="buttons" class="mt-8 flex justify-between">
      <button
        type="button"
        (click)="togglePreview()"
        class="primary-color-button"
      >
        Trusty Voorbeeld
      </button>
      <button
        [disabled]="
          !formGroup.valid || (!paymentInformationComponent?.isValid() ?? false)
        "
        type="submit"
        class="px-4 py-2 bg-fuchsia-400 text-white rounded-md hover:bg-fuchsia-500 transition-colors shadow-lg disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:bg-fuchsia-400"
      >
        Verstuur Trusty
      </button>
    </div>
  </div>
</form>
