import { CommonModule } from '@angular/common'
import { Component, OnDestroy, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { from, Subject, switchMap, take, takeUntil } from 'rxjs'
import { UserProfileResponse } from '../../../../shared/Interfaces/UserProfile'
import { UserProfileState } from '../../../../shared/store/userprofile/userprofile.reducer'
import {
  selectExistsUserProfile,
  selectUserProfile,
  selectUserVerified,
} from '../../../../shared/store/userprofile/userprofile.selectors'
import { TrustyAuthService } from '../../../services/trusty-auth-service'

@Component({
  selector: 'app-split-usp',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './split-usp.component.html',
  styleUrls: ['./split-usp.component.scss'],
})
export class SplitUspComponent implements OnInit, OnDestroy {
  exists: boolean = false
  verified: boolean = false
  userProfile: UserProfileResponse | null = null
  private destroy$ = new Subject<void>()

  constructor(
    private router: Router,
    private authService: TrustyAuthService,
    private store: Store<UserProfileState>,
  ) {}

  ngOnInit(): void {
    this.store
      .select(selectExistsUserProfile)
      .pipe(takeUntil(this.destroy$))
      .subscribe((exists: boolean) => {
        if (exists) {
          this.exists = exists
          this.store
            .select(selectUserProfile)
            .pipe(takeUntil(this.destroy$))
            .subscribe((profile: UserProfileResponse) => {
              this.userProfile = profile
            })
        }
      })

    this.store
      .select(selectUserVerified)
      .pipe(takeUntil(this.destroy$))
      .subscribe((verified: boolean) => {
        this.verified = verified
      })
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  navigateToNextPage(type: string) {
    // First check if user is logged in
    this.authService.isLoggedIn$
      .pipe(
        take(1),
        switchMap(isLoggedIn => {
          if (!isLoggedIn) {
            // Not logged in - go to info page
            if (type === 'toestaan') {
              return from(
                this.router.navigate(['/supplier-onboarding-information'], {
                  queryParams: {
                    redirect: 'trusty-creation',
                    isOnboarding: true,
                  },
                }),
              )
            } else {
              return from(
                this.router.navigate(['/debtor-onboarding-information'], {
                  queryParams: { normalDebtorOnboardFlow: true },
                }),
              )
            }
          }

          // Logged in - check if verified and has profile
          if (!this.verified) {
            return from(
              this.router.navigate(['/authentication-confirmation'], {
                queryParams: {
                  type: type === 'toestaan' ? 'supplier' : 'debtor',
                },
              }),
            )
          }

          if (!this.exists || !this.userProfile) {
            // Has account but no profile - go to onboarding
            if (type === 'toestaan') {
              return from(
                this.router.navigate(['/supplier-onboarding'], {
                  queryParams: {
                    redirect: 'trusty-creation',
                    isOnboarding: true,
                  },
                }),
              )
            } else {
              return from(
                this.router.navigate(['/debtor-onboarding'], {
                  queryParams: { normalDebtorOnboardFlow: true },
                }),
              )
            }
          }

          // Logged in, verified, and has profile - go directly to creation/ask page
          if (type === 'toestaan') {
            if (this.userProfile?.isDebtor) {
              return from(
                this.router.navigate(['/supplier-onboarding'], {
                  queryParams: { isDebtorToSupplier: true },
                }),
              )
            }
            return from(this.router.navigate(['/trusty-creation']))
          } else {
            return from(this.router.navigate(['/trusty-ask-form']))
          }
        }),
      )
      .subscribe()
  }
}
