<div class="max-w-4xl mx-auto px-4">
  <!-- Progress Timeline -->
  <div class="relative mb-12">
    <div class="h-1 bg-gray-200 absolute w-full top-4"></div>
    <div class="relative flex justify-between">
      @for (step of steps; track step.title; let i = $index) {
        <div class="flex flex-col items-center">
          <div
            [class]="
              'w-8 h-8 rounded-full flex items-center justify-center text-white text-sm font-bold ' +
              (i <= currentStep ? 'bg-blue-600' : 'bg-gray-300')
            "
          >
            {{ i + 1 }}
          </div>

          <span class="text-sm mt-2 text-gray-600 text-center max-w-[120px]">{{
            step.title
          }}</span>
        </div>
      }
    </div>
  </div>
</div>
