<div class="unauthorized-container">
  <mat-card class="mb-2">
    <mat-card-content>
      <div class="content-container">
        <h2>Aanmelden vereist</h2>

        <p>Log in om toegang te krijgen tot uw afbetalingsplannen.</p>
        <p>
          Kijk zeker ook na of u ingelogd bent met het correcte account
          (leverancier / debiteur)
        </p>
        <p>
          Blijft u problemen ondervinden na het inloggen? Neem dan contact op
          met onze support.
        </p>

        <div class="flex">
          <button
            class="mr-1"
            mat-raised-button
            color="primary"
            (click)="goToLogin()"
          >
            <mat-icon>login</mat-icon>
            Inloggen
          </button>
          <button
            class="mr-1"
            mat-raised-button
            color="accent"
            (click)="goToSupport()"
          >
            <mat-icon>support</mat-icon>
            Contact support
          </button>
        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-content>
      <div class="flex">
        <mat-icon color="primary">info</mat-icon>
        <span
          >Toegang tot Trust Your Plan is exclusief beschikbaar voor
          geregistreerde gebruikers om uw gegevens veilig te houden.</span
        >
      </div>
    </mat-card-content>
  </mat-card>
</div>
