import { AsyncPipe, NgClass, NgIf } from '@angular/common'
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import {
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms'
import { Store } from '@ngrx/store'
import { BehaviorSubject, filter, Subject, takeUntil } from 'rxjs'
import { DashboardHeaderComponent } from '../../../shared/components/dashboard-header/dashboard-header.component'
import { FormFieldComponent } from '../../../shared/components/form-field/form-field.component'
import { InfoIconComponent } from '../../../shared/components/info-icon/info-icon.component'
import { Role } from '../../../shared/constants/role'
import {
  UserProfileResponse,
  UserProfileUpdateRequest,
} from '../../../shared/Interfaces/UserProfile'
import { updateUserProfile } from '../../../shared/store/userprofile/userprofile.actions'
import { UserProfileState } from '../../../shared/store/userprofile/userprofile.reducer'
import { selectUserProfile } from '../../../shared/store/userprofile/userprofile.selectors'
import { OnboardingContactComponent } from '../onboarding/onboarding-contact/onboarding-contact.component'

@Component({
  selector: 'app-user-profile-settings',
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    AsyncPipe,
    ReactiveFormsModule,
    InfoIconComponent,
    OnboardingContactComponent,
    DashboardHeaderComponent,
    FormFieldComponent,
  ],
  templateUrl: './user-profile-settings.component.html',
  styleUrl: './user-profile-settings.component.css',
})
export class UserProfileSettingsComponent implements OnInit, OnDestroy {
  constructor(
    private fb: UntypedFormBuilder,
    private store: Store<UserProfileState>,
  ) {}

  userProfileForm: UntypedFormGroup = new UntypedFormGroup({})
  userProfile$: BehaviorSubject<UserProfileResponse | undefined> =
    new BehaviorSubject(<UserProfileResponse | undefined>{})
  dataLoaded: boolean = false
  selectedRole: BehaviorSubject<string> = new BehaviorSubject('')
  private destroy$ = new Subject<void>()

  @ViewChild(OnboardingContactComponent)
  onboardingContactComponent!: OnboardingContactComponent

  async ngOnInit() {
    this.initializeUserProfileSubscription()
  }

  private initializeUserProfileSubscription(): void {
    this.store
      .select(selectUserProfile)
      .pipe(
        takeUntil(this.destroy$),
        filter(
          (userProfile): userProfile is UserProfileResponse =>
            userProfile !== null && userProfile !== undefined,
        ),
      )
      .subscribe({
        next: userProfile => {
          try {
            this.updateFormWithProfileData(userProfile)
            this.initForm()
          } catch (error) {
            console.error('Error initializing form:', error)
            // Add error handling (toast, notification, etc.)
          }
        },
        error: error => {
          console.error('Error fetching user profile:', error)
          // Add error handling (toast, notification, etc.)
        },
      })
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  onSubmit() {
    if (
      this.userProfileForm.valid &&
      this.onboardingContactComponent.isValidForm()
    ) {
      this.updateUserProfile()
    } else {
      this.userProfileForm.markAllAsTouched()
      this.onboardingContactComponent.onboardingContactForm.markAllAsTouched()
    }
  }

  private updateFormWithProfileData(userProfile: UserProfileResponse): void {
    // Update form with profile data
    this.userProfileForm.patchValue({
      iban: userProfile.bank?.iban ?? '',
      bankName: userProfile.bank?.bankName ?? '',
      maxTerms: userProfile.paymentPlanParameters?.maxDurationInMonths ?? '',
      minAmount: userProfile.paymentPlanParameters?.minimumAmount ?? '',
      interestRate:
        userProfile.paymentPlanParameters?.defaultInterestRate ?? '',
    })

    // Update state
    this.userProfile$.next(userProfile)
    this.selectedRole.next(Role[userProfile.role])
    this.dataLoaded = true
  }

  private initForm(): void {
    const currentProfile = this.userProfile$.value

    const commonValidators = {
      maxTerms: [Validators.min(1), Validators.max(60)],
      minAmount: [Validators.min(0)],
      interestRate: [Validators.min(0), Validators.max(12.5)],
    }

    if (currentProfile!.isSupplier) {
      this.initSupplierForm(commonValidators)
    } else {
      this.initDefaultForm(commonValidators)
    }
  }

  private initSupplierForm(
    commonValidators: Record<string, Validators[]>,
  ): void {
    const currentProfile = this.userProfile$.value!

    this.userProfileForm = this.fb.group({
      iban: [currentProfile.bank?.iban ?? '', [Validators.required]],
      bankName: [currentProfile.bank?.bankName ?? '', [Validators.required]],
      maxTerms: [
        currentProfile.paymentPlanParameters?.maxDurationInMonths ?? '',
        [Validators.required, ...commonValidators['maxTerms']],
      ],
      minAmount: [
        currentProfile.paymentPlanParameters?.minimumAmount ?? '',
        [Validators.required, ...commonValidators['minAmount']],
      ],
      interestRate: [
        currentProfile.paymentPlanParameters?.defaultInterestRate ?? '',
        [Validators.required, ...commonValidators['interestRate']],
      ],
    })
  }

  private initDefaultForm(
    commonValidators: Record<string, Validators[]>,
  ): void {
    this.userProfileForm = this.fb.group({
      iban: [''],
      bankName: [''],
      maxTerms: ['', commonValidators['maxTerms']],
      minAmount: ['', commonValidators['minAmount']],
      interestRate: ['', commonValidators['interestRate']],
    })
  }

  private async updateUserProfile(): Promise<void> {
    try {
      this.dataLoaded = false
      const { value: contactForm } =
        this.onboardingContactComponent.onboardingContactForm
      const currentProfile = this.userProfile$.value

      if (!currentProfile) {
        throw new Error('No user profile data available')
      }

      const baseContactPerson = {
        isMain: true,
        name: contactForm.contactName,
        email: contactForm.contactEmail,
        phone: contactForm.contactPhone,
      }

      const contactPersons = [baseContactPerson]

      // Add extra contact if provided
      if (contactForm.extraContactName?.trim()) {
        contactPersons.push({
          isMain: false,
          name: contactForm.extraContactName,
          email: contactForm.extraContactEmail,
          phone: contactForm.extraContactPhone,
        })
      }

      const userProfileUpdateRequest: UserProfileUpdateRequest = {
        isDebtor: currentProfile.isDebtor ?? true,
        isSupplier: currentProfile.isSupplier ?? true,
        contactPersons,

        // Company details for business users
        company:
          currentProfile.role === Role.particulier
            ? null
            : {
                name: contactForm.companyName,
                address: contactForm.address,
                postalCode: contactForm.postalCode,
                city: contactForm.city,
                country: contactForm.country,
              },

        // Bank details for non-debtors
        bank: currentProfile.isSupplier
          ? {
              bankName: this.userProfileForm.value.bankName,
              iban: this.userProfileForm.value.iban,
            }
          : null,

        // Payment parameters for non-debtors
        paymentPlanParameters: currentProfile.isSupplier
          ? {
              maxDurationInMonths: this.userProfileForm.value.maxTerms,
              minimumAmount: this.userProfileForm.value.minAmount,
              defaultInterestRate: this.userProfileForm.value.interestRate,
            }
          : null,

        // Address for individual users
        address:
          currentProfile.role === Role.bedrijf
            ? null
            : {
                address: contactForm.contactAddress,
                postalCode: contactForm.contactPostalCode,
                city: contactForm.contactCity,
                country: contactForm.contactCountry,
              },
      }

      this.store.dispatch(updateUserProfile(userProfileUpdateRequest))
    } catch (error) {
      console.error('Error updating user profile:', error)
      // Here you might want to add error handling logic, such as showing a toast/notification
    } finally {
      this.dataLoaded = true
    }
  }
}
