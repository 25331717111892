<!-- Bedrijfsinformatie -->
<form [formGroup]="onboardingContactForm">
  <div class="mb-8 mt-4 p-4 bg-gray-50 rounded-2xl border border-t-4">
    <h2 class="text-2xl font-semibold mb-4 text-[#333333]">{{ headerText }}</h2>

    <app-role-selection
      *ngIf="shouldShowRoleSelection"
      [selectedRole]="(selectedRole | async) ?? 'bedrijf'"
      (roleSelected)="onRoleSelected($event)"
    ></app-role-selection>

    <div *ngIf="(selectedRole | async) === 'bedrijf'" class="col-span-2">
      <h2 class="text-2xl font-semibold mb-4 text-[#333333]">
        Bedrijfsinformatie
      </h2>
      <div class="grid grid-cols-2 gap-4">
        <app-form-field
          label="Bedrijfsnaam"
          [control]="onboardingContactForm.get('companyName')"
          fieldId="companyName"
          errorMessage="Bedrijfsnaam is verplicht"
        ></app-form-field>

        <app-form-field
          label="BTW-nummer"
          [control]="onboardingContactForm.get('vatNumber')"
          fieldId="vatNumber"
          errorMessage="BTW-nummer is verplicht"
        ></app-form-field>
      </div>

      <div class="grid grid-cols-2 gap-4 mt-4">
        <app-form-field
          label="Adres"
          [control]="onboardingContactForm.get('address')"
          fieldId="address"
          errorMessage="Adres is verplicht"
        ></app-form-field>

        <app-form-field
          label="Postcode"
          [control]="onboardingContactForm.get('postalCode')"
          fieldId="postalCode"
          errorMessage="Postcode is verplicht"
        ></app-form-field>
      </div>

      <div class="grid grid-cols-2 gap-4 mt-4">
        <app-form-field
          label="Plaats"
          [control]="onboardingContactForm.get('city')"
          fieldId="city"
          errorMessage="Plaats is verplicht"
        ></app-form-field>

        <app-form-field
          label="Land"
          [control]="onboardingContactForm.get('country')"
          fieldId="country"
          type="select"
          [options]="countryOptions"
          errorMessage="Land is verplicht"
        ></app-form-field>
      </div>
    </div>

    <!-- Contactpersoon -->
    <div *ngIf="(selectedRole | async) !== undefined" class="col-span-2">
      <h2 class="text-2xl font-semibold mt-4 mb-4 text-[#333333]">
        Contactpersoon
      </h2>
      <!-- Column 1: Contact Details -->
      <div class="grid grid-cols-2 gap-4 mt-4">
        <app-form-field
          label="Naam"
          [control]="onboardingContactForm.get('contactName')"
          fieldId="contactName"
          errorMessage="Naam is verplicht"
        ></app-form-field>
        <app-form-field
          *ngIf="(selectedRole | async) !== 'bedrijf'"
          label="Adres"
          [control]="onboardingContactForm.get('contactAddress')"
          fieldId="contactAddress"
          errorMessage="Adres is verplicht"
        ></app-form-field>
      </div>

      <div class="grid grid-cols-2 gap-4 mt-4">
        <app-form-field
          label="E-mail"
          [control]="onboardingContactForm.get('contactEmail')"
          fieldId="contactEmail"
          errorMessage="Geldig e-mailadres is verplicht"
          type="email"
        ></app-form-field>
        <app-form-field
          *ngIf="
            ['particulier', 'eenmanszaak'].includes(
              (selectedRole | async) || ''
            )
          "
          label="Postcode"
          [control]="onboardingContactForm.get('contactPostalCode')"
          fieldId="contactPostalCode"
          errorMessage="Postcode is verplicht"
        ></app-form-field>
      </div>

      <div class="grid grid-cols-2 gap-4 mt-4">
        <app-form-field
          label="Mobiel nummer"
          [control]="onboardingContactForm.get('contactPhone')"
          fieldId="contactPhone"
          errorMessage="Mobielnummer is verplicht"
          type="tel"
        ></app-form-field>
        <app-form-field
          *ngIf="
            ['particulier', 'eenmanszaak'].includes(
              (selectedRole | async) || ''
            )
          "
          label="Plaats"
          [control]="onboardingContactForm.get('contactCity')"
          fieldId="contactCity"
          errorMessage="Plaats is verplicht"
        ></app-form-field>
      </div>

      <div class="grid grid-cols-2 gap-4 mt-4">
        <!-- VAT number for eenmanszaak -->
        <app-form-field
          *ngIf="(selectedRole | async) === 'eenmanszaak'"
          label="BTW-nummer"
          [control]="onboardingContactForm.get('vatNumber')"
          fieldId="vatNumber2"
          errorMessage="BTW-nummer is verplicht"
        ></app-form-field>
        <app-form-field
          *ngIf="
            ['particulier', 'eenmanszaak'].includes(
              (selectedRole | async) || ''
            )
          "
          label="Land"
          [control]="onboardingContactForm.get('contactCountry')"
          fieldId="contactCountry"
          type="select"
          [options]="countryOptions"
          errorMessage="Land is verplicht"
        ></app-form-field>
      </div>
      <button
        (click)="toggleExtraContactPerson()"
        class="px-4 py-2 bg-indigo-400 text-white rounded-md hover:bg-indigo-500 transition-colors shadow-md mt-4"
      >
        Extra contactpersoon ingeven
      </button>
    </div>
    <div *ngIf="showExtraContactPerson" class="grid grid-cols-1 gap-4 mt-4">
      <div class="bg-white p-6 rounded-2xl shadow border-l-4">
        <!-- Increased padding -->
        <h2 class="text-2xl font-semibold mb-4 text-[#333333]">
          Extra contactpersoon
        </h2>
        <!-- Increased text and margin -->
        <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
          <!-- Changed to 2 columns for wider layout -->
          <div class="col-span-2">
            <label
              for="extraContactName"
              class="block text-sm font-medium text-gray-700"
              >Naam</label
            >
            <input
              type="text"
              id="extraContactName"
              formControlName="extraContactName"
              class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-green-400 focus:border-green-400"
              required
            />
          </div>
          <div>
            <label
              for="extraContactEmail"
              class="block text-sm font-medium text-gray-700"
              >Email</label
            >
            <input
              type="email"
              id="extraContactEmail"
              formControlName="extraContactEmail"
              class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-green-400 focus:border-green-400"
              required
            />
          </div>
          <div>
            <label
              for="extraContactPhone"
              class="block text-sm font-medium text-gray-700"
              >Mobiel nummer</label
            >
            <input
              type="tel"
              id="extraContactPhone"
              formControlName="extraContactPhone"
              class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-green-400 focus:border-green-400"
              required
            />
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="(selectedRole | async) !== undefined && showExtraInformationField"
      class="mb-4"
    >
      <label
        for="optionalComment"
        class="block text-2xl font-semibold mb-4 text-[#333333] mt-4"
        >Optionele opmerking</label
      >
      <textarea
        placeholder="Opmerking / Vraag / Factuurreferentie / ..."
        id="optionalComment"
        formControlName="optionalComment"
        class="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
      ></textarea>
    </div>
  </div>
</form>
