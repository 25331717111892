import { Component } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { MatCardModule } from '@angular/material/card'
import { MatIconModule } from '@angular/material/icon'
import { Router } from '@angular/router'
import { TrustyAuthService } from '../../../main/services/trusty-auth-service'

@Component({
  selector: 'app-unauthorized',
  standalone: true,
  imports: [MatIconModule, MatCardModule, MatButtonModule],
  templateUrl: './unauthorized.component.html',
  styleUrl: './unauthorized.component.css',
})
export class UnauthorizedComponent {
  constructor(
    private authService: TrustyAuthService,
    private router: Router,
  ) {}

  goToLogin() {
    this.authService.login('')
  }

  goToSupport() {
    this.router.navigate(['/contact'])
  }
}
