import { CommonModule } from '@angular/common'
import { Component, effect, inject, OnDestroy, OnInit } from '@angular/core'
import { NavigationEnd, Router, RouterOutlet } from '@angular/router'
import { Store } from '@ngrx/store'
import { filter, Subject, switchMap, takeUntil } from 'rxjs'
import { TrustyAuthService } from './modules/main/services/trusty-auth-service'
import { FooterComponent } from './modules/shared/components/footer/footer.component'
import { HeaderComponent } from './modules/shared/components/header/header.component'
import {
  existsUserProfile,
  getUserProfile,
  verifiedUser,
} from './modules/shared/store/userprofile/userprofile.actions'
import { UserProfileState } from './modules/shared/store/userprofile/userprofile.reducer'
import { selectExistsUserProfile } from './modules/shared/store/userprofile/userprofile.selectors'

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, HeaderComponent, FooterComponent],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>()

  constructor(
    private authService: TrustyAuthService,
    private store: Store<UserProfileState>,
  ) {
    effect(() => {
      this.router.events
        .pipe(
          filter(
            (event): event is NavigationEnd => event instanceof NavigationEnd,
          ),
        )
        .subscribe(() => {
          window.scrollTo(0, 0)
        })
    })
  }

  ngOnInit(): void {
    this.authService.isLoggedIn$
      .pipe(
        takeUntil(this.destroy$),
        filter(isLoggedIn => isLoggedIn),
        switchMap(() => {
          this.store.dispatch(existsUserProfile())
          this.store.dispatch(verifiedUser())
          return this.store.select(selectExistsUserProfile)
        }),
        filter(exists => exists),
      )
      .subscribe(() => {
        this.store.dispatch(getUserProfile())
      })
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  title = 'TrustyClient'
  private router = inject(Router)
}
