import { CommonModule, NgForOf } from '@angular/common'
import { Component } from '@angular/core'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'
import { Router } from '@angular/router'

interface WorkflowStep {
  title: string
  description: string
  icon: SafeHtml
  color: string
}

@Component({
  selector: 'app-how-it-works',
  standalone: true,
  imports: [NgForOf, CommonModule],
  templateUrl: './how-it-works.component.html',
})
export class HowItWorksComponent {
  public steps: WorkflowStep[]

  public testimonials = [
    {
      quote:
        'Trust Your Plan heeft onze aanpak van openstaande rekeningen getransformeerd. Onze klanten waarderen de flexibele benadering en we hebben een verbetering van 40% in inningspercentages gezien.',
      author: 'Sarah T., Eigenaar van een Klein Bedrijf',
    },
    {
      quote:
        'Het gebruik van Trust Your Plan voelt als het uitsteken van een helpende hand in plaats van het stellen van eisen. Het heeft zowel onze klantrelaties als onze cashflow verbeterd.',
      author: 'Michael R., Accountmanager',
    },
  ]

  constructor(
    private sanitizer: DomSanitizer,
    private router: Router,
  ) {
    this.steps = [
      {
        title: 'Word Trust Your Plan-gebruiker',
        description:
          'Meld je aan en maak een account. Het is makkelijk en snel!',
        icon: this.sanitizer.bypassSecurityTrustHtml(
          '<svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><line x1="19" y1="8" x2="19" y2="14"></line><line x1="22" y1="11" x2="16" y2="11"></line></svg>',
        ),
        color: 'bg-indigo-300',
      },
      {
        title: 'Vul je profiel in',
        description:
          'Snel en éénmalig invullen voor efficiënt toekomstig gebruik.',
        icon: this.sanitizer.bypassSecurityTrustHtml(
          '<svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><polyline points="16 11 18 13 22 9"></polyline></svg>',
        ),
        color: 'bg-emerald-300',
      },
      {
        title: 'Maak Trusties',
        description:
          'Begin met het maken van afbetalingsplannen. Het is super simpel!',
        icon: this.sanitizer.bypassSecurityTrustHtml(
          '<svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polygon points="13 2 3 14 12 14 11 22 21 10 12 10 13 2"></polygon></svg>',
        ),
        color: 'bg-amber-300',
      },
      {
        title: 'Registreer eenvoudig en snel elke betaling',
        description: 'Simpel, intuïtief en zonder gedoe',
        icon: this.sanitizer.bypassSecurityTrustHtml(
          '<svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="18" y1="20" x2="18" y2="10"></line><line x1="12" y1="20" x2="12" y2="4"></line><line x1="6" y1="20" x2="6" y2="14"></line></svg>',
        ),
        color: 'bg-fuchsia-300',
      },
    ]
  }

  navigateToSpeedtest() {
    this.router.navigate(['/speedtest'])
  }
}
