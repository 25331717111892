<div
  class="max-w-7xl mx-auto pr-6 pl-6 pb-6 bg-transparent rounded-2xl"
  style="margin-bottom: 2rem"
>
  <app-supplier-onboard-header
    *ngIf="!isDebtorToSupplier"
    [currentStep]="3"
  ></app-supplier-onboard-header>

  <h1 class="text-3xl font-bold mb-6 text-[#333333] text-center gradient-text">
    {{ isDebtorToSupplier ? 'Gegevens vervolledigen' : 'Profiel aanmaken' }}
  </h1>
  <form [formGroup]="supplierForm" (ngSubmit)="onSubmit()" class="space-y-6">
    <app-onboarding-contact
      *ngIf="!isDebtorToSupplier"
      [userProfileData]="userProfile$ | async"
      [headerText]="isDebtorToSupplier ? '' : 'Duid uw type profiel aan:'"
      [showExtraInformationField]="false"
      [selectedRole]="selectedRole"
      [shouldShowRoleSelection]="isDebtorToSupplier ? false : true"
    ></app-onboarding-contact>
    <!-- Trusty Limieten, Bankinformatie, Terms and Conditions, and Submit Button -->
    <div class="grid grid-cols-8 gap-6 mt-6">
      <!-- Trusty Limieten -->
      <div class="col-span-2 bg-white p-6 rounded-2xl shadow-md border-t-4">
        <div class="flex items-center">
          <h2 class="text-2xl font-semibold mb-4 text-[#333333]">
            Trusty limieten
          </h2>
          <app-info-icon
            class="ml-4"
            [infoText]="
              'Duid hier aan hoeveel maanden krediet u maximaal wilt toestaan aan uw debiteuren en wat het minimum bedrag is dat ze in ieder geval altijd zouden moeten betalen. Leg hier ook een interestvoet tussen 0% (u hanteert geen interest bij uw afbetalingsplan) en 12,5% (u gebruikt de maximum toegelaten wettelijke interestvoet) vast.'
            "
          ></app-info-icon>
        </div>
        <div class="mb-4">
          <app-form-field
            label="Maximale termijnen (maanden)"
            fieldId="maxTerms"
            type="number"
            [control]="supplierForm.get('maxTerms')"
            [numberConfig]="{ min: 1, max: 60, step: 1 }"
            errorMessage="Maximale termijnen moeten tussen 1 en 60 zijn"
          ></app-form-field>
        </div>
        <div class="mb-4">
          <app-form-field
            label="Minimaal bedrag (€)"
            fieldId="minAmount"
            type="number"
            [control]="supplierForm.get('minAmount')"
            [numberConfig]="{ min: 0, step: 5 }"
            errorMessage="Minimaal bedrag moet 0 of hoger zijn"
          ></app-form-field>
        </div>
        <div class="mb-4">
          <app-form-field
            label="Rentepercentage (%)"
            fieldId="interestRate"
            type="number"
            [control]="supplierForm.get('interestRate')"
            [numberConfig]="{ min: 0, max: 12.5, step: 0.5 }"
            errorMessage="Rentepercentage moet tussen 0 en 12.5 zijn"
          ></app-form-field>
        </div>
      </div>

      <!-- Bankinformatie and Terms and Conditions -->
      <div class="col-span-6 grid grid-cols-2 gap-6">
        <!-- Bankinformatie -->
        <div class="bg-white p-6 rounded-2xl shadow-md border-t-4">
          <h2 class="text-2xl font-semibold mb-4 text-[#333333]">
            Bankinformatie
          </h2>
          <div class="mb-4">
            <app-form-field
              label="IBAN"
              fieldId="iban"
              type="text"
              [control]="supplierForm.get('iban')"
              errorMessage="IBAN is verplicht"
            ></app-form-field>
          </div>
          <div class="mb-4">
            <app-form-field
              label="Banknaam"
              fieldId="bankName"
              type="text"
              [control]="supplierForm.get('bankName')"
              errorMessage="Banknaam is verplicht"
            ></app-form-field>
          </div>
        </div>

        <!-- Terms and Conditions -->
        <div
          class="bg-white p-6 rounded-2xl shadow-md border-t-4 flex flex-col justify-between"
        >
          <h2 class="text-2xl font-semibold mb-4 text-[#333333]">
            Voorwaarden
          </h2>
          <div class="flex items-center mb-4">
            <input
              [checked]="trustyAgreed"
              (change)="handleTrustyAgree($event)"
              type="checkbox"
              id="term3"
              class="mr-2"
            />
            <span class="text-gray-700">Akkoord met het </span>
            <span
              class="text-blue-600 hover:text-blue-800 flex items-center"
              (click)="openTYPEngagement()"
            >
              <i class="lucide-external-link mr-1"> </i>
              Trust Your Plan Engagement
            </span>
          </div>
          <div class="flex items-center mb-4">
            <input
              [checked]="termsAgreed"
              (change)="handleTermsAgree($event)"
              type="checkbox"
              id="term1"
              class="mr-2"
            />
            <span class="text-gray-700">Akkoord met de </span>
            <span
              class="text-blue-600 hover:text-blue-800 flex items-center"
              (click)="openTermsAndConditions()"
            >
              <i class="lucide-external-link mr-1"></i>
              Algemene Voorwaarden
            </span>
          </div>
          <button
            type="submit"
            [disabled]="
              (!isDebtorToSupplier &&
                (!supplierForm.valid ||
                  (!onboardingContactComponent?.isValidForm() ?? false))) ||
              (isDebtorToSupplier && !supplierForm.valid) ||
              !termsAgreed ||
              !trustyAgreed
            "
            class="w-full primary-color-button mt-4 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Registreren
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
