import { Component, OnDestroy, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'
import {
  BarController,
  BarElement,
  CategoryScale,
  Chart,
  Legend,
  LinearScale,
  Tooltip,
} from 'chart.js/auto'
import annotationPlugin from 'chartjs-plugin-annotation'
import { BaseChartDirective } from 'ng2-charts'
import { filter, Subject, takeUntil } from 'rxjs'
import { DashboardHeaderComponent } from '../../../../shared/components/dashboard-header/dashboard-header.component'
import {
  getDefaultChartConfig,
  ReportChartConfig,
} from '../../../../shared/Interfaces/internal/ChartConfiguration'
import { TrustyResponse } from '../../../../shared/Interfaces/Trusty'
import { getTrusties } from '../../../../shared/store/trusty/trusty.actions'
import { TrustyState } from '../../../../shared/store/trusty/trusty.reducer'
import {
  selectTrusties,
  selectTrustyLoaded,
} from '../../../../shared/store/trusty/trusty.selectors'
import { toUTCDate } from '../../../services/data.service'

// Register the necessary components
Chart.register(
  CategoryScale,
  LinearScale,
  BarController,
  BarElement,
  Tooltip,
  Legend,
  annotationPlugin,
)

@Component({
  selector: 'app-sign-up',
  standalone: true,
  imports: [BaseChartDirective, DashboardHeaderComponent],
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css'],
})
export class ReportComponent implements OnInit, OnDestroy {
  constructor(private store: Store<TrustyState>) {}

  private destroy$ = new Subject<void>()
  private trusties: TrustyResponse[] = []

  private chartConfig: ReportChartConfig = getDefaultChartConfig()

  public get barChartOptions() {
    return this.chartConfig.options
  }

  public get barChartType() {
    return this.chartConfig.type
  }

  public get barChartData() {
    return this.chartConfig.data
  }

  ngOnInit() {
    this.store
      .select(selectTrustyLoaded)
      .pipe(takeUntil(this.destroy$))
      .subscribe(loaded => {
        if (!loaded) {
          this.store.dispatch(getTrusties())
        }
      })

    this.store
      .select(selectTrusties)
      .pipe(
        takeUntil(this.destroy$),
        filter(
          (trusties: TrustyResponse[]) =>
            trusties.length !== 0 || trusties !== null,
        ),
      )
      .subscribe((trusties: TrustyResponse[]) => {
        this.trusties = trusties
        this.generateChartData()
      })
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  generateChartData() {
    // Helper function to get month key
    const getMonthKey = (date: Date | string) => {
      const d = new Date(date)
      return d.toLocaleString('default', { month: 'short', year: 'numeric' })
    }

    // Get date range
    const currentDate = toUTCDate(new Date())
    const startDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() - 6,
      1,
    )
    const endDate = this.trusties.reduce((maxDate, trusty) => {
      const planEndDate = new Date(trusty.startDate)
      planEndDate.setMonth(planEndDate.getMonth() + trusty.amountOfMonths)
      return planEndDate > maxDate ? planEndDate : maxDate
    }, new Date(currentDate))

    // Generate all months between start and end
    const monthsData = new Map<string, { total: number; paid: number }>()
    for (
      let date = new Date(startDate);
      date <= endDate;
      date.setMonth(date.getMonth() + 1)
    ) {
      monthsData.set(getMonthKey(date), { total: 0, paid: 0 })
    }

    // Populate data
    this.trusties.forEach(trusty => {
      // Add theoretical payments
      trusty.theoreticalPayments.forEach(payment => {
        const key = getMonthKey(payment.dueDate)
        const month = monthsData.get(key)
        if (month) {
          month.total += payment.totalAmount
        }
      })

      // Add actual payments
      trusty.actualPayments.forEach(payment => {
        const key = getMonthKey(payment.paymentDate)
        const month = monthsData.get(key)
        if (month) {
          month.paid += payment.amountPaid
        }
      })
    })

    // Convert to chart data
    const chartData = Array.from(monthsData.entries())
    this.barChartData.labels = chartData.map(([label]) => label)
    this.barChartData.datasets[0].data = chartData.map(([, data]) => data.paid)
    this.barChartData.datasets[1].data = chartData.map(
      ([, data]) => data.total - data.paid,
    )

    // Set current month line
    const currentMonthIndex = this.barChartData.labels.indexOf(
      getMonthKey(currentDate),
    )
    if (this.barChartOptions && currentMonthIndex >= 0) {
      ;(
        this.barChartOptions.plugins!.annotation!.annotations! as any
      ).currentMonthLine = {
        type: 'line',
        xMin: currentMonthIndex - 0.5,
        xMax: currentMonthIndex - 0.5,
        borderColor: 'black',
        borderWidth: 2,
      }
    }
  }
}
