export interface FilterConfig {
  debtor: string
  amount: string
  date: string
  amountMin: string
  amountMax: string
  dateMin: string
  dateMax: string
}

export function getDefaultFilterConfig(): FilterConfig {
  return {
    debtor: '',
    amount: '',
    date: '',
    amountMin: '',
    amountMax: '',
    dateMin: '',
    dateMax: '',
  }
}
