<div
  class="payment-schedule overflow-x-auto bg-gray-200 rounded-2xl shadow-md mb-4"
>
  <h3 class="text-lg text-center font-semibold p-2 bg-indigo-200">
    {{ title }}
  </h3>
  <table class="min-w-full">
    <thead class="bg-gray-100">
      <tr>
        <th class="py-2 px-4 border-b">
          {{ isExecuted ? 'Referentie' : 'Nummer' }}
        </th>
        <th class="py-2 px-4 border-b">Datum</th>
        <th class="py-2 px-4 border-b">Betaling</th>
        <th class="py-2 px-4 border-b">Kapitaal</th>
        <th class="py-2 px-4 border-b">Interest</th>
        <th class="py-2 px-4 border-b">Balans</th>
      </tr>
    </thead>

    <tbody>
      <tr
        *ngFor="let row of payments; let i = index"
        [class.bg-gray-50]="i % 2 === 0"
      >
        <td
          class="py-2 px-4 border-b text-center text-indigo-500 font-semibold"
        >
          {{ row.reference }}
        </td>
        <td
          class="py-2 px-4 border-b text-center text-indigo-500 font-semibold"
        >
          {{ formatDateEU(row.date) }}
        </td>
        <td
          class="py-2 px-4 border-b text-center text-indigo-500 font-semibold"
        >
          {{ formatCurrency(row.payment) }}
        </td>
        <td
          class="py-2 px-4 border-b text-center text-indigo-500 font-semibold"
        >
          {{ formatCurrency(row.capital) }}
        </td>
        <td
          class="py-2 px-4 border-b text-center text-indigo-500 font-semibold"
        >
          {{ formatCurrency(row.interest) }}
        </td>
        <td
          class="py-2 px-4 border-b text-center text-indigo-500 font-semibold"
        >
          {{ formatCurrency(row.balance) }}
        </td>
      </tr>
    </tbody>
  </table>
</div>
