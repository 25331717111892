<app-supplier-onboard-header
  *ngIf="isSupplier && hasRole"
  [currentStep]="2"
></app-supplier-onboard-header>

<app-debtor-onboard-header
  *ngIf="!isSupplier && hasRole"
  [currentStep]="2"
></app-debtor-onboard-header>

<div class="max-w-2xl mx-auto p-6 mt-12">
  <div class="bg-white rounded-2xl shadow-md p-8 text-center">
    <h1 class="text-3xl font-bold mb-6 text-[#333333] gradient-text">
      Bevestig uw e-mailadres
    </h1>

    <div class="space-y-4 text-gray-700">
      <p class="text-lg">
        We hebben een bevestigingsmail gestuurd naar uw e-mailadres.
      </p>

      <p>
        Klik op de link in de e-mail om uw account te verifiëren en verder te
        gaan met de registratie.
      </p>

      <div class="bg-blue-50 p-4 rounded-lg mt-6">
        <p class="text-sm">
          <strong>Tip:</strong> Controleer ook uw spam/ongewenste e-mail map als
          u de mail niet kunt vinden.
        </p>
      </div>

      <div class="mt-8">
        <button
          mat-raised-button
          color="primary"
          (click)="continueOnboarding()"
          class="w-full py-3"
        >
          Doorgaan met registratie na e-mailverificatie
        </button>
      </div>
    </div>
  </div>
</div>
