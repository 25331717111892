import { NgClass, NgForOf, NgIf } from '@angular/common'
import { Component, OnDestroy, OnInit } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { MatIconModule } from '@angular/material/icon'
import { Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { Subject, takeUntil } from 'rxjs'
import { handleGeneralSort, sortData } from '../../../../../util/sortData'
import { DashboardHeaderComponent } from '../../../../shared/components/dashboard-header/dashboard-header.component'
import {
  FilterConfig,
  getDefaultFilterConfig,
} from '../../../../shared/Interfaces/FilterConfig'
import {
  SortConfig,
  getDefaultSortConfig,
} from '../../../../shared/Interfaces/SortConfig'
import {
  TheoreticalPayment,
  TrustyResponse,
} from '../../../../shared/Interfaces/Trusty'
import { getDebtorTrusties } from '../../../../shared/store/trusty/trusty.actions'
import { TrustyState } from '../../../../shared/store/trusty/trusty.reducer'
import {
  selectDebtorTrusties,
  selectTrustyLoaded,
} from '../../../../shared/store/trusty/trusty.selectors'
import { toUTCDate } from '../../../services/data.service'
import { TrustyPreviewService } from '../../../services/trusty-preview-service'

interface TrustyWithNextTheoreticalPayment {
  trusty: TrustyResponse
  nextPayment: TheoreticalPayment
  state: string
}
@Component({
  selector: 'app-debtor-dashboard',
  standalone: true,
  imports: [
    FormsModule,
    NgClass,
    NgIf,
    NgForOf,
    MatIconModule,
    DashboardHeaderComponent,
  ],
  templateUrl: './debtor-dashboard.component.html',
  styleUrl: './debtor-dashboard.component.css',
})
export class DebtorDashboardComponent implements OnInit, OnDestroy {
  constructor(
    public router: Router,
    private store: Store<TrustyState>,
    private trustyPreviewService: TrustyPreviewService,
  ) {}
  showInfo: boolean = false
  trustiesWithPayment: TrustyWithNextTheoreticalPayment[] = []
  private destroy$ = new Subject<void>()

  sortConfig: SortConfig = getDefaultSortConfig()
  filters: FilterConfig = getDefaultFilterConfig()

  tableHeaders = [
    { key: 'debtor', label: 'Schuldeiser' },
    { key: 'amount', label: 'Saldo' },
    { key: 'nextPaymentAmount', label: 'TermijnBedrag' },
    { key: 'nextPaymenyDate', label: 'Volgende betaling' },
    { key: 'status', label: 'Status' },
    { key: 'action', label: 'Acties' },
  ]

  ngOnInit(): void {
    this.store
      .select(selectTrustyLoaded)
      .pipe(takeUntil(this.destroy$))
      .subscribe(loaded => {
        if (!loaded) {
          this.store.dispatch(getDebtorTrusties())
        }
      })

    this.store
      .select(selectDebtorTrusties)
      .pipe(takeUntil(this.destroy$))
      .subscribe((trusties: TrustyResponse[]) => {
        this.selectDebtorTrustiesWithPayment(trusties)
      })
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  get filteredAndSortedTrusties(): TrustyWithNextTheoreticalPayment[] {
    const filteredTrusties = this.trustiesWithPayment.filter(
      trustyWithNextPayment =>
        trustyWithNextPayment.trusty.debtor.name
          .toLowerCase()
          .includes(this.filters.debtor.toLowerCase()) &&
        trustyWithNextPayment.trusty.totalAmount
          .toString()
          .includes(this.filters.amount) &&
        toUTCDate(trustyWithNextPayment.trusty.startDate)
          .toDateString()
          .includes(this.filters.date),
    )

    return sortData(filteredTrusties, this.sortConfig)
  }

  handleSort(key: string) {
    handleGeneralSort(key, this.sortConfig)
  }

  viewTrusty(trusty: TrustyResponse): void {
    this.setTrustyPreview(trusty)
    this.router.navigate(['trusty-preview'])
  }

  goToSettings() {
    this.router.navigate(['profile'])
  }

  private selectDebtorTrustiesWithPayment(trusties: TrustyResponse[]): void {
    const dateNow = toUTCDate(new Date())

    this.trustiesWithPayment = trusties.map<TrustyWithNextTheoreticalPayment>(
      trusty => ({
        trusty: trusty,
        nextPayment: this.getTrustyNextPayment(trusty),
        state:
          trusty.agreementDate === undefined || trusty.agreementDate === null
            ? 'In Afwachting'
            : Date.parse(trusty.agreementDate) >
                dateNow.setDate(dateNow.getDate() + 7)
              ? 'Kritiek'
              : 'Actief',
      }),
    )
  }

  private getTrustyNextPayment(trusty: TrustyResponse): TheoreticalPayment {
    return trusty.theoreticalPayments
      .filter(
        payment =>
          !payment.isPaid &&
          toUTCDate(payment.dueDate).getTime() >=
            toUTCDate(new Date()).getTime(),
      )
      .sort(
        (payment1, payment2) =>
          toUTCDate(payment1.dueDate).getTime() -
          toUTCDate(payment2.dueDate).getTime(),
      )[0]
  }

  private setTrustyPreview(trusty: TrustyResponse): void {
    this.trustyPreviewService.trustyPreview = {
      supplierName: trusty.supplierName,
      debtor: {
        role: trusty.debtor.role,
        name: trusty.debtor.name,
        email: trusty.debtor.email,
        phone: trusty.debtor.phone,
      },
      totalAmount: trusty.totalAmount,
      interestRate: trusty.interestRate,
      amountOfMonths: trusty.amountOfMonths,
      agreementDate: trusty.agreementDate,
      minAmount: trusty.minAmount,
      startDate: toUTCDate(trusty.startDate!),
      invoices: trusty.invoices,
      actualPayments: trusty.actualPayments,
      theoreticalPayments: trusty.theoreticalPayments,
    }
  }
}
