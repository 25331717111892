<div>
  <label [for]="fieldId" class="block text-sm font-medium text-gray-700 mb-1">
    {{ label }}
  </label>

  <ng-container [ngSwitch]="type">
    <!-- Select Input -->
    <select
      *ngSwitchCase="'select'"
      [id]="fieldId"
      [formControl]="formControl"
      class="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
    >
      <option *ngFor="let option of options" [value]="option.value">
        {{ option.label }}
      </option>
    </select>

    <!-- Number Input -->
    <input
      *ngSwitchCase="'number'"
      type="number"
      [id]="fieldId"
      [formControl]="formControl"
      [min]="numberConfig?.min ?? 0"
      [max]="numberConfig?.max ?? 1000"
      [step]="numberConfig?.step ?? 1"
      class="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
    />

    <!-- Text/Email/Tel Input -->
    <input
      *ngSwitchDefault
      [type]="type"
      [id]="fieldId"
      [formControl]="formControl"
      [readOnly]="readOnly"
      class="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
    />
  </ng-container>

  <div *ngIf="isInvalid" class="text-red-500 text-sm mt-1">
    {{ errorMessage }}
  </div>
</div>
