import { CommonModule, DOCUMENT, NgClass } from '@angular/common'
import { Component, Inject, OnDestroy, OnInit } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { Router, RouterLink } from '@angular/router'
import { Store } from '@ngrx/store'
import { Subject, takeUntil } from 'rxjs'
import { TrustyAuthService } from '../../../main/services/trusty-auth-service'
import { UserProfileResponse } from '../../Interfaces/UserProfile'
import { UserProfileState } from '../../store/userprofile/userprofile.reducer'
import { selectUserProfile } from '../../store/userprofile/userprofile.selectors'

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [NgClass, RouterLink, CommonModule, MatIconModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.css',
})
export class HeaderComponent implements OnInit, OnDestroy {
  constructor(
    private router: Router,
    private store: Store<UserProfileState>,
    public authService: TrustyAuthService,
    @Inject(DOCUMENT) public document: Document,
  ) {}

  isLandingPage: boolean = false
  isExplanationPage: boolean = false
  userProfile?: UserProfileResponse = undefined
  private onDestroy$ = new Subject<void>()

  ngOnInit() {
    this.store
      .select(selectUserProfile)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((userProfile: UserProfileResponse) => {
        if (userProfile) {
          this.userProfile = userProfile
        }
      })

    this.router.events.subscribe(() => {
      this.isLandingPage = this.router.url === '/'
      this.isExplanationPage =
        this.router.url === '/speedtest' || this.router.url === '/trusty-test'
    })
  }
  ngOnDestroy() {
    this.onDestroy$.next()
    this.onDestroy$.complete()
  }

  navigateToLogin() {
    localStorage.setItem('onboarding_role', 'NONE')
    this.authService.login()
  }

  logout() {
    this.authService.logout(document.location.origin)
  }

  navigateToDashboard(dashboardType: string) {
    this.router.navigate([`${dashboardType}-dashboard`])
  }

  navigateToLandingPage() {
    this.router.navigate(['/'])
  }
}
