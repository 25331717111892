<div class="max-w-2xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
  <h2 class="text-3xl font-bold text-center mb-8">Contacteer ons</h2>

  <form [formGroup]="contactForm" (ngSubmit)="onSubmit()" class="space-y-6">
    <div>
      <app-form-field
        label="Naam"
        [control]="contactForm.get('name')"
        fieldId="name"
        errorMessage="Vul je naam in"
      ></app-form-field>
    </div>

    <div>
      <app-form-field
        label="Email"
        [control]="contactForm.get('email')"
        fieldId="email"
        errorMessage="Vul je email in"
      ></app-form-field>
    </div>

    <div>
      <app-form-field
        label="Telefoonnummer"
        [control]="contactForm.get('phone')"
        fieldId="phone"
        errorMessage="Vul je telefoonnummer in"
      ></app-form-field>
    </div>

    <div>
      <label for="message" class="block text-sm font-medium text-gray-700"
        >Bericht</label
      >
      <textarea
        id="message"
        rows="4"
        formControlName="message"
        class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
      ></textarea>
      <div
        *ngIf="
          contactForm.get('message')?.touched &&
          contactForm.get('message')?.invalid
        "
        class="text-red-500 text-sm mt-1"
      >
        Vul een bericht in
      </div>
    </div>

    <div>
      <button
        type="submit"
        [disabled]="contactForm.invalid"
        class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:bg-gray-400"
      >
        Verstuur bericht
      </button>
    </div>
  </form>
</div>
