import { CommonModule } from '@angular/common'
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { Subject, takeUntil } from 'rxjs'
import { DashboardHeaderComponent } from '../../../../shared/components/dashboard-header/dashboard-header.component'
import { PaymentInformationComponent } from '../../../../shared/components/payment-information/payment-information.component'
import { TrustyResponse } from '../../../../shared/Interfaces/Trusty'
import { updateTrusty } from '../../../../shared/store/trusty/trusty.actions'
import { TrustyState } from '../../../../shared/store/trusty/trusty.reducer'
import {
  selectTrusty,
  selectTrustyLoaded,
} from '../../../../shared/store/trusty/trusty.selectors'
import { TrustyPreviewService } from '../../../services/trusty-preview-service'

@Component({
  selector: 'app-speedtest',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    PaymentInformationComponent,
    DashboardHeaderComponent,
  ],
  templateUrl: './trusty-change-form.component.html',
  styleUrls: ['./trusty-change-form.component.css'],
})
export class TrustyChangeFormComponent implements OnInit, OnDestroy {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private trustyPreviewService: TrustyPreviewService,
    private store: Store<TrustyState>,
  ) {}

  @ViewChild(PaymentInformationComponent)
  schuldInformatieComponent!: PaymentInformationComponent

  id: string = ''
  trusty: TrustyResponse = <TrustyResponse>{}
  private destroy$ = new Subject<void>()

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.id = params['id']
    })

    this.store
      .select(selectTrusty(this.id))
      .pipe(takeUntil(this.destroy$))
      .subscribe(trusty => {
        this.trusty = trusty
      })
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  updateTrusty() {
    this.store.dispatch(updateTrusty({ trustyUpdateRequest: {} }))
    this.store
      .select(selectTrustyLoaded)
      .pipe(takeUntil(this.destroy$))
      .subscribe(loaded => {
        if (loaded) {
          this.router.navigate(['management'])
          this.trustyPreviewService.clearTrustyData()
        }
      })
  }

  togglePreview() {
    this.trustyPreviewService.trustyPreview = {
      supplierName: this.trusty.supplierName,
      debtor: {
        role: this.trusty.debtor.role,
        name: this.trusty.debtor.name,
        email: this.trusty.debtor.email,
        phone: this.trusty.debtor.phone,
      },
      totalAmount: this.schuldInformatieComponent.totalAmount,
      interestRate: this.schuldInformatieComponent.interestRate,
      amountOfMonths: this.schuldInformatieComponent.numberOfMonths,
      minAmount: this.schuldInformatieComponent.minAmount,
      startDate: this.schuldInformatieComponent.startDate!,
      invoices: this.trusty.invoices,
      actualPayments: this.trusty.actualPayments,
      theoreticalPayments: this.trusty.theoreticalPayments,
    }

    this.router.navigate(['trusty-preview'])
  }
}
