<div class="bg-transparent pl-4 pr-4 pb-4 rounded-2xl">
  <div class="relative flex items-center justify-center mb-6 pb-4">
    <h2 class="text-3xl font-bold text-[#333333] gradient-text">
      Aanvraagformulier afbetalingsplan
    </h2>
  </div>

  <!-- Belangrijke informatie section -->
  <div class="p-4 bg-gray-100 border-gray-500 text-[#333333] rounded-2xl">
    <ul class="list-disc pl-5">
      <li>
        Dit formulier is een verzoek tot een afbetalingsplan, geen bindende
        overeenkomst.
      </li>
      <li>Na indiening zal de leverancier uw verzoek beoordelen.</li>
      <li>
        Bij akkoord ontvangt u een gedetailleerd voorstel voor een
        afbetalingsplan.
      </li>
      <li>
        U verbindt zich pas na uw goedkeuring van het definitieve voorstel.
      </li>
    </ul>
  </div>

  <!-- Buttons for choosing type -->
  <app-onboarding-contact
    headerText="Opmerking: Je kan je gegevens wijzigen in je instellingen."
    [selectedRole]="selectedRole"
    [userProfileData]="userProfile$ | async"
    [isReadOnlyPage]="true"
    [shouldShowRoleSelection]="false"
    [showExtraInformationField]="false"
  ></app-onboarding-contact>

  <!-- Preview and Submit Buttons -->
  <div id="buttons" class="flex justify-center">
    <button
      (click)="goToSupplier()"
      type="button"
      [disabled]="!selectedRole"
      [class.disabled-button]="!selectedRole"
      class="px-4 py-2 bg-indigo-400 text-white rounded-md hover:bg-indigo-500 transition-colors shadow-md"
    >
      Leveranciersinformatie
    </button>
  </div>
</div>
