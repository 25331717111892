import { CommonModule } from '@angular/common'
import { Component, Input } from '@angular/core'
import { LucideAngularModule } from 'lucide-angular'

interface Step {
  title: string
}

@Component({
  selector: 'app-debtor-onboard-header',
  standalone: true,
  imports: [CommonModule, LucideAngularModule],
  templateUrl: './debtor-onboard-header.component.html',
  styleUrl: './debtor-onboard-header.component.css',
})
export class DebtorOnboardHeaderComponent {
  @Input()
  currentStep: number = 0

  steps: Step[] = [
    {
      title: 'Informatie',
    },
    {
      title: 'Veilige authenticatie',
    },
    {
      title: 'Email verificatie',
    },
    {
      title: 'Profiel vervolledigen',
    },
    {
      title: 'Afbetalingsplan aanpassen en bevestigen',
    },
    {
      title: 'Activatie',
    },
  ]
}
