<div class="min-h-screen bg-gradient-to-b from-blue-50 to-green-50 py-8">
  <div class="max-w-4xl mx-auto px-4">
    <!-- Header -->
    <div class="text-center mb-12">
      <h1 class="text-3xl font-bold text-blue-600 mb-4 gradient-text">
        Goedkeuring van een afbetalingsplan
      </h1>
    </div>

    <app-supplier-onboard-header
      [currentStep]="0"
    ></app-supplier-onboard-header>

    <!-- Steps List -->
    <div class="space-y-8">
      @for (step of steps; track step.title; let i = $index) {
        <div
          class="bg-white rounded-lg shadow-md p-6 border-l-4 border-[#26368b]"
        >
          <div class="flex items-start">
            <div class="flex-shrink-0 mr-4">
              <div
                class="w-10 h-10 rounded-full flex items-center justify-center text-white bg-[#26368b]"
              >
                <i class="material-icons">{{ step.icon }}</i>
              </div>
            </div>

            <div class="flex-grow">
              <h3 class="text-xl font-semibold mb-2">{{ step.title }}</h3>

              <p class="text-gray-600 mb-4">{{ step.description }}</p>
            </div>
          </div>
        </div>
      }
      <div>
        <h1 class="text-l font-bold text-blue-600 mb-4">
          U krijgt toegang tot:
        </h1>

        <ul class="space-y-4">
          <li class="flex items-center gap-3 text-gray-600">
            <mat-icon class="text-blue-600">public</mat-icon>
            24/7 online beheer van alle afbetalingsplannen
          </li>
          <li class="flex items-center gap-3 text-gray-600">
            <mat-icon class="text-blue-600">task_alt</mat-icon>
            Geautomatiseerde contracten en documenten
          </li>
          <li class="flex items-center gap-3 text-gray-600">
            <mat-icon class="text-blue-600">trending_up</mat-icon>
            Uitgebreide rapportage en statistieken
          </li>
        </ul>
      </div>
    </div>

    <!-- Action Button -->

    <div class="mt-8 text-center">
      <button
        (click)="startOnboarding()"
        class="primary-color-button px-8 py-3 rounded-lg flex items-center justify-center mx-auto"
      >
        <span>Aan de slag!</span>
      </button>
    </div>

    <!-- Help Section -->

    <div class="mt-8 bg-blue-50 rounded-lg p-6">
      <div class="flex items-start">
        <i class="material-icons text-blue-600 mr-3 flex-shrink-0">help</i>

        <div>
          <h3 class="text-lg font-semibold text-blue-600 mb-2">Hulp nodig?</h3>

          <p class="text-blue-800">
            Heeft u vragen over het proces? Neem gerust contact op met onze
            <u (click)="navigateToContact()">klantenservice</u> of bekijk onze
            veelgestelde vragen.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
