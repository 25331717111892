import { inject } from '@angular/core'
import { Router } from '@angular/router'
import { filter, map } from 'rxjs'
import { TrustyAuthService } from '../../main/services/trusty-auth-service'

export const authGuard = () => {
  const trustyAuthService = inject(TrustyAuthService)
  const router = inject(Router)

  return trustyAuthService.isLoggedIn$.pipe(
    filter((state): state is boolean => state === true || state === false),
    map(isAuthenticated => {
      if (isAuthenticated) {
        return true
      }

      router.navigate(['/unauthorized'])
      return false
    }),
  )
}
