import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, map, of, switchMap, tap } from 'rxjs'
import { TrustyService } from '../../../main/services/trusty-service'
import { ExtraPayment } from '../../Interfaces/internal/supplier-dashboard'
import {
  TrustyCreateRequest,
  TrustyDebtorInviteSupplierRequest,
  TrustyResponse,
} from '../../Interfaces/Trusty'
import {
  createTrustyFailure,
  createTrustySuccess,
  deleteTrustyFailure,
  deleteTrustySuccess,
  getDebtorTrustiesFailure,
  getDebtorTrustiesSuccess,
  getTrustiesFailure,
  getTrustiesSuccess,
  trustyAssignDebtorFailure,
  trustyAssignDebtorSuccess,
  trustyDebtorInviteSupplierFailure,
  trustyDebtorInviteSupplierSuccess,
  trustyExtraPaymentFailure,
  trustyExtraPaymentSuccess,
  updateTrustyPaymentFailure,
  updateTrustyPaymentSuccess,
} from './trusty.actions'

@Injectable()
export class TrustyEffects {
  constructor(
    private actions$: Actions,
    private trustyService: TrustyService,
  ) {}

  getTrusties$ = createEffect(() =>
    this.actions$.pipe(
      ofType('[Trusty] get all'),
      switchMap(() =>
        this.trustyService.getTrusties().pipe(
          map(trusties =>
            getTrustiesSuccess({
              trustiesResponse: trusties,
            }),
          ),
          catchError(error => {
            console.log(error)
            return of(getTrustiesFailure({ error }))
          }),
        ),
      ),
    ),
  )

  getDebtorTrusties$ = createEffect(() =>
    this.actions$.pipe(
      ofType('[Trusty] debtor get all'),
      switchMap(() =>
        this.trustyService.getDebtorTrusties().pipe(
          map(trusties =>
            getDebtorTrustiesSuccess({
              trustiesResponse: trusties,
            }),
          ),
          catchError(error => {
            console.log(error)
            return of(getDebtorTrustiesFailure({ error }))
          }),
        ),
      ),
    ),
  )

  createTrusty$ = createEffect(() =>
    this.actions$.pipe(
      ofType('[Trusty] create'),
      switchMap((trustyCreateRequest: TrustyCreateRequest) =>
        this.trustyService.createTrusty(trustyCreateRequest).pipe(
          map(trustyResponse => createTrustySuccess(trustyResponse)),
          catchError(error => {
            console.log(error)
            return of(createTrustyFailure({ error }))
          }),
        ),
      ),
    ),
  )

  updateTrustyActualPayment$ = createEffect(() =>
    this.actions$.pipe(
      ofType('[Trusty] update payment'),
      switchMap(({ trustyId, trustyUpdatePaymentRequest }) =>
        this.trustyService
          .updateActualPayment(trustyId, trustyUpdatePaymentRequest)
          .pipe(
            map(trustyResponse => updateTrustyPaymentSuccess(trustyResponse)),
            catchError(error => {
              console.log(error)
              return of(updateTrustyPaymentFailure({ error }))
            }),
          ),
      ),
    ),
  )

  deleteTrusty$ = createEffect(() =>
    this.actions$.pipe(
      ofType('[Trusty] delete'),
      switchMap((trusty: any) =>
        this.trustyService.deleteTrusty(trusty.trustyId).pipe(
          map(() =>
            deleteTrustySuccess({
              trustyId: trusty.trustyId,
            }),
          ),
          catchError(error => of(deleteTrustyFailure({ error }))),
        ),
      ),
    ),
  )

  trustyAssignDebtor$ = createEffect(() =>
    this.actions$.pipe(
      ofType('[Trusty] debtor assign'),
      switchMap(({ trustyId }) =>
        this.trustyService.assignTrustyDebtor(trustyId).pipe(
          map(() => trustyAssignDebtorSuccess()),
          catchError(error => of(trustyAssignDebtorFailure({ error }))),
        ),
      ),
    ),
  )

  trustyDebtorInviteSupplier$ = createEffect(() =>
    this.actions$.pipe(
      ofType('[Trusty] debtor invite supplier'),
      switchMap(
        (
          trustyDebtorInviteSupplierRequest: TrustyDebtorInviteSupplierRequest,
        ) =>
          this.trustyService
            .trustyDebtorInviteSupplier(trustyDebtorInviteSupplierRequest)
            .pipe(
              tap(),
              map(() => trustyDebtorInviteSupplierSuccess()),
              catchError(error =>
                of(trustyDebtorInviteSupplierFailure({ error })),
              ),
            ),
      ),
    ),
  )

  trustyExtraPayment$ = createEffect(() =>
    this.actions$.pipe(
      ofType('[Trusty] extra payment'),
      switchMap((extraPayment: ExtraPayment) =>
        this.trustyService.trustyAddExtraPayment(extraPayment).pipe(
          map((trustyResponse: TrustyResponse) =>
            trustyExtraPaymentSuccess(trustyResponse),
          ),
          catchError(error => of(trustyExtraPaymentFailure({ error }))),
        ),
      ),
    ),
  )
}
