import { ChartConfiguration, ChartData, ChartType } from 'chart.js'

export interface ReportChartConfig {
  options: ChartConfiguration['options']
  type: ChartType
  data: ChartData<'bar'>
}

export const getDefaultChartConfig = (): ReportChartConfig => ({
  options: {
    responsive: true,
    scales: {
      x: {
        type: 'category',
        title: {
          display: true,
          text: 'Maand en Jaar',
          font: {
            weight: 'bold',
          },
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Bedrag in Euro',
          font: {
            weight: 'bold',
          },
        },
      },
    },
    plugins: {
      tooltip: {
        mode: 'index',
        intersect: false,
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          font: {
            weight: 'bold',
          },
        },
      },
      annotation: {
        annotations: {
          currentMonthLine: {
            type: 'line',
            xMin: 0,
            xMax: 0,
            borderColor: 'black',
            borderWidth: 2,
          },
        },
      },
    },
  },
  type: 'bar',
  data: {
    labels: [],
    datasets: [
      {
        data: [],
        label: 'Betaald Bedrag',
        backgroundColor: '#6ee7b7',
        stack: 'Stack 0',
      },
      {
        data: [],
        label: 'Onbetaald Bedrag',
        backgroundColor: '#a5b4fc',
        stack: 'Stack 0',
      },
    ],
  },
})
