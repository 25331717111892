import { CommonModule } from '@angular/common'
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormGroup,
  Validators,
} from '@angular/forms'
import { MatIconModule } from '@angular/material/icon'
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar'
import { Router } from '@angular/router'
import { Subscription, timer } from 'rxjs'
import { PaymentInformationComponent } from '../../../../shared/components/payment-information/payment-information.component'
import { getRole } from '../../../../shared/constants/role'
import { TrustyPreviewService } from '../../../services/trusty-preview-service'
import { TrustyContactInformationComponent } from '../../trusty/trusty-contact-information/trusty-contact-information.component'

@Component({
  selector: 'app-speedtest',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatSnackBarModule,
    MatIconModule,
    PaymentInformationComponent,
    TrustyContactInformationComponent,
  ],
  templateUrl: './speedtest.component.html',
  styleUrls: ['./speedtest.component.css'],
})
export class SpeedtestComponent implements OnInit, OnDestroy {
  constructor(
    private router: Router,
    private trustyPreviewService: TrustyPreviewService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
  ) {}

  @ViewChild(PaymentInformationComponent)
  paymentInformationComponent!: PaymentInformationComponent
  @ViewChild(TrustyContactInformationComponent)
  trustyContactInformationComponent!: TrustyContactInformationComponent

  speedTestForm: UntypedFormGroup = new UntypedFormGroup({})
  public countdown: number = 0
  focusedField: string | null = null
  private timerSubscription: Subscription | undefined

  ngOnInit() {
    this.speedTestForm = this.fb.group({
      debtorName: ['', Validators.required],
      emailAddress: ['', [Validators.required, Validators.email]],
      mobileNumber: ['', Validators.required],
    })
    this.startTimer()
  }

  ngOnDestroy(): void {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe()
    }
  }

  startTimer() {
    this.timerSubscription = timer(0, 1000).subscribe(() => {
      this.countdown++
    })
  }

  onSubmit() {
    if (!this.validateSubmittedForm()) return

    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe()
    }
    this.trustyPreviewService.trustyPreview = {
      debtor: {
        role: getRole(this.trustyContactInformationComponent.selectedRole!),
        name: this.trustyContactInformationComponent.formGroup.value[
          'debtorName'
        ],
        phone:
          this.trustyContactInformationComponent.formGroup.value[
            'mobileNumber'
          ],
        email:
          this.trustyContactInformationComponent.formGroup.value[
            'emailAddress'
          ],
      },
      totalAmount: this.paymentInformationComponent.totalAmount,
      interestRate: this.paymentInformationComponent.interestRate,
      amountOfMonths: this.paymentInformationComponent.numberOfMonths,
      minAmount: this.paymentInformationComponent.minAmount,
      startDate: this.paymentInformationComponent.startDate!,
      invoices: this.paymentInformationComponent.invoices.filter(
        x => x.amount !== 0 && x.invoiceNumber !== '',
      ),
      actualPayments: [],
      theoreticalPayments: [],
    }

    this.router.navigate(['trusty-test'], {
      queryParams: { duration: this.countdown },
    })
  }

  private validateSubmittedForm(): boolean {
    this.speedTestForm.markAllAsTouched()
    this.trustyContactInformationComponent.formGroup.markAllAsTouched()
    if (!this.speedTestForm.valid) {
      this.snackBar.open('Niet alle velden zijn correct ingevuld.', 'Sluiten', {
        duration: 3000,
      })
      return false
    }

    return true
  }
}
