<app-dashboard-header></app-dashboard-header>
<div class="bg-transparent pb-4 rounded-2xl">
  <div class="bg-white shadow rounded-2xl border-t-4 p-4 mb-4">
    <div class="flex space-x-2 mb-6">
      <input
        type="text"
        name="debtor"
        [(ngModel)]="filters.debtor"
        placeholder="Schuldeiser"
        class="flex-1 p-2 border rounded-md"
      />
      <input
        type="text"
        name="amount"
        [(ngModel)]="filters.amount"
        placeholder="Bedrag"
        class="flex-1 p-2 border rounded-md"
      />
      <input
        type="date"
        name="date"
        [(ngModel)]="filters.date"
        class="flex-1 p-2 border rounded-md"
      />
    </div>

    <div class="overflow-x-auto">
      <!-- Scrollable container with fixed height -->
      <div class="max-h-80 overflow-y-auto relative">
        <table class="min-w-full divide-y divide-gray-200">
          <thead class="bg-gray-50 sticky top-0 z-10">
            <tr>
              <th
                *ngFor="let header of tableHeaders"
                (click)="handleSort(header.key)"
                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider bg-gray-50"
              >
                <div class="flex items-center">
                  {{ header.label }}
                  <mat-icon *ngIf="sortConfig.key === header.key">
                    {{
                      sortConfig.direction === 'ascending'
                        ? 'arrow_upward'
                        : 'arrow_downward'
                    }}
                  </mat-icon>
                </div>
              </th>
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200">
            <tr
              *ngFor="let trusty of filteredAndSortedTrusties"
              [ngClass]="{
                'bg-red-100': trusty.state === 'Kritiek',
                'bg-green-100': trusty.state === 'Actief',
                'bg-amber-100': trusty.state === 'In Afwachting',
              }"
            >
              <td class="px-6 py-4 whitespace-nowrap">
                {{ trusty.trusty.supplierName }}
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                {{ trusty.trusty.remainingAmount }} €
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                {{ trusty.nextPayment.totalAmount }} €
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                {{ trusty.nextPayment.dueDate }}
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                {{ trusty.state }}
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                <div class="flex justify-end space-x-2">
                  <button
                    (click)="viewTrusty(trusty.trusty)"
                    class="bg-emerald-400 text-white hover:bg-emerald-500 px-3 py-1 rounded flex items-center"
                  >
                    Bekijk
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
