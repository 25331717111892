<div [formGroup]="formGroup">
  <div class="mb-8 mt-4 p-4 bg-gray-50 rounded-2xl border border-t-4">
    <h3 class="text-2xl font-semibold mb-4 text-[#333333]">
      Gegevens afbetalingsplan
    </h3>
    <div
      class="flex justify-center space-x-10 mt-6 mr-4"
      style="margin-bottom: 24px"
    >
      <p class="text-l font-medium text-[#333333]">
        Duid hier het type klant aan:
      </p>
    </div>
    <app-role-selection
      [selectedRole]="selectedRole"
      (roleSelected)="selectRole($event)"
    ></app-role-selection>
    <div class="grid grid-cols-2 gap-4 mb-4">
      <div>
        <label
          class="block text-sm font-medium text-[#333333] mb-2"
          for="debtorName"
        >
          Naam
        </label>
        <input
          class="w-full p-2 bg-white border border-[#333333] rounded-md text-[#333333] focus:outline-none focus:ring-2 focus:ring-[#333333] focus:border-transparent"
          id="debtorName"
          type="text"
          formControlName="debtorName"
          placeholder="Albert Impens"
          required
        />
        <div
          *ngIf="
            formGroup.get('debtorName')?.touched &&
            formGroup.get('debtorName')?.invalid
          "
          class="text-red-500 text-sm mt-1"
        >
          Naam is verplicht
        </div>
      </div>
      <div>
        <label
          class="block text-sm font-medium text-[#333333] mb-2"
          for="mobileNumber"
        >
          Mobiel nummer
        </label>
        <input
          class="w-full p-2 bg-white border border-[#333333] rounded-md text-gray-700 focus:outline-none focus:ring-2 focus:ring-[#333333] focus:border-transparent"
          id="mobileNumber"
          type="tel"
          formControlName="mobileNumber"
          placeholder="+32 412 34 56 12"
          required
        />
        <div
          *ngIf="
            formGroup.get('mobileNumber')?.touched &&
            formGroup.get('mobileNumber')?.invalid
          "
          class="text-red-500 text-sm mt-1"
        >
          Mobiel nummer is verplicht
        </div>
      </div>
    </div>

    <div>
      <label
        class="block text-sm font-medium text-[#333333] mb-2"
        for="emailAddress"
      >
        Emailadres
      </label>
      <input
        class="w-full p-2 bg-white border border-[#333333] rounded-md text-gray-700 focus:outline-none focus:ring-2 focus:ring-[#333333] focus:border-transparent"
        id="emailAddress"
        type="email"
        formControlName="emailAddress"
        placeholder="albert.impens@telenet.be"
        required
      />
      <div
        *ngIf="
          formGroup.get('emailAddress')?.touched &&
          formGroup.get('emailAddress')?.invalid
        "
        class="text-red-500 text-sm mt-1"
      >
        Emailadres is verplicht
      </div>
    </div>
  </div>
</div>
