<div class="min-h-screen bg-gradient-to-b from-blue-50 to-green-50 py-8">
  <div class="max-w-4xl mx-auto px-4">
    <!-- Header -->
    <div class="text-center mb-12">
      <h1 class="text-3xl font-bold text-blue-600 mb-4 gradient-text">
        Welkom bij uw Trusty Afbetalingsplan
      </h1>
      <p class="text-gray-600">
        We begeleiden u stap voor stap door het proces.
      </p>
    </div>

    <app-debtor-onboard-header [currentStep]="0"></app-debtor-onboard-header>

    <!-- Steps List -->
    <div class="space-y-8">
      @for (step of steps; track step.title; let i = $index) {
        <div
          [class]="
            'bg-white rounded-lg shadow-md p-6 ' +
            (i === 0 ? 'border-l-4 border-blue-600' : '')
          "
        >
          <div class="flex items-start">
            <div class="flex-shrink-0 mr-4">
              <div
                [class]="
                  'w-10 h-10 rounded-full flex items-center justify-center text-white ' +
                  (i <= 0 ? 'bg-blue-600' : 'bg-gray-300')
                "
              >
                <i class="material-icons">{{ step.icon }}</i>
              </div>
            </div>

            <div class="flex-grow">
              <h3 class="text-xl font-semibold mb-2">{{ step.title }}</h3>

              <p class="text-gray-600 mb-4">{{ step.description }}</p>

              <div class="bg-gray-50 rounded p-4">
                <h4 class="font-semibold mb-2 text-blue-600">
                  Waarom dit belangrijk is:
                </h4>

                <p class="text-gray-600">{{ step.importance }}</p>
              </div>

              @if (step.timeLimit) {
                <div class="mt-4 flex items-center text-orange-600">
                  <i class="material-icons mr-2">hourglass_bottom</i>
                  <span>Actie vereist binnen {{ step.timeLimit }}</span>
                </div>
              }
            </div>
          </div>
        </div>
      }
    </div>

    <!-- Action Button -->

    <div class="mt-8 text-center">
      <button
        (click)="startOnboarding()"
        class="primary-color-button px-8 py-3 rounded-lg flex items-center justify-center mx-auto"
      >
        <span>Aan de slag!</span>
      </button>
    </div>

    <!-- Help Section -->

    <div class="mt-8 bg-blue-50 rounded-lg p-6">
      <div class="flex items-start">
        <i class="material-icons text-blue-600 mr-3 flex-shrink-0">help</i>

        <div>
          <h3 class="text-lg font-semibold text-blue-600 mb-2">Hulp nodig?</h3>

          <p class="text-blue-800">
            Heeft u vragen over het proces? Neem gerust contact op met onze
            klantenservice of bekijk onze veelgestelde vragen.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
