<section class="mb-8">
  <h3 class="text-2xl font-bold mb-6">Veelgestelde vragen</h3>
  <div class="space-y-4">
    <div *ngFor="let faq of faqs" class="bg-white rounded-2xl shadow">
      <button
        (click)="faq.isOpen = !faq.isOpen"
        class="w-full p-6 flex justify-between items-center"
        [attr.aria-expanded]="faq.isOpen"
      >
        <h4 class="font-semibold">{{ faq.question }}</h4>
        <svg
          class="w-5 h-5 transition-transform"
          [class.rotate-180]="faq.isOpen"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </button>
      <div
        class="overflow-hidden transition-all duration-200"
        [class.h-0]="!faq.isOpen"
        [class.p-6]="faq.isOpen"
        [class.pt-0]="faq.isOpen"
      >
        <p class="text-gray-600" [innerHTML]="faq.answer"></p>
      </div>
    </div>
  </div>
</section>
