<!-- Timer Component -->
<div
  class="fixed top-10 right-0 m-4 p-2 bg-gray-800 text-white rounded-2xl shadow-lg z-50"
>
  <p class="text-lg font-bold">Verstreken tijd: {{ countdown }}s</p>
</div>

<div class="bg-transparent pl-4 pr-4 pb-4 rounded-2xl">
  <!-- New Debtor Data section -->
  <form
    [formGroup]="speedTestForm"
    (ngSubmit)="onSubmit()"
    (keydown.enter)="$event.preventDefault()"
  >
    <app-trusty-contact-information
      [formGroup]="speedTestForm"
    ></app-trusty-contact-information>
    <app-payment-information></app-payment-information>

    <!-- Preview and Submit Buttons -->
    <div class="mt-8 flex justify-center">
      <button
        type="submit"
        class="bg-gradient-to-r from-blue-400 to-cyan-300 hover:from-blue-500 hover:to-cyan-400 text-black font-bold py-2 px-4 rounded-full shadow-lg transform transition duration-500 hover:scale-105 flex items-center space-x-2"
      >
        <mat-icon>electric_bolt</mat-icon>
        <span class="text-xl gradient-text">Bereken Trusty</span>
      </button>
    </div>
  </form>
</div>
