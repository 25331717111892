import { createReducer, on } from '@ngrx/store'
import { immerOn } from 'ngrx-immer/store'
import { TrustyResponse } from '../../Interfaces/Trusty'
import {
  createTrusty,
  createTrustyFailure,
  createTrustySuccess,
  deleteTrusty,
  deleteTrustyFailure,
  deleteTrustySuccess,
  getDebtorTrusties,
  getDebtorTrustiesFailure,
  getDebtorTrustiesSuccess,
  getTrusties,
  getTrustiesFailure,
  getTrustiesSuccess,
  trustyAssignDebtor,
  trustyAssignDebtorFailure,
  trustyAssignDebtorSuccess,
  trustyDebtorInviteSupplier,
  trustyDebtorInviteSupplierFailure,
  trustyDebtorInviteSupplierSuccess,
  trustyExtraPayment,
  trustyExtraPaymentFailure,
  trustyExtraPaymentSuccess,
  updateTrusty,
  updateTrustyFailure,
  updateTrustyPayment,
  updateTrustyPaymentFailure,
  updateTrustyPaymentSuccess,
  updateTrustySuccess,
} from './trusty.actions'

export const trustyFeatureKey = 'trusty'

export interface TrustyState {
  supplierTrusties: TrustyResponse[]
  debtorTrusties: TrustyResponse[]
  error: string | null
  loaded: boolean
}

export const initialState: TrustyState = {
  supplierTrusties: [],
  debtorTrusties: [],
  error: null,
  loaded: false,
}

export const trustyReducers = createReducer(
  initialState,

  on(getTrusties, state => ({
    ...state,
    loaded: false,
  })),
  immerOn(getTrustiesSuccess, (state, { trustiesResponse }) => {
    state.supplierTrusties = trustiesResponse
    state.loaded = true
  }),
  immerOn(getTrustiesFailure, (state, { error }) => ({
    ...state,
    loaded: true,
    error,
  })),

  on(getDebtorTrusties, state => ({
    ...state,
    loaded: false,
  })),
  immerOn(getDebtorTrustiesSuccess, (state, { trustiesResponse }) => {
    state.debtorTrusties = trustiesResponse
    state.loaded = true
  }),
  immerOn(getDebtorTrustiesFailure, (state, { error }) => ({
    ...state,
    loaded: true,
    error,
  })),

  on(createTrusty, state => ({
    ...state,
    loaded: false,
  })),
  immerOn(createTrustySuccess, (state, trustyResponse) => {
    state.supplierTrusties.push(trustyResponse)
    state.loaded = true
  }),
  immerOn(createTrustyFailure, (state, { error }) => ({
    ...state,
    loaded: true,
    error,
  })),

  on(updateTrusty, state => ({
    ...state,
    loaded: false,
  })),
  immerOn(updateTrustySuccess, (state, trustyResponse) => {
    const index = state.supplierTrusties.findIndex(
      x => x.id === trustyResponse.id,
    )
    if (index !== -1) {
      state.supplierTrusties[index] = trustyResponse
    }
    state.loaded = true
  }),
  immerOn(updateTrustyFailure, (state, { error }) => ({
    ...state,
    loaded: true,
    error,
  })),

  on(updateTrustyPayment, state => ({
    ...state,
    loaded: false,
  })),
  immerOn(updateTrustyPaymentSuccess, (state, trustyResponse) => {
    const index = state.supplierTrusties.findIndex(
      x => x.id === trustyResponse.id,
    )
    if (index !== -1) {
      state.supplierTrusties[index] = trustyResponse
    }
    state.loaded = true
  }),
  immerOn(updateTrustyPaymentFailure, (state, { error }) => ({
    ...state,
    loaded: true,
    error,
  })),

  on(deleteTrusty, state => ({
    ...state,
  })),
  immerOn(deleteTrustySuccess, (state, action) => {
    const index = state.supplierTrusties.findIndex(
      x => x.id === action.trustyId,
    )

    if (index !== -1) {
      state.supplierTrusties.splice(index, 1)
    }
  }),
  immerOn(deleteTrustyFailure, (_state, { error }) => {
    error
  }),

  on(trustyAssignDebtor, state => ({
    ...state,
    loaded: false,
  })),
  on(trustyAssignDebtorSuccess, state => ({
    ...state,
    loaded: true,
  })),
  immerOn(trustyAssignDebtorFailure, (state, { error }) => ({
    ...state,
    loaded: true,
    error,
  })),

  on(trustyDebtorInviteSupplier, state => ({
    ...state,
    loaded: false,
  })),
  on(trustyDebtorInviteSupplierSuccess, state => ({
    ...state,
    loaded: true,
  })),
  immerOn(trustyDebtorInviteSupplierFailure, (state, { error }) => ({
    ...state,
    loaded: true,
    error,
  })),

  on(trustyExtraPayment, state => ({
    ...state,
    loaded: false,
  })),
  immerOn(trustyExtraPaymentSuccess, (state, trustyResponse) => {
    const index = state.supplierTrusties.findIndex(
      x => x.id === trustyResponse.id,
    )
    if (index !== -1) {
      state.supplierTrusties[index] = trustyResponse
    }
    state.loaded = true
  }),
  immerOn(trustyExtraPaymentFailure, (state, { error }) => ({
    ...state,
    loaded: true,
    error,
  })),
)
