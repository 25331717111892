import { createFeatureSelector, createSelector } from '@ngrx/store'
import { userProfileFeatureKey } from './userprofile.reducer'

const userProfileFeature = createFeatureSelector(userProfileFeatureKey)

export const selectError = createSelector(
  userProfileFeature,
  (userProfile: any) => userProfile?.error,
)

export const selectUserVerified = createSelector(
  userProfileFeature,
  (state: any) => state?.verified,
)

export const selectUserProfile = createSelector(
  userProfileFeature,
  (userProfile: any) => userProfile?.userprofile,
)

export const selectExistsUserProfile = createSelector(
  userProfileFeature,
  (userProfile: any) => userProfile?.exists,
)

export const selectUserProfileLoaded = createSelector(
  userProfileFeature,
  (state: any) => state?.loaded,
)
