import { CommonModule, DatePipe } from '@angular/common'
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormGroup,
  Validators,
} from '@angular/forms'
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar'
import { ActivatedRoute, Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { BehaviorSubject, Subject, take, takeUntil } from 'rxjs'
import { DashboardHeaderComponent } from '../../../../shared/components/dashboard-header/dashboard-header.component'
import { PaymentInformationComponent } from '../../../../shared/components/payment-information/payment-information.component'
import { getRole, Role } from '../../../../shared/constants/role'
import { TrustyPreview } from '../../../../shared/Interfaces/internal/trusty-preview'
import { TrustyResponse } from '../../../../shared/Interfaces/Trusty'
import { createTrusty } from '../../../../shared/store/trusty/trusty.actions'
import { TrustyState } from '../../../../shared/store/trusty/trusty.reducer'
import { selectTrusties } from '../../../../shared/store/trusty/trusty.selectors'
import { toUTCDate } from '../../../services/data.service'
import { TrustyDetailsService } from '../../../services/trusty-detail-service'
import { TrustyPreviewService } from '../../../services/trusty-preview-service'
import { SupplierOnboardHeaderComponent } from '../../onboarding/supplier-onboarding/supplier-onboard-header/supplier-onboard-header.component'
import { TrustyContactInformationComponent } from '../trusty-contact-information/trusty-contact-information.component'

@Component({
  selector: 'app-speedtest',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ReactiveFormsModule,
    FormsModule,
    MatSnackBarModule,
    TrustyContactInformationComponent,
    PaymentInformationComponent,
    DashboardHeaderComponent,
    SupplierOnboardHeaderComponent,
  ],
  templateUrl: './trusty-creation-form.component.html',
  styleUrls: ['./trusty-creation-form.component.css'],
})
export class TrustyCreationFormComponent implements OnInit, OnDestroy {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private trustyStore: Store<TrustyState>,
    private datePipe: DatePipe,
    private snackBar: MatSnackBar,
    private trustyPreviewService: TrustyPreviewService,
    private trustyDetailsService: TrustyDetailsService,
  ) {}

  @ViewChild(PaymentInformationComponent)
  paymentInformationComponent!: PaymentInformationComponent
  @ViewChild(TrustyContactInformationComponent)
  trustyContactInformationComponent!: TrustyContactInformationComponent

  public selectedRole = new BehaviorSubject<string>('bedrijf')
  public isOnboarding = new BehaviorSubject<boolean>(false)

  formGroup: UntypedFormGroup = new UntypedFormGroup({})
  private destroy$ = new Subject<void>()

  ngOnInit() {
    this.formGroup = this.fb.group({
      debtorName: ['', Validators.required],
      emailAddress: ['', [Validators.required, Validators.email]],
      mobileNumber: ['', Validators.required],
    })

    this.route.queryParams.pipe(take(1)).subscribe(params => {
      if (params['email'] || params['phone'] || params['name']) {
        this.formGroup.patchValue({
          emailAddress: params['email'],
          mobileNumber: params['phone'],
          debtorName: params['name'],
        })
      }

      if (params['role']) {
        this.selectedRole.next(Role[params['role']])
      }

      if (params['isOnboarding']) {
        this.isOnboarding.next(true)
      }
    })

    this.trustyPreviewService.currentTrustyPreview$
      .pipe(takeUntil(this.destroy$))
      .subscribe(trusty => {
        if (trusty) {
          this.patchFormValues(trusty)
        }
      })
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
    this.selectedRole.complete()
  }

  onSubmit(): void {
    if (!this.validateSubmittedForm()) {
      return
    }

    let trustyDetail = this.trustyDetailsService.calculatePaymentPlan(
      this.paymentInformationComponent.totalAmount,
      this.paymentInformationComponent.minAmount,
      this.paymentInformationComponent.interestRate,
      this.paymentInformationComponent.numberOfMonths,
      toUTCDate(new Date()),
      this.paymentInformationComponent.startDate!,
    )

    const invoices = this.paymentInformationComponent.invoices.filter(
      x => x.amount !== 0 && x.invoiceNumber !== '',
    )
    const contactInformation =
      this.trustyContactInformationComponent.formGroup.value

    this.trustyStore.dispatch(
      createTrusty({
        debtor: {
          role: getRole(this.trustyContactInformationComponent.selectedRole!),
          name: contactInformation['debtorName'],
          phone: contactInformation['mobileNumber'],
          email: contactInformation['emailAddress'],
        },
        totalAmount: this.paymentInformationComponent.totalAmount,
        minAmount: this.paymentInformationComponent.minAmount,
        amountOfMonths: this.paymentInformationComponent.numberOfMonths,
        interestRate: this.paymentInformationComponent.interestRate,
        invoices: invoices,
        startDate: this.datePipe.transform(
          this.paymentInformationComponent.startDate!,
          'yyyy-MM-dd',
        )!,
        theoreticalPayments: trustyDetail.remainingPayments.map(x => ({
          paymentNumber: x.reference.toString(),
          totalAmount: x.payment,
          principalAmount: x.capital,
          interestAmount: x.interest,
          dueDate: this.datePipe.transform(x.date, 'yyyy-MM-dd')!,
          isPaid: false,
        })),
      }),
    )
    this.trustyStore
      .select(selectTrusties)
      .pipe(takeUntil(this.destroy$))
      .subscribe((trusties: TrustyResponse[]) => {
        let createdTrusty = [...trusties].sort(
          (a, b) =>
            toUTCDate(a.startDate).getTime() - toUTCDate(b.startDate).getTime(),
        )[0]

        if (createdTrusty) {
          this.trustyPreviewService.clearTrustyData()
          this.router.navigate(['trusty-creation-success'], {
            state: createdTrusty,
          })
        }
      })
  }

  togglePreview() {
    const contactInformation =
      this.trustyContactInformationComponent.formGroup.value
    this.trustyPreviewService.trustyPreview = {
      debtor: {
        role: getRole(this.trustyContactInformationComponent.selectedRole!),
        name: contactInformation['debtorName'],
        phone: contactInformation['mobileNumber'],
        email: contactInformation['emailAddress'],
      },
      totalAmount: this.paymentInformationComponent.totalAmount,
      minAmount: this.paymentInformationComponent.minAmount,
      amountOfMonths: this.paymentInformationComponent.numberOfMonths,
      interestRate: this.paymentInformationComponent.interestRate,
      invoices: this.paymentInformationComponent.invoices.filter(
        x => x.amount !== 0 && x.invoiceNumber !== '',
      ),
      startDate: this.paymentInformationComponent.startDate!,
      actualPayments: [],
      theoreticalPayments: [],
    }

    this.router.navigate(['trusty-preview'])
  }

  private validateSubmittedForm(): boolean {
    this.formGroup.markAllAsTouched()
    this.trustyContactInformationComponent.formGroup.markAllAsTouched()
    if (
      !this.formGroup.valid ||
      this.paymentInformationComponent.totalAmount <= 0
    ) {
      this.snackBar.open('Niet alle velden zijn correct ingevuld.', 'Sluiten', {
        duration: 3000,
      })
      return false
    }

    return true
  }

  private patchFormValues(trusty: TrustyPreview | undefined) {
    if (trusty === undefined) return

    if (trusty.debtor?.name !== undefined) {
      this.formGroup.patchValue({
        debtorName: trusty.debtor.name ?? '',
      })
    }
    if (trusty.debtor?.email !== undefined) {
      this.formGroup.patchValue({
        emailAddress: trusty.debtor.email ?? '',
      })
    }
    if (trusty.debtor?.phone !== undefined) {
      this.formGroup.patchValue({
        mobileNumber: trusty.debtor.phone ?? '',
      })
    }
  }
}
