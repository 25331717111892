<div class="bg-transparent p-4">
  <app-debtor-onboard-header [currentStep]="4"></app-debtor-onboard-header>
  <h1 class="text-3xl text-center font-bold mb-4 text-[#333333]">
    Impact van verhoging maandelijkse betaling
  </h1>

  <div class="bg-white rounded-2xl shadow-md border-t-4 p-4 mb-4">
    <h2 class="text-lg font-semibold mb-2 ml-2 flex items-center">
      Informatie
    </h2>
    <p class="text-sm ml-2">
      <strong>Totaal bedrag:</strong> € {{ trusty?.totalAmount?.toFixed(2) }}
    </p>
    <p class="text-sm ml-2">
      <strong>Minimale maandelijkse betaling:</strong> €
      {{ trusty?.minAmount?.toFixed(2) }}
    </p>
    <p class="text-sm ml-2">
      <strong>Datum eerste termijn: </strong>
      {{ trusty !== undefined ? formatDate(trusty!.startDate) : '' }}
    </p>
  </div>

  <div class="bg-white rounded-2xl shadow-md border-t-4 p-4 mb-4 pl-6 pr-6">
    <h2 class="text-lg font-semibold mb-2">Aanpassen</h2>
    <label class="block text-sm font-medium text-gray-700 mb-1">
      Maandelijks termijnbedrag: € {{ installmentAmount.toFixed(2) }}
    </label>
    <input
      type="range"
      [min]="trusty?.minAmount"
      [max]="maxPayment"
      step="5"
      [(ngModel)]="installmentAmount"
      (input)="handleInstallmentChange($event)"
      class="w-full h-2 bg-indigo-200 rounded-lg appearance-none cursor-pointer"
    />
    <div class="flex justify-between text-sm text-[#333333] mt-1">
      <span>€ {{ trusty?.minAmount?.toFixed(2) }}</span>
      <span>€ {{ maxPayment.toFixed(2) }}</span>
    </div>
  </div>

  <div class="bg-white rounded-2xl border-t-4 shadow-md p-4 mb-4">
    <h2 class="text-lg font-semibold mb-2">Aantal maanden: {{ months }}</h2>
    <div class="flex flex-wrap">
      <div
        *ngFor="let month of renderMonthCircles()"
        class="w-8 h-8 rounded-full bg-indigo-400 flex items-center justify-center text-white font-bold text-sm mr-2 mb-2"
      >
        {{ month }}
      </div>
    </div>
    <p *ngIf="months > 24" class="text-sm text-[#333333] mt-2">
      + {{ months - 24 }} meer maanden
    </p>
  </div>
  <div class="flex justify-center space-x-4">
    <button
      type="button"
      (click)="previewTrusty()"
      class="mb-6 w-full bg-indigo-400 hover:bg-indigo-500 text-white p-3 rounded-md flex justify-center items-center"
    >
      Preview afbetalingsplan
      <i class="lucide-arrow-right ml-2" size="18"></i>
    </button>
    <button
      type="button"
      (click)="confirmPaymentPlan()"
      class="mb-6 w-full bg-emerald-400 hover:bg-emerald-500 text-white p-3 rounded-md flex justify-center items-center"
    >
      Bevestig afbetalingsplan
      <i class="lucide-arrow-right ml-2" size="18"></i>
    </button>
  </div>
</div>
