import { AsyncPipe, CommonModule } from '@angular/common'
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { BehaviorSubject, filter, Subject, takeUntil } from 'rxjs'
import { Role } from '../../../../shared/constants/role'
import { UserProfileResponse } from '../../../../shared/Interfaces/UserProfile'
import { UserProfileState } from '../../../../shared/store/userprofile/userprofile.reducer'
import { selectUserProfile } from '../../../../shared/store/userprofile/userprofile.selectors'
import { OnboardingContactComponent } from '../../onboarding/onboarding-contact/onboarding-contact.component'
import { TrustyPreviewComponent } from '../trusty-preview/trusty-preview.component'

@Component({
  selector: 'app-trusty-ask-form',
  standalone: true,
  imports: [
    CommonModule,
    TrustyPreviewComponent,
    OnboardingContactComponent,
    AsyncPipe,
  ],
  templateUrl: './trusty-ask-form.component.html',
  styleUrls: ['./trusty-ask-form.component.css'],
})
export class TrustyAskFormComponent implements OnInit, OnDestroy {
  constructor(
    private router: Router,
    private store: Store<UserProfileState>,
  ) {}

  @ViewChild(OnboardingContactComponent)
  onboardingContactComponent!: OnboardingContactComponent
  selectedRole = new BehaviorSubject('bedrijf')
  userProfile$: BehaviorSubject<UserProfileResponse | undefined> =
    new BehaviorSubject(<UserProfileResponse | undefined>undefined)
  private destroy$ = new Subject<void>()

  ngOnInit() {
    this.store
      .select(selectUserProfile)
      .pipe(
        takeUntil(this.destroy$),
        filter(
          (userProfile: UserProfileResponse) =>
            userProfile !== null && userProfile.role !== Role.unknown,
        ),
      )
      .subscribe((userProfile: UserProfileResponse) => {
        this.selectedRole.next(Role[userProfile.role])
        this.userProfile$.next(userProfile)
      })
  }

  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }

  goToSupplier(): void {
    this.router.navigate(['trusty-ask-form-for'])
  }
}
