import { CommonModule, NgIf } from '@angular/common'
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core'
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormGroup,
  Validators,
} from '@angular/forms'
import { BehaviorSubject } from 'rxjs'
import { UserProfileResponse } from '../../../../shared/Interfaces/UserProfile'
import { FormFieldComponent } from '../../../../shared/components/form-field/form-field.component'
import { RoleSelectionComponent } from '../../../../shared/components/role-selection/role-selection.component'

@Component({
  selector: 'app-onboarding-contact',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgIf,
    FormsModule,
    FormFieldComponent,
    RoleSelectionComponent,
  ],
  templateUrl: './onboarding-contact.component.html',
  styleUrl: './onboarding-contact.component.css',
})
export class OnboardingContactComponent implements OnInit, OnChanges {
  onboardingContactForm: UntypedFormGroup = new UntypedFormGroup({})
  countries = ['Nederland', 'België', 'Duitsland']
  countryOptions = this.countries.map(country => ({
    value: country,
    label: country,
  }))

  public showExtraContactPerson: boolean = false

  private readonly baseRequiredFields = [
    'contactName',
    'contactEmail',
    'contactPhone',
  ] as const
  private readonly roleSpecificFields = {
    particulier: [
      'contactAddress',
      'contactPostalCode',
      'contactCity',
      'contactCountry',
    ],
    eenmanszaak: [
      'vatNumber',
      'contactAddress',
      'contactPostalCode',
      'contactCity',
      'contactCountry',
    ],
    bedrijf: ['companyName', 'address', 'postalCode', 'city', 'country'],
  } as const

  @Input() headerText!: string
  @Input() showExtraInformationField: boolean = true
  @Input() selectedRole!: BehaviorSubject<string>
  @Input() userProfileData?: UserProfileResponse | null
  @Input() shouldShowRoleSelection: boolean = true
  @Input() isReadOnlyPage: boolean = false

  constructor(private fb: FormBuilder) {
    this.onboardingContactForm = this.fb.group({
      companyName: [this.userProfileData?.company?.name, Validators.required],
      vatNumber: [
        this.userProfileData?.company?.btwNummer,
        Validators.required,
      ],
      address: [this.userProfileData?.company?.address, Validators.required],
      postalCode: [
        this.userProfileData?.company?.postalCode,
        Validators.required,
      ],
      city: [this.userProfileData?.company?.city, Validators.required],
      country: [this.userProfileData?.company?.country, Validators.required],
      contactAddress: [
        this.userProfileData?.address?.address,
        Validators.required,
      ],
      contactCity: [this.userProfileData?.address?.city, Validators.required],
      contactPostalCode: [
        this.userProfileData?.address?.postalCode,
        Validators.required,
      ],
      contactCountry: [
        this.userProfileData?.address?.country,
        Validators.required,
      ],
      contactName: ['', Validators.required],
      contactEmail: ['', [Validators.required, Validators.email]],
      contactPhone: ['', Validators.required],
      extraContactName: [''],
      extraContactEmail: ['', [Validators.email]],
      extraContactPhone: [''],
      iban: [this.userProfileData?.bank?.iban, Validators.required],
      bankName: [this.userProfileData?.bank?.bankName, Validators.required],
      maxTerms: [
        this.userProfileData?.paymentPlanParameters?.maxDurationInMonths,
        [Validators.required, Validators.min(1), Validators.max(60)],
      ],
      minAmount: [
        this.userProfileData?.paymentPlanParameters?.minimumAmount,
        [Validators.required, Validators.min(0)],
      ],
      interestRate: [
        this.userProfileData?.paymentPlanParameters?.defaultInterestRate,
        [Validators.required, Validators.min(0), Validators.max(12.5)],
      ],
      optionalComment: [''],
    })
  }

  ngOnInit(): void {
    if (this.isReadOnlyPage) {
      this.hideReadOnlyFields()
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['userProfileData'] && this.userProfileData) {
      const profile = this.userProfileData!
      const mainContact = profile.contactPersons?.find(x => x.isMain)
      const extraContact = profile.contactPersons?.find(x => !x.isMain)

      this.onboardingContactForm.patchValue({
        // Company details
        companyName: profile.company?.name,
        vatNumber: profile.company?.btwNummer,
        address: profile.company?.address,
        city: profile.company?.city,
        postalCode: profile.company?.postalCode,
        country: profile.company?.country,

        // Contact details
        contactName: mainContact?.name,
        contactEmail: mainContact?.email,
        contactPhone: mainContact?.phone,
        contactAddress: profile.address?.address,
        contactCity: profile.address?.city,
        contactPostalCode: profile.address?.postalCode,
        contactCountry: profile.address?.country,

        // Extra contact details
        extraContactName: extraContact?.name,
        extraContactEmail: extraContact?.email,
        extraContactPhone: extraContact?.phone,

        // Bank details
        iban: profile.bank?.iban,
        bankName: profile.bank?.bankName,

        // Payment plan parameters
        maxTerms: profile.paymentPlanParameters?.maxDurationInMonths,
        minAmount: profile.paymentPlanParameters?.minimumAmount,
        interestRate: profile.paymentPlanParameters?.defaultInterestRate,
      })
    }
  }

  public toggleExtraContactPerson(): void {
    this.showExtraContactPerson = !this.showExtraContactPerson
  }

  public onRoleSelected(role: string): void {
    this.showExtraContactPerson = false
    this.selectedRole.next(role)
  }

  public isValidForm(): boolean {
    const requiredFields = [
      ...this.baseRequiredFields,
      ...(this.roleSpecificFields[
        this.selectedRole.value as keyof typeof this.roleSpecificFields
      ] || []),
    ]

    return requiredFields.every(field => {
      const control = this.onboardingContactForm.get(field)
      return control?.valid && control.value
    })
  }

  private hideReadOnlyFields() {
    this.onboardingContactForm.get('companyName')?.disable()
    this.onboardingContactForm.get('vatNumber')?.disable()
    this.onboardingContactForm.get('address')?.disable()
    this.onboardingContactForm.get('city')?.disable()
    this.onboardingContactForm.get('postalCode')?.disable()
    this.onboardingContactForm.get('country')?.disable()
    this.onboardingContactForm.get('contactName')?.disable()
    this.onboardingContactForm.get('contactEmail')?.disable()
    this.onboardingContactForm.get('contactPhone')?.disable()
    this.onboardingContactForm.get('contactAddress')?.disable()
    this.onboardingContactForm.get('contactPostalCode')?.disable()
    this.onboardingContactForm.get('contactCity')?.disable()
    this.onboardingContactForm.get('contactCountry')?.disable()
    this.onboardingContactForm.get('extraContactName')?.disable()
    this.onboardingContactForm.get('extraContactEmail')?.disable()
    this.onboardingContactForm.get('extraContactPhone')?.disable()
  }
}
