<header class="bg-transparent header mb-4">
  <div class="relative h-[150px]">
    <img
      src="https://i.imgur.com/fieyGi4.png"
      class="absolute inset-0 w-full h-full object-cover z-10 mask-bottom"
    />
    <div
      class="flex justify-between items-center absolute inset-x-0 top-1/2 transform -translate-y-1/2 z-20 px-8 lg:px-16"
    >
      <div>
        <button class="primary-color-button mr-4">
          <div class="flex items-center justify-center" title="Demo">
            <i
              class="material-icons text-white"
              style="font-size: 28px; width: 30px"
              >mail</i
            >
            <a href="mailto:jonas.devlieger@alternatiefbv.be" class="ml-2"
              >Demo</a
            >
          </div>
        </button>
        <button (click)="navigateToLandingPage()" class="primary-color-button">
          <div class="flex items-center justify-center" title="Terug naar home">
            <i
              class="material-icons text-white"
              style="font-size: 28px; width: 30px"
            >
              home
            </i>
          </div>
        </button>
      </div>
      <div class="flex items-center gap-4">
        <button
          *ngIf="userProfile?.isSupplier && !isExplanationPage"
          (click)="navigateToDashboard('supplier')"
          class="primary-color-button"
          title="Leverancier dashboard"
        >
          <div class="flex items-center justify-center">
            <i
              class="material-icons text-white"
              style="font-size: 28px; width: 30px"
            >
              dashboard
            </i>
            <span>Leverancier</span>
          </div>
        </button>
        <button
          *ngIf="userProfile?.isDebtor && !isExplanationPage"
          (click)="navigateToDashboard('debtor')"
          class="bg-[#26368b] text-white font-bold py-2.5 px-4 rounded-lg hover:bg-indigo-500"
          title="Debiteur dashboard"
        >
          <div class="flex items-center justify-center">
            <i
              class="material-icons text-white"
              style="font-size: 28px; width: 30px"
            >
              dashboard
            </i>
            <span>Debiteur</span>
          </div>
        </button>
        <button
          *ngIf="
            !(authService.isLoggedIn$ | async) && isLandingPage;
            else logoutButton
          "
          (click)="navigateToLogin()"
          type="submit"
          class="bg-[#26368b] hover:bg-indigo-500 text-white font-bold py-2 px-4 rounded-lg"
        >
          <div class="flex items-center justify-center">
            <i
              class="material-icons text-white"
              style="font-size: 28px; width: 30px"
            >
              login
            </i>
            <span class="ml-2">Inloggen</span>
          </div>
        </button>
        <ng-template #logoutButton>
          <button (click)="logout()" class="primary-color-button mr-4">
            <div class="flex items-center justify-center" title="Uitloggen">
              <i
                class="material-icons text-white"
                style="font-size: 28px; width: 30px"
              >
                logout
              </i>
            </div>
          </button>
        </ng-template>
      </div>
    </div>
  </div>
</header>
