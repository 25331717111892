import { SortDirection } from '../modules/shared/constants/sortDirection'
import { SortConfig } from '../modules/shared/Interfaces/SortConfig'

export function sortData(
  data: any[],
  config: { key: string; direction: string },
) {
  return [...data].sort((a, b) => {
    if (a[config.key] < b[config.key])
      return config.direction === SortDirection.Ascending ? -1 : 1
    if (a[config.key] > b[config.key])
      return config.direction === SortDirection.Descending ? 1 : -1
    return 0
  })
}

export function handleGeneralSort(
  key: string,
  sortConfig: SortConfig,
): SortConfig {
  return {
    key,
    direction:
      sortConfig.key === key && sortConfig.direction === SortDirection.Ascending
        ? SortDirection.Descending
        : SortDirection.Ascending,
  }
}
