import { AsyncPipe, NgIf } from '@angular/common'
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { ReactiveFormsModule, UntypedFormGroup } from '@angular/forms'
import { ActivatedRoute, Params, Router } from '@angular/router'
import { Actions, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import {
  BehaviorSubject,
  filter,
  Subject,
  take,
  takeUntil,
  withLatestFrom,
} from 'rxjs'
import { getRole, Role } from '../../../../shared/constants/role'
import { TrustyState } from '../../../../shared/constants/trustyState'
import { DebtorOnboardRequest } from '../../../../shared/Interfaces/Onboarding'
import { UserProfileResponse } from '../../../../shared/Interfaces/UserProfile'
import {
  trustyAssignDebtor,
  trustyAssignDebtorSuccess,
} from '../../../../shared/store/trusty/trusty.actions'
import {
  createDebtorUserProfile,
  getUserProfile,
} from '../../../../shared/store/userprofile/userprofile.actions'
import { UserProfileState } from '../../../../shared/store/userprofile/userprofile.reducer'
import {
  selectExistsUserProfile,
  selectUserProfile,
  selectUserProfileLoaded,
} from '../../../../shared/store/userprofile/userprofile.selectors'
import { OnboardingContactComponent } from '../onboarding-contact/onboarding-contact.component'
import { DebtorOnboardHeaderComponent } from './debtor-onboard-header/debtor-onboard-header.component'

@Component({
  selector: 'app-debtor-onboarding',
  standalone: true,
  imports: [
    NgIf,
    AsyncPipe,
    ReactiveFormsModule,
    OnboardingContactComponent,
    DebtorOnboardHeaderComponent,
  ],
  templateUrl: './debtor-onboarding.component.html',
  styleUrl: './debtor-onboarding.component.css',
})
export class DebtorOnboardingComponent implements OnInit, OnDestroy {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userProfileStore: Store<UserProfileState>,
    private trustyStore: Store<TrustyState>,
    private actions$: Actions,
  ) {}

  public isNormalDebtorOnboardFlow: Boolean = false

  debtorForm: UntypedFormGroup = new UntypedFormGroup({})
  selectedRole = new BehaviorSubject('bedrijf')
  trustyId?: string = ''
  userProfile$: BehaviorSubject<UserProfileResponse | undefined> =
    new BehaviorSubject(<UserProfileResponse | undefined>undefined)
  private hasUserProfile: boolean = false
  private destroy$ = new Subject<void>()

  @ViewChild(OnboardingContactComponent)
  onboardingContactComponent!: OnboardingContactComponent

  ngOnInit() {
    this.userProfileStore
      .select(selectExistsUserProfile)
      .pipe(
        takeUntil(this.destroy$),
        filter(exists => exists !== null),
      )
      .subscribe(exists => {
        if (!exists) {
          this.userProfileStore.dispatch(getUserProfile())
        }
      })

    this.userProfileStore
      .select(selectUserProfile)
      .pipe(takeUntil(this.destroy$))
      .subscribe((userProfile: UserProfileResponse) => {
        this.hasUserProfile = !!userProfile?.id
        this.userProfile$.next(userProfile)

        this.route.queryParams
          .pipe(take(1), takeUntil(this.destroy$))
          .subscribe(params => {
            this.trustyId = params['trustyId']
            if (this.trustyId) {
              // If the user already has a user profile, assign the debtor to the trusty and navigate to the trusty adjust page
              if (this.hasUserProfile) {
                this.handleExistingUser()
                return
              }
              this.handleNewUser(params)
            } else {
              // If the onboarding isn't related to a created trusty and the user already has a user profile,
              // navigate to the trusty ask page
              if (this.hasUserProfile) {
                this.router.navigate(['trusty-ask-form'])
              }

              this.isNormalDebtorOnboardFlow =
                'normalDebtorOnboardFlow' in params
            }
          })
      })
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  onSubmit() {
    if (this.verifyFormValidity()) {
      const formValue =
        this.onboardingContactComponent.onboardingContactForm.value
      const selectedRole = getRole(
        this.onboardingContactComponent.selectedRole.value,
      )

      const debtorOnboardRequest: DebtorOnboardRequest =
        this.setupDebtorOnboardRequest(formValue, selectedRole)

      // Create the debtor
      this.userProfileStore.dispatch(
        createDebtorUserProfile(debtorOnboardRequest),
      )

      this.userProfileStore
        .select(selectUserProfileLoaded)
        .pipe(takeUntil(this.destroy$))
        .subscribe(loaded => {
          if (loaded) {
            if (this.isNormalDebtorOnboardFlow) {
              this.router.navigate(['trusty-ask-form'])
            } else {
              this.trustyStore.dispatch(
                trustyAssignDebtor({ trustyId: this.trustyId! }),
              )

              this.actions$
                .pipe(
                  ofType(trustyAssignDebtorSuccess),
                  take(1),
                  takeUntil(this.destroy$),
                )
                .subscribe(() => {
                  this.router.navigate(['trusty-adjust'], {
                    queryParams: {
                      debtorRole: this.selectedRole.value,
                      trustyId: this.trustyId!,
                    },
                  })
                })
            }
          }
        })
    }
  }

  navigateToDash() {
    this.router.navigate(['debtor-dashboard'])
  }

  private setupDebtorOnboardRequest(formValue: any, selectedRole: Role) {
    const debtorOnboardRequest: DebtorOnboardRequest = {
      role: this.onboardingContactComponent.selectedRole.value,
      contactPersons: [
        {
          isMain: true,
          name: formValue['contactName'],
          email: formValue['contactEmail'],
          phone: formValue['contactPhone'],
        },
      ],
      company:
        selectedRole === Role.particulier
          ? null
          : {
              name: formValue['companyName'],
              address: formValue['address'],
              postalCode: formValue['postalCode'],
              city: formValue['city'],
              country: formValue['country'],
              btwNummer: formValue['vatNumber'],
            },
      address:
        selectedRole === Role.bedrijf
          ? null
          : {
              address: formValue['contactAddress'],
              city: formValue['contactCity'],
              postalCode: formValue['contactPostalCode'],
              country: formValue['contactCountry'],
            },
    }
    if (
      formValue['extraContactName'] !== undefined &&
      formValue['extraContactEmail'] !== undefined &&
      formValue['extraContactPhone'] !== undefined
    ) {
      debtorOnboardRequest.contactPersons.push({
        isMain: false,
        name: formValue['extraContactName'],
        email: formValue['extraContactEmail'],
        phone: formValue['extraContactPhone'],
      })
    }
    return debtorOnboardRequest
  }

  private verifyFormValidity(): boolean {
    if (!this.onboardingContactComponent) return false

    return this.onboardingContactComponent.isValidForm()
  }

  private handleExistingUser() {
    this.trustyStore.dispatch(trustyAssignDebtor({ trustyId: this.trustyId! }))

    this.actions$
      .pipe(
        ofType(trustyAssignDebtorSuccess),
        take(1),
        withLatestFrom(this.userProfile$),
      )
      .subscribe({
        next: ([_, userProfile]) => {
          this.router.navigate(['trusty-adjust'], {
            queryParams: {
              debtorRole: userProfile?.role,
              trustyId: this.trustyId,
            },
          })
        },
        error: (error: string) => {
          console.error('Error assigning debtor:', error)
          // Handle error (show toast, etc)
        },
      })
  }

  private handleNewUser(params: Params) {
    this.selectedRole.next(Role[params['role']])
    this.userProfile$.next({
      id: '',
      role: params['role'],
      isSupplier: false,
      isDebtor: true,
      contactPersons: [
        {
          isMain: true,
          name: params['name'],
          email: params['email'],
          phone: params['phone'],
        },
      ],
      paymentPlanParameters: null,
      bank: null,
      company: null,
      address: null,
    })
  }
}
