import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, map, of, switchMap } from 'rxjs'
import { TrustyUserService } from '../../../main/services/trusty-user.service'
import {
  DebtorOnboardRequest,
  SupplierOnboardRequest,
} from '../../Interfaces/Onboarding'
import { UserProfileUpdateRequest } from '../../Interfaces/UserProfile'
import {
  createDebtorUserProfileFailure,
  createDebtorUserProfileSuccess,
  createSupplierUserProfileFailure,
  createSupplierUserProfileSuccess,
  existsUserProfileFailure,
  existsUserProfileSuccess,
  getUserProfileFailure,
  getUserProfileSuccess,
  updateUserProfileFailure,
  updateUserProfileSuccess,
  verifiedUserFailure,
  verifiedUserSuccess,
} from './userprofile.actions'

@Injectable()
export class UserProfileEffects {
  constructor(
    private actions$: Actions,
    private trustyUserService: TrustyUserService,
  ) {}

  existsUserProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType('[UserProfile] exists'),
      switchMap(() =>
        this.trustyUserService.existsUser().pipe(
          map(existsResponse =>
            existsUserProfileSuccess({
              exists: existsResponse.exists,
            }),
          ),
          catchError(() => of(existsUserProfileFailure({ exists: false }))),
        ),
      ),
    ),
  )

  verifiedUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType('[User] verified'),
      switchMap(() =>
        this.trustyUserService.verifiedUser().pipe(
          map(verifiedResponse =>
            verifiedUserSuccess({
              verified: verifiedResponse.verified,
            }),
          ),
          catchError(() => of(verifiedUserFailure({ verified: false }))),
        ),
      ),
    ),
  )

  getUserProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType('[UserProfile] get'),
      switchMap(() =>
        this.trustyUserService.getUserProfile().pipe(
          map(userProfile =>
            getUserProfileSuccess({
              userProfileResponse: userProfile,
            }),
          ),
          catchError(error => of(getUserProfileFailure({ error }))),
        ),
      ),
    ),
  )

  createSupplierUserProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType('[UserProfile] supplier create'),
      switchMap((supplierOnboardRequest: SupplierOnboardRequest) =>
        this.trustyUserService.onboardSupplier(supplierOnboardRequest).pipe(
          map(userProfile =>
            createSupplierUserProfileSuccess({
              userProfileResponse: userProfile,
            }),
          ),
          catchError(error => of(createSupplierUserProfileFailure({ error }))),
        ),
      ),
    ),
  )

  createDebtorUserProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType('[UserProfile] debtor create'),
      switchMap((debtorOnboardRequest: DebtorOnboardRequest) =>
        this.trustyUserService.onboardDebtor(debtorOnboardRequest).pipe(
          map(userProfile =>
            createDebtorUserProfileSuccess({
              userProfileResponse: userProfile,
            }),
          ),
          catchError(error => of(createDebtorUserProfileFailure({ error }))),
        ),
      ),
    ),
  )

  updateUserProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType('[UserProfile] update'),
      switchMap((userProfileUpdate: UserProfileUpdateRequest) =>
        this.trustyUserService.updateUserProfile(userProfileUpdate).pipe(
          map(userProfile =>
            updateUserProfileSuccess({
              userProfileResponse: userProfile,
            }),
          ),
          catchError(error => of(updateUserProfileFailure({ error }))),
        ),
      ),
    ),
  )
}
