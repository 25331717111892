<div class="flex flex-col items-center justify-center min-h-[60vh] p-6">
  <!-- Success Icon -->
  <div class="mb-6">
    <mat-icon class="text-green-500 scale-[2]">check_circle_outline</mat-icon>
  </div>

  <!-- Success Message -->
  <h1 class="text-2xl font-bold text-gray-800 mb-4 text-center">
    Uw betaling is succesvol verwerkt
  </h1>

  <p class="text-gray-600 text-center mb-8 max-w-md">
    U ontvangt binnenkort een bevestigingsmail met de details van uw betaling.
  </p>

  <!-- Back to Dashboard Button -->
  <button
    mat-flat-button
    class="primary-color-button"
    (click)="navigateToDashboard()"
  >
    Terug naar dashboard
  </button>
</div>
