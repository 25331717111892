import { Component, Input } from '@angular/core'

interface Step {
  title: string
}

@Component({
  selector: 'app-supplier-onboard-header',
  standalone: true,
  imports: [],
  templateUrl: './supplier-onboard-header.component.html',
  styleUrl: './supplier-onboard-header.component.css',
})
export class SupplierOnboardHeaderComponent {
  @Input()
  currentStep: number = 0

  steps: Step[] = [
    {
      title: 'Informatie',
    },
    {
      title: 'Authenticatie',
    },
    {
      title: 'Email bevestigen',
    },
    {
      title: 'Profiel aanmaken',
    },
    {
      title: 'Afbetalingsplan bevestigen',
    },
    {
      title: 'Activatie',
    },
  ]
}
