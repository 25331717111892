import { SortDirection } from '../constants/sortDirection'

export interface SortConfig {
  key: string
  direction: string
}

export function getDefaultSortConfig(): SortConfig {
  return {
    key: 'date',
    direction: SortDirection.Ascending,
  }
}
