import { CommonModule } from '@angular/common'
import { Component, OnDestroy, OnInit } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { Store } from '@ngrx/store'
import { Subject, takeUntil } from 'rxjs'
import { toUTCDate } from '../../../main/services/data.service'
import { TrustyPreviewService } from '../../../main/services/trusty-preview-service'
import { TrustyPreview } from '../../Interfaces/internal/trusty-preview'
import { Invoice } from '../../Interfaces/Trusty'
import { UserProfileResponse } from '../../Interfaces/UserProfile'
import { UserProfileState } from '../../store/userprofile/userprofile.reducer'
import { selectUserProfile } from '../../store/userprofile/userprofile.selectors'

@Component({
  selector: 'app-payment-information',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FormsModule],
  templateUrl: './payment-information.component.html',
  styleUrl: './payment-information.component.css',
})
export class PaymentInformationComponent implements OnInit, OnDestroy {
  constructor(
    private trustyPreviewService: TrustyPreviewService,
    private store: Store<UserProfileState>,
  ) {}
  Math = Math

  readOnlyTotal: boolean = false
  totalAmount: number = 0
  minAmount: number = 50
  numberOfMonths: number = 12
  interestRate: number = 5
  startDate?: Date
  formattedStartDate?: string
  invoices: Invoice[] = [{ invoiceNumber: '', amount: 0 }]
  focusedField: string | null = null

  trustyPreview: TrustyPreview | null = null
  private destroy$ = new Subject<void>()

  ngOnInit() {
    this.updateStartDate()
    this.trustyPreviewService.currentTrustyPreview$
      .pipe(takeUntil(this.destroy$))
      .subscribe(trusty => {
        if (trusty !== undefined) {
          this.trustyPreview = trusty
          this.invoices = []
          this.totalAmount = this.trustyPreview.totalAmount
          this.minAmount = this.trustyPreview.minAmount
          this.numberOfMonths = this.trustyPreview.amountOfMonths
          this.interestRate = this.trustyPreview.interestRate
          this.trustyPreview.invoices
            .filter(x => x.amount > 0 && x.invoiceNumber !== '')
            .forEach(invoice => {
              this.invoices.push({
                invoiceNumber: invoice.invoiceNumber,
                amount: invoice.amount,
              })
            })

          // Because we start with an empty invoice, we need to remove if the current trusty has existing invoices
          if (this.invoices.length === 0) {
            this.addInvoice()
          }
        } else {
          this.store
            .select(selectUserProfile)
            .pipe(takeUntil(this.destroy$))
            .subscribe((userProfile: UserProfileResponse) => {
              if (userProfile) {
                this.minAmount =
                  userProfile.paymentPlanParameters?.minimumAmount || 50
                this.numberOfMonths =
                  userProfile.paymentPlanParameters?.maxDurationInMonths || 12
                this.interestRate =
                  userProfile.paymentPlanParameters?.defaultInterestRate || 5
              }
            })
        }
      })
  }

  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }

  updateStartDate() {
    const currentDate = toUTCDate(new Date())
    let nextMonth = currentDate.getMonth() + 2 // +2 because months are 0-indexed
    let year = currentDate.getFullYear()
    if (nextMonth > 12) {
      nextMonth = 1
      year += 1
    }
    this.startDate = toUTCDate(new Date(year, nextMonth - 1, 7))
    this.formattedStartDate = this.formatDate(this.startDate)
  }

  formatDate(date: Date): string {
    const year = date.getFullYear()
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const day = date.getDate().toString().padStart(2, '0')
    return `${year}-${month}-${day}`
  }

  onDateChange(dateString: string) {
    this.startDate = toUTCDate(dateString)
    this.formattedStartDate = dateString
  }

  calculatePaymentPlan(): void {
    let calculatedMinAmount: number = 0
    let calculatedMonths: number = 0
    const calculateForZeroInterest = (): void => {
      calculatedMonths = Math.min(
        12,
        Math.ceil(this.totalAmount / this.minAmount),
      )
      calculatedMinAmount =
        Math.ceil(this.totalAmount / calculatedMonths / 5) * 5
      if (calculatedMinAmount < 1) {
        calculatedMinAmount = 1
        calculatedMonths = Math.min(
          12,
          Math.ceil(this.totalAmount / calculatedMinAmount),
        )
      }
    }
    const calculateWithInterest = (): void => {
      const r: number = this.interestRate / 100 / 12
      calculatedMonths = 12 // Start with maximum months
      const calculateAmount = (): number => {
        const numerator: number = r * Math.pow(1 + r, calculatedMonths)
        const denominator: number = Math.pow(1 + r, calculatedMonths) - 1
        return this.totalAmount * (numerator / denominator)
      }
      calculatedMinAmount = Math.ceil(calculateAmount() / 5) * 5
      if (calculatedMinAmount < 1) {
        calculatedMinAmount = 1
        calculatedMonths = Math.ceil(
          Math.log(
            calculatedMinAmount / (calculatedMinAmount - this.totalAmount * r),
          ) / Math.log(1 + r),
        )
        calculatedMonths = Math.min(12, Math.max(2, calculatedMonths))
      }
    }
    if (this.interestRate === 0) {
      calculateForZeroInterest()
    } else {
      calculateWithInterest()
    }
    this.minAmount = Math.max(1, calculatedMinAmount)
    this.numberOfMonths = Math.min(12, Math.max(2, calculatedMonths))
  }

  adjustPaymentOnMonthsChange(): void {
    // Zorg ervoor dat het aantal maanden niet minder dan 2 is
    this.numberOfMonths = Math.max(2, this.numberOfMonths)
    if (this.interestRate === 0) {
      // Zonder rente is de berekening eenvoudig
      this.minAmount = Math.ceil(this.totalAmount / this.numberOfMonths / 5) * 5
    } else {
      // Met rente gebruiken we de annuïteitenformule
      const r: number = this.interestRate / 100 / 12
      const numerator: number = r * Math.pow(1 + r, this.numberOfMonths)
      const denominator: number = Math.pow(1 + r, this.numberOfMonths) - 1
      this.minAmount =
        Math.ceil((this.totalAmount * (numerator / denominator)) / 5) * 5
    }
    // Zorg ervoor dat de maandelijkse betaling niet minder dan 50 is
    this.minAmount = Math.max(1, this.minAmount)
    // Als de maandelijkse betaling 50 is en dit niet voldoende is om de lening af te betalen,
    // pas dan het aantal maanden aan
    if (this.minAmount === 1 && this.numberOfMonths * 1 < this.totalAmount) {
      this.recalculateNumberOfMonths()
    }
  }

  private recalculateNumberOfMonths(): void {
    if (this.interestRate === 0) {
      this.numberOfMonths = Math.ceil(this.totalAmount / this.minAmount)
    } else {
      const r: number = this.interestRate / 100 / 12
      this.numberOfMonths = Math.ceil(
        Math.log(this.minAmount / (this.minAmount - this.totalAmount * r)) /
          Math.log(1 + r),
      )
    }
  }

  updateNumberOfMonths(): void {
    // Zorg ervoor dat de maandelijkse betaling minstens 50 is
    this.minAmount = Math.max(1, this.minAmount)
    if (this.interestRate === 0) {
      // Zonder rente
      this.numberOfMonths = Math.ceil(this.totalAmount / this.minAmount)
    } else {
      // Met rente
      const r = this.interestRate / 100 / 12
      const numerator =
        Math.log(this.minAmount) -
        Math.log(this.minAmount - this.totalAmount * r)
      const denominator = Math.log(1 + r)
      this.numberOfMonths = Math.ceil(numerator / denominator)
    }
    // Rond het aantal maanden naar boven af naar het dichtstbijzijnde gehele getal
    this.numberOfMonths = Math.ceil(this.numberOfMonths)
  }

  calculateOnInterestRateChange(): void {
    const currentMonthlyPayment = this.minAmount
    const currentTotalAmount = this.totalAmount

    // Update de rentevoet
    const newInterestRate = this.interestRate
    // Bereken het nieuwe aantal maanden
    if (newInterestRate === 0) {
      // Als de nieuwe rentevoet 0 is, is het simpelweg totaalbedrag gedeeld door maandelijkse betaling
      this.numberOfMonths = Math.ceil(
        currentTotalAmount / currentMonthlyPayment,
      )
    } else {
      const monthlyRate = newInterestRate / 100 / 12
      // Gebruik de formule voor aantal termijnen bij een annuïteitenlening
      this.numberOfMonths = Math.ceil(
        Math.log(
          currentMonthlyPayment /
            (currentMonthlyPayment - currentTotalAmount * monthlyRate),
        ) / Math.log(1 + monthlyRate),
      )
    }
    // Rond het aantal maanden naar boven af naar het dichtstbijzijnde gehele getal
    this.numberOfMonths = Math.ceil(this.numberOfMonths)
    // Herbereken het totaalbedrag om eventuele afrondingsverschillen te corrigeren
    if (this.interestRate === 0) {
      this.totalAmount = this.minAmount * this.numberOfMonths
    } else {
      const monthlyRate = this.interestRate / 100 / 12
      this.totalAmount =
        this.minAmount *
        ((1 - Math.pow(1 + monthlyRate, -this.numberOfMonths)) / monthlyRate)
    }
    this.totalAmount = currentTotalAmount
  }

  onFocus(field: string) {
    this.focusedField = field
  }

  onBlur() {
    if (this.focusedField === 'interestRate') {
      this.calculateOnInterestRateChange()
    } else if (this.focusedField === 'minAmount') {
      this.updateNumberOfMonths()
    } else if (this.focusedField === 'numberOfMonths') {
      this.adjustPaymentOnMonthsChange()
    } else if (this.focusedField === 'totalAmount') {
      this.calculatePaymentPlan()
    } else {
      this.calculatePaymentPlan()
    }
    this.focusedField = null
  }

  handleInterestRateChange(value: number) {
    if (value <= 0 || value > 12.5) {
      return
    }

    this.interestRate = Math.max(0, value)
    this.interestRate = Math.min(this.interestRate, 12.5)
    this.calculateOnInterestRateChange()
  }

  handleNumberOfMonthsChange(value: number) {
    if (value <= 2 || value > 60) {
      return
    }

    this.numberOfMonths = Math.max(2, parseInt(value.toString()))
    this.numberOfMonths = Math.min(this.numberOfMonths, 60)
    this.numberOfMonths = this.Math.abs(this.numberOfMonths)
    this.adjustPaymentOnMonthsChange()
  }

  handleInvoiceChange(
    index: number,
    field: 'invoiceNumber' | 'amount',
    value: string,
  ) {
    if (field === 'amount') {
      this.invoices[index][field] = parseFloat(value) || 0
      this.readOnlyTotal = true
    } else {
      this.invoices[index][field] = value
    }
    this.updateTotalAmount()
    this.calculatePaymentPlan()
  }

  addInvoice() {
    this.invoices.push({ invoiceNumber: '', amount: 0 })
  }

  removeInvoice(index: number) {
    if (index === 0) return

    this.invoices.splice(index, 1)
    this.updateTotalAmount()
    this.calculatePaymentPlan()
  }

  updateTotalAmount() {
    this.totalAmount = Number(
      this.invoices.reduce((sum, invoice) => sum + Number(invoice.amount), 0),
    )
  }

  isValid(): boolean {
    return (
      this.interestRate > 0 &&
      this.interestRate <= 12.5 &&
      this.minAmount >= 1 &&
      this.numberOfMonths >= 2 &&
      this.numberOfMonths <= 60
    )
  }
}
