<div class="flex justify-center space-x-8 mb-4 mt-2">
  <button
    type="button"
    (click)="selectRole('bedrijf')"
    [ngClass]="{
      'bg-indigo-500 text-white': selectedRole === 'bedrijf',
      'bg-gray-300 text-gray-800': selectedRole !== 'bedrijf',
    }"
    class="px-4 py-2 rounded-md hover:bg-indigo-600 transition-colors shadow-md"
  >
    Bedrijf
  </button>
  <button
    type="button"
    (click)="selectRole('eenmanszaak')"
    [ngClass]="{
      'bg-indigo-500 text-white': selectedRole === 'eenmanszaak',
      'bg-gray-300 text-gray-800': selectedRole !== 'eenmanszaak',
    }"
    class="px-4 py-2 rounded-md hover:bg-indigo-600 transition-colors shadow-md"
  >
    Eénmanszaak
  </button>
  <button
    type="button"
    (click)="selectRole('particulier')"
    [ngClass]="{
      'bg-indigo-500 text-white': selectedRole === 'particulier',
      'bg-gray-300 text-gray-800': selectedRole !== 'particulier',
    }"
    class="px-4 py-2 rounded-md hover:bg-indigo-600 transition-colors shadow-md"
  >
    Particulier
  </button>
</div>
