import { CommonModule } from '@angular/common'
import { Component, OnDestroy, ViewChild } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { BehaviorSubject, map, Subject, take, takeUntil } from 'rxjs'
import { ConfirmationDialogComponent } from '../../../../shared/components/confirmation-dialog/confirmation-dialog.component'
import { getRole } from '../../../../shared/constants/role'
import { TrustyDebtorInviteSupplierRequest } from '../../../../shared/Interfaces/Trusty'
import { UserProfileResponse } from '../../../../shared/Interfaces/UserProfile'
import { trustyDebtorInviteSupplier } from '../../../../shared/store/trusty/trusty.actions'
import { TrustyState } from '../../../../shared/store/trusty/trusty.reducer'
import { selectTrustyLoaded } from '../../../../shared/store/trusty/trusty.selectors'
import { updateUserProfile } from '../../../../shared/store/userprofile/userprofile.actions'
import { UserProfileState } from '../../../../shared/store/userprofile/userprofile.reducer'
import { selectUserProfile } from '../../../../shared/store/userprofile/userprofile.selectors'
import { OnboardingContactComponent } from '../../onboarding/onboarding-contact/onboarding-contact.component'
import { TrustyPreviewComponent } from '../trusty-preview/trusty-preview.component'

@Component({
  selector: 'app-trusty-ask-form-for',
  standalone: true,
  imports: [CommonModule, TrustyPreviewComponent, OnboardingContactComponent],
  templateUrl: './trusty-ask-form-for.component.html',
  styleUrl: './trusty-ask-form-for.component.css',
})
export class TrustyAskFormForComponent implements OnDestroy {
  @ViewChild(OnboardingContactComponent)
  onboardingContactComponent!: OnboardingContactComponent
  selectedRole = new BehaviorSubject('bedrijf')

  private destroy$ = new Subject<void>()
  constructor(
    private router: Router,
    public dialog: MatDialog,
    private trustyStore: Store<TrustyState>,
    private userProfileStore: Store<UserProfileState>,
  ) {}

  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }

  submitForm(): void {
    var contactForm =
      this.onboardingContactComponent.onboardingContactForm.value
    const baseContactPerson = {
      isMain: true,
      name: contactForm.contactName,
      email: contactForm.contactEmail,
      phone: contactForm.contactPhone,
    }

    const contactPersons = [baseContactPerson]

    // Add extra contact if provided
    if (contactForm.extraContactName?.trim()) {
      contactPersons.push({
        isMain: false,
        name: contactForm.extraContactName,
        email: contactForm.extraContactEmail,
        phone: contactForm.extraContactPhone,
      })
    }

    const trustyDebtorInviteSupplierRequest: TrustyDebtorInviteSupplierRequest =
      {
        role: getRole(this.onboardingContactComponent.selectedRole.value),
        contactPersons: contactPersons,
        company:
          this.onboardingContactComponent.selectedRole.value === 'particulier'
            ? null
            : {
                name: contactForm.companyName,
                address: contactForm.address,
                postalCode: contactForm.postalCode,
                city: contactForm.city,
                country: contactForm.country,
                btwNummer: contactForm.vatNumber,
              },
        extraInformation: contactForm.optionalComment,
      }

    this.trustyStore.dispatch(
      trustyDebtorInviteSupplier(trustyDebtorInviteSupplierRequest),
    )

    this.trustyStore
      .select(selectTrustyLoaded)
      .pipe(takeUntil(this.destroy$))
      .subscribe(loaded => {
        if (loaded) {
          const confirmDialogRef = this.dialog.open(
            ConfirmationDialogComponent,
            {
              data: {
                title: 'Trusty aangevraagd',
                text: 'Uw aanvraag is succesvol verstuurd!',
                showCancelButton: false,
              },
            },
          )

          this.userProfileStore.select(selectUserProfile).pipe(
            take(1),
            map((userProfile: UserProfileResponse) => {
              if (userProfile.isSupplier && !userProfile.isDebtor) {
                this.userProfileStore.dispatch(
                  updateUserProfile({
                    isSupplier: userProfile.isSupplier,
                    isDebtor: true,
                    contactPersons: userProfile.contactPersons,
                    company: userProfile.company,
                    paymentPlanParameters: userProfile.paymentPlanParameters,
                    bank: userProfile.bank,
                    address: userProfile.address,
                  }),
                )
              }
            }),
          )

          confirmDialogRef.afterClosed().subscribe(() => {
            this.router.navigate(['debtor-dashboard'])
          })
        }
      })
  }

  navigateBack(): void {
    this.router.navigate(['trusty-ask-form'])
  }
}
