<div
  class="min-h-screen bg-gradient-to-b from-blue-50 to-green-50 py-12 px-4 sm:px-6 lg:px-8"
>
  <div class="max-w-4xl mx-auto">
    <div class="text-center mb-12">
      <div class="flex justify-center mb-6">
        <div class="rounded-full bg-green-100 p-4">
          <svg
            class="h-12 w-12 text-green-600"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M5 13l4 4L19 7"
            ></path>
          </svg>
        </div>
      </div>

      <h1 class="text-4xl font-bold text-gray-900 mb-4">
        Gefeliciteerd met uw nieuwe Trusty!
      </h1>

      <p class="text-xl text-gray-600">
        U heeft succesvol een nieuwe afbetalingsplan opgemaakt.
      </p>

      <p class="text-xl text-gray-600">
        Uw debiteur zal spoedig een uitnodiging ontvangen.
      </p>
    </div>

    <!-- Plan Details -->

    <div class="bg-white rounded-lg shadow-lg p-6 mb-8">
      <h2 class="text-2xl font-semibold mb-4">Details van uw Trusty</h2>

      <div class="grid grid-cols-2 gap-4">
        <div>
          <p class="text-gray-600">Debiteur</p>

          <p class="font-semibold">{{ trusty?.debtor?.name }}</p>
        </div>

        <div>
          <p class="text-gray-600">Totaal Bedrag</p>

          <p class="font-semibold">
            € {{ trusty?.totalAmount | number: '1.2-2' }}
          </p>
        </div>

        <div>
          <p class="text-gray-600">Looptijd</p>

          <p class="font-semibold">{{ trusty?.amountOfMonths }} maanden</p>
        </div>

        <div>
          <p class="text-gray-600">Maandelijks Bedrag</p>

          <p class="font-semibold">
            € {{ trusty?.minAmount | number: '1.2-2' }}
          </p>
        </div>
      </div>
    </div>

    <div class="bg-white rounded-lg shadow-lg p-6 mb-8">
      <h2 class="text-2xl font-semibold mb-4">Wat wilt u nu doen?</h2>

      <div class="space-y-4">
        <div class="flex items-start mb-4">
          <div class="flex-shrink-0">
            <mat-icon class="material-icons">add</mat-icon>
          </div>

          <div class="ml-3">
            <h3 class="text-lg font-semibold">Maak nog een Trusty aan</h3>

            <p class="text-gray-600">
              Heeft u meer debiteuren die baat kunnen hebben bij een
              afbetalingsregeling? Maak direct een nieuwe Trusty aan.
            </p>
          </div>
        </div>

        <div class="flex items-start mb-4">
          <mat-icon class="material-icons">compare_arrows</mat-icon>

          <div class="ml-3">
            <h3 class="text-lg font-semibold">Vraag zelf een Trusty aan</h3>

            <p class="text-gray-600">
              Heeft u zelf openstaande facturen? Vraag een Trusty aan bij uw
              leveranciers en profiteer van dezelfde voordelen.
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- Action Buttons -->

    <div class="flex justify-center space-x-4">
      <button
        (click)="navigateTo('dashboard')"
        class="primary-color-button px-6 py-3 rounded-lg transition-colors"
      >
        Dashboard
      </button>

      <button
        (click)="navigateTo('create')"
        class="px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
      >
        Maak Nieuwe Trusty
      </button>

      <button
        (click)="navigateTo('request')"
        class="px-6 py-3 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors"
      >
        Vraag Trusty Aan
      </button>
    </div>
  </div>
</div>
