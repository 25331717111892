<div class="flex flex-col items-center justify-center min-h-[60vh] p-6">
  <!-- Error Icon -->
  <div class="mb-6">
    <mat-icon class="text-red-500 scale-[2]">error_outline</mat-icon>
  </div>

  <!-- Error Message -->
  <h1 class="text-2xl font-bold text-gray-800 mb-4 text-center">
    Er is iets misgegaan met uw betaling
  </h1>

  <p class="text-gray-600 text-center mb-8 max-w-md">
    U zult binnenkort een e-mail ontvangen met een nieuwe betaallink om de
    betaling opnieuw te proberen.
  </p>

  <!-- Back to Dashboard Button -->
  <button
    mat-flat-button
    class="primary-color-button"
    (click)="navigateToDashboard()"
  >
    Terug naar dashboard
  </button>
</div>
