import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { TrustyPreview } from '../../shared/Interfaces/internal/trusty-preview'

@Injectable({
  providedIn: 'root',
})
export class TrustyPreviewService {
  private readonly _preview = new BehaviorSubject<TrustyPreview | undefined>(
    undefined,
  )

  readonly currentTrustyPreview$ = this._preview.asObservable()

  set trustyPreview(trustyPreview: TrustyPreview | undefined) {
    this._preview.next(trustyPreview)
  }

  get trustyPreview(): TrustyPreview | undefined {
    return this._preview.value
  }

  clearTrustyData() {
    this._preview.next(undefined)
  }
}
